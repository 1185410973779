import React, { useEffect, useState } from 'react'

const ActivityQuestionAnsPaper = ({ selectedLessonItem, onClose, type}) => {


    const [ currentIndex, setCurrentIndex ] = useState(1)
    const [ questions, setQuestions ] = useState([])

    const handlePrev = () => {
        setCurrentIndex(currentIndex-1)
    }

    const handleNext = () => {
        setCurrentIndex(currentIndex+1)
    }

    const chooseAnswer = (selectedAnswer, correctAnswer, selectedItem, itemIndex) => {
        let newData = questions.map(item => {
            if(selectedItem.id === item.id) {
                if(selectedAnswer === correctAnswer) {
                    item.selectedAnswer = selectedAnswer
                    item.marked = 'correct'
                }
                else {
                    item.marked = 'wrong'
                }
                item.selectedAnswerIndex = itemIndex
            }
            return item
        })
        setQuestions(newData)
    }

    const handleSelectedAnswer = (item, answer, index) => {

        if(item.selectedAnswerIndex === index) {
            if(item.selectedAnswer === answer) {
                return "correct"
            }
            else {
                return "wrong"
            }
        }

        
    }

    const handleStudentChoosenAnswerForOptions = (correct_answer, student_answer, answer, index) => {
        if(parseInt(correct_answer[0].id) === parseInt(student_answer[index].id)) {
            if(parseInt(answer.id) === parseInt(correct_answer[0].id)) {
                return 'correct'
            }
        }
        else {
            if(parseInt(answer.id) === parseInt(student_answer[index].id)) {
                return 'wrong'
            }
        }
    }

    const handleStudentChoosenAnswerForNav = (correct_answer, student_answer, index) => {
        if(parseInt(correct_answer[0].id) === parseInt(student_answer[index].id)) {
            if(currentIndex >= (index+1)) {
                return 'correct'
            }
        }
        else {
            if(currentIndex >= (index+1)) {
                return 'wrong'
            }
        }
    }

    const handleStudentChoosenAnswerForSolution = (correct_answer, student_answer, index) => {
        if(parseInt(correct_answer[0].id) === parseInt(student_answer[index].id)) {
            if(currentIndex >= (index+1)) {
                return 'd-none'
            }
        }
        else {
            if(currentIndex >= (index+1)) {
                return ''
            }
        }
    }

    const handleClose = () => {
        onClose()
        let newData = questions.map(item => {
            delete item.selectedAnswer
            delete item.selectedAnswerIndex
            delete item.marked
            return item
        })
        setQuestions(newData)
    }

    const showCorrectAnswer = (answer) => {
        switch (parseInt(answer)) {
            case 1:
                return 'A';
            case 2:
                return 'B';
            case 3:
                return 'C';
            case 4:
                return 'D';
            default:
                break;
        }
    }

    useEffect(() => {
        setCurrentIndex(1)
        // the data structure of unassigned and assigned assignment are different, logic written to overcome the issue
        if(type === 'preview') {
            setQuestions(selectedLessonItem.questions_data)
        }
        else {
            setQuestions(selectedLessonItem.lesson_activity.questions_data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


  return (
    <div className='paper-container'>
        { type === 'preview' ? 
        <>
            <div className='content'>
                <div className='stepper-wrapper'>
                    <div className='stepper-box d-flex justify-content-center align-items-center'>
                        { questions.map((item, index) => {
                            return (
                                <div className={`circle fw-medium ft-default ${item.marked}`}>{index+1}</div>
                            )
                        })}
                    </div>
                    <div className='close-btn text-base fw-medium ft-default pointer' onClick={handleClose}>Close</div>
                </div>
                <div className='ques-wrapper'>
                    { questions.map((item, index) => {
                            return (
                                <div className={`inner ${currentIndex === (index+1) ? "" : "d-none"}`}>
                                    <div className='ques-area'>
                                        <h5 className='text p-0 mb-4'> <span className='me-3'>{item.question_number}</span> <span dangerouslySetInnerHTML={{__html: item.question}}></span></h5>
                                        {/*<div className='img-box text-center'>
                                            <img src={`https://picsum.photos/20${index}`} alt="" />
                                        </div> */}
                                        <ul className='ans-box row'>
                                            { item.answers.map((data, dataIndex) => {
                                                return (
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className={`ans text-base ${handleSelectedAnswer(item, data.answer, dataIndex)} ${item.marked ? "no-event" : ""}`} onClick={() => {chooseAnswer(data.answer, item.correct_answers[0].answer, item, dataIndex)}}>
                                                            <li className='text-capitalize'>{data.answer}</li>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                        <div className='nav'>
                                            <div className={`btn prev ${currentIndex === 1 ? "disabled" : ""}`} onClick={handlePrev}>Previous</div>
                                            <div className={`btn next  ${currentIndex >= (selectedLessonItem?.questions.length) ? "disabled" : ""}`} onClick={handleNext}>Next</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    })}
                </div>
            </div>
        </>
        :
        <>
            <div className='content'>
                <div className='stepper-wrapper'>
                    <div className='stepper-box d-flex justify-content-center align-items-center'>
                        { questions.map((item, index) => {
                            return (
                                <div className={`circle fw-medium ft-default ${handleStudentChoosenAnswerForNav(item.correct_answers, selectedLessonItem?.student_answers, index)}`}>{index+1}</div>
                            )
                        })}
                    </div>
                    <div className='close-btn text-base fw-medium ft-default pointer' onClick={handleClose}>Close</div>
                </div>
                <div className='ques-wrapper'>
                    { questions.map((item, index) => {
                            return (
                                <div className={`inner ${currentIndex === (index+1) ? "" : "d-none"}`}>
                                    <div className='ques-area'>
                                        <h5 className='text p-0 mb-4'> <span className='me-3'>{item.question_number}</span> <span dangerouslySetInnerHTML={{__html: item.question}}></span></h5>
                                        {/*<div className='img-box text-center'>
                                            <img src={`https://picsum.photos/20${index}`} alt="" />
                                        </div> */}
                                        <ul className='ans-box row'>
                                            { item.answers.map((data, dataIndex) => {
                                                return (
                                                    <div className='col-sm-12 col-md-6'>
                                                        <div className={`ans text-base no-event ${handleStudentChoosenAnswerForOptions(item.correct_answers, selectedLessonItem?.student_answers, data, index)}`} onClick={() => {chooseAnswer(data.answer, item.correct_answers[0].answer, item, dataIndex)}}>
                                                            <li className='text-capitalize'>{data.answer}</li>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                        <div className={`answer-box mt-2 ${handleStudentChoosenAnswerForSolution(item.correct_answers, selectedLessonItem?.student_answers, index)}`}>
                                            <p className='text-base fw-medium text-primary-950'>Answer: Correct Answer is {showCorrectAnswer(item.correct_answers[0].id)}.</p>
                                        </div>
                                        <div className='nav'>
                                            <div className={`btn prev ${currentIndex === 1 ? "disabled" : ""}`} onClick={handlePrev}>Previous</div>
                                            <div className={`btn next  ${currentIndex >= (selectedLessonItem?.lesson_activity.questions.length) ? "disabled" : ""}`} onClick={handleNext}>Next</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    })}
                </div>
            </div>
        </>
        }
    </div>
  )
}

export default ActivityQuestionAnsPaper
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { pending, fulfilled, clean } from 'redux-saga-thunk'
import { Tooltip } from "reactstrap"

import growthBlockImg  from '../../../assets/images/growth-block-img.png'
import _ from 'underscore'

import CreateRewardModal from './CreateRewardModal';
import RewardDeleteConfirmModal from './RewardDeleteConfirmModal';

import { viewReward as onViewReward } from '../../../store/reward/actions';
import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { deleteReward as onDeleteReward } from '../../../store/reward/actions';

import { rewardPointsData as reward_points } from '../../../constants/variousdata';

import { showToastMessage } from '../../../helpers/toaster'


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../../../components/Common/Loader';

library.add(fas, fab);

const GrowthReward = props => {

  const dispatch = useDispatch()

  const location = useLocation()

  const [ isRewardModalVisible, setRewardModalVisible ] = useState(false)
  const [ isRewardDeleteConfirmModalVisible, setRewardDeleteConfirmModalVisible ] = useState(false)

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedReward, setselectedReward] = useState({});

  const { reward, reward_type, subject, get_reward, get_reward_success, get_subject_success,  get_delete_success } = useSelector(state => ({
    reward: state.RewardReducer.reward,
    reward_type: state.RewardTypeReducer.reward_type,
    subject: state.SubjectReducer.subject,
    get_reward: pending(state, "VIEW_REWARD"),
    get_reward_success: fulfilled(state, "VIEW_REWARD"),
    get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
    get_delete_success: fulfilled(state, "DELETE_REWARD")
  }))

  const handleCreateReward = () => {
    setRewardModalVisible(!isRewardModalVisible)
  }

  const handleEditReward = (item) => {
    setEdit(true)
    setselectedReward(item)
    setRewardModalVisible(!isRewardModalVisible)
  }

  const getSubjectName = (id) => {
    let data = subject?.filter(item => {
      return item.id === id
    })
    if(data === undefined || data === null || data.length === 0){
      return ""
    }
    else {
      return data[0].name
    }

  }

  const getSubjectColor = (id) => {
    let data = subject?.filter(item => {
      return item.id === id
    })
    if(data === undefined || data === null || data.length === 0){
      return ""
    }
    else {
      return data[0].color
    }
  }

  const getSubjectLogo = (id) => {
    let data = subject?.filter(item => {
      return item.id === id
    })
    if(data === undefined || data === null || data.length === 0){
      return ""
    }
    else {
      return data[0].logo
    }
  }

  const getRewardType = (id) => {
    let data = reward_type?.filter(item => {
      return item.id === id
    })
    if(data === undefined || data === null || data.length === 0){
      return ""
    }
    else {
      return data[0].title
    }
  }

  const getRewardPoints = (id) => {
    let data = reward_points?.filter(item => {
      return item.id === id
    })
    if(data === undefined || data === null || data.length === 0){
      return ""
    }
    else {
      return data[0].name
    }
  }

  const onClose = () => {
    setRewardModalVisible(false)
    setselectedReward({})
    setEdit(false)
  }

  const onDelete = () => {
    setRewardModalVisible(false)
    setRewardDeleteConfirmModalVisible(!isRewardDeleteConfirmModalVisible)
  }

  const onConfirmDelete = () => {
    setRewardDeleteConfirmModalVisible(!isRewardDeleteConfirmModalVisible)
    setselectedReward({})
    setEdit(false)
  }

  const onSubmitDelete = () => {
    
    let data = {
      id: selectedReward.id
    } 
    dispatch(onDeleteReward(data))
  }

  useEffect(() => {

    let currentUrlParams = new URLSearchParams(location.search);
    let student_id = currentUrlParams.get('sid')

    if(student_id) {
     let data = {
        user_id: student_id
      }
      dispatch(onViewReward(data))
      setRewardModalVisible(false)
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    dispatch(onViewSubject())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_reward_success) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_reward_success])

  useEffect(() => {
    if(get_delete_success) {
        dispatch(clean("DELETE_REWARD"))
        showToastMessage('success',"Reward deleted")
        setRewardDeleteConfirmModalVisible(!isRewardDeleteConfirmModalVisible)
        setselectedReward({})
        setEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [get_delete_success])

  return (
    
    <div className='growth-inner-wrapper reward-wrapper'>
      <div className='btn-container d-flex justify-content-end'>
        <div className='btn tab-btn sp-btn' onClick={handleCreateReward}>
          <span className='circle'>
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </span>
          <span className='d-none d-xl-inline-block'>Create reward</span>
        </div>
      </div>
      <div className='left-box bg-white'>
        
        { get_reward && <Loader /> }

        { get_reward_success && 
          <>
            { _.isEmpty(reward) ? 
              <div className='no-results'>
                <div className='img-container'>
                  <img src={growthBlockImg} alt="" />
                </div>
                <div className='desp-box'>
                  <h6 className='title'>No Reward created</h6>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis eligendi atque repudiandae ipsam aspernatur</p>
                </div>
              </div>
              :
              <div className='reward-box'>
                <div className='d-flex align-items-center reward-title'>
                  <h3 className='text-gray-950 ft-default fw-normal'>Customised rewards</h3>
                  <h4 className='ms-4 text-primary-500' id="infoTooltip"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></h4>
                  <Tooltip
                    isOpen={tooltipOpen}
                    placement="right"
                    target="infoTooltip"
                    toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                    To create new reward click on the create new button
                  </Tooltip>
                </div>
                <div className='row'>
                  { get_subject_success && reward && reward_type && reward.map((item, index) => {
                      return (
                        <div key={index} className='col-xs-12 col-md-6 col-xl-4'>
                          <div className='reward-list h-100'>
                            <div className='reward-card h-100'>
                              <div className='edit text-end text-normal ft-default'>
                                <div className='d-inline-block pointer mb-2' onClick={() => {handleEditReward(item)}}>Edit</div>
                              </div>
                              <div className="subject text-sm ft-default" style={{backgroundColor: getSubjectColor(parseInt(item.subject_id))}}>{getSubjectName(parseInt(item.subject_id))}</div>
                              <div className='content'>
                                <img className='logo' src={getSubjectLogo(parseInt(item.subject_id))} alt="" />
                                <h6 className='text-gray-950 text-xs'>{item.description}</h6>
                              </div>
                              <div className='bottom'>
                                <div className='title ft-default text-sm' style={{backgroundColor: getSubjectColor(parseInt(item.subject_id))}}>{getRewardType(parseInt(item.reward_typ_id))}</div>
                                <div className='bg-primary-300 text-sm text-center fw-semibold text-white points'>Score Point {getRewardPoints(item.points)}</div>
                              </div>
                            </div>
                          </div>
                        </div> 
                      )
                    }) }
                </div>
                <div className='reward-list'>
                  
                </div>
              </div>
            }
          </>
        }
      </div>
      <CreateRewardModal 
        visible={isRewardModalVisible}
        isEdit={isEdit}
        reward={selectedReward} 
        onClose = {onClose}
        onDelete = {onDelete}
      />
      <RewardDeleteConfirmModal 
            visible={isRewardDeleteConfirmModalVisible}
            onConfirmDelete = {onConfirmDelete}
            onSubmitData={onSubmitDelete} />
    </div>
  )
}


export default GrowthReward
import {
    VIEW_NOTICE_SUCCESS,
    VIEW_NOTICE_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    notice: null
}

const NoticeReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case VIEW_NOTICE_SUCCESS: {
        return {
          ...state,
          loading: false,
          notice: action.payload,
          error: "",
        }
      }
      case VIEW_NOTICE_FAILED: {
        return {
          ...state,
          loading: false,
          notice: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default NoticeReducer;
import {
    ADD_STUDENT,
    ADD_STUDENT_SUCCESS,
    ADD_STUDENT_FAILED,
    VIEW_STUDENT,
    VIEW_STUDENT_SUCCESS,
    VIEW_STUDENT_FAILED,
    UPDATE_STUDENT,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAILED,
    DELETE_STUDENT,
    DELETE_STUDENT_SUCCESS,
    DELETE_STUDENT_FAILED
} from './actionTypes';

export const addStudent = (student) => {
    return {
        type: ADD_STUDENT,
        payload: student,
        meta:{
            thunk:true
        }
    }
}
  
export const addStudentSuccess = (student, { thunk }) => ({
    type: ADD_STUDENT_SUCCESS,
    payload: student,
    meta: {
        thunk
    }
})
  
export const addStudentFailed = (error, { thunk }) => ({
    type: ADD_STUDENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const viewStudent = (student) => {
    return {
        type: VIEW_STUDENT,
        payload: student,
        meta:{
            thunk:true
        }
    }
}
  
export const viewStudentSuccess = (student, { thunk }) => ({
    type: VIEW_STUDENT_SUCCESS,
    payload: student,
    meta: {
        thunk
    }
})
  
export const viewStudentFailed = (error, { thunk }) => ({
    type: VIEW_STUDENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const updateStudent = (student) => {
    return {
        type: UPDATE_STUDENT,
        payload: student,
        meta:{
            thunk:true
        }
    }
}
  
export const updateStudentSuccess = (student, { thunk }) => ({
    type: UPDATE_STUDENT_SUCCESS,
    payload: student,
    meta: {
        thunk
    }
})
  
export const updateStudentFailed = (error, { thunk }) => ({
    type: UPDATE_STUDENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const deleteStudent = (student) => {
    return {
        type: DELETE_STUDENT,
        payload: student,
        meta:{
            thunk:true
        }
    }
}
  
export const deleteStudentSuccess = (student, { thunk }) => ({
    type: DELETE_STUDENT_SUCCESS,
    payload: student,
    meta: {
        thunk
    }
})
  
export const deleteStudentFailed = (error, { thunk }) => ({
    type: DELETE_STUDENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})
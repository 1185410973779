export const VIEW_REWARD = "VIEW_REWARD"
export const VIEW_REWARD_SUCCESS = "VIEW_REWARD_SUCCESS"
export const VIEW_REWARD_FAILED = "VIEW_REWARD_FAILED"

export const CREATE_REWARD = "CREATE_REWARD"
export const CREATE_REWARD_SUCCESS = "CREATE_REWARD_SUCCESS"
export const CREATE_REWARD_FAILED = "CREATE_REWARD_FAILED"

export const UPDATE_REWARD = "UPDATE_REWARD"
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS"
export const UPDATE_REWARD_FAILED = "UPDATE_REWARD_FAILED"

export const DELETE_REWARD = "DELETE_REWARD"
export const DELETE_REWARD_SUCCESS = "DELETE_REWARD_SUCCESS"
export const DELETE_REWARD_FAILED = "DELETE_REWARD_FAILED"

export const VIEW_REWARD_CATEGORY = "VIEW_REWARD_CATEGORY"
export const VIEW_REWARD_CATEGORY_SUCCESS = "VIEW_REWARD_CATEGORY_SUCCESS"
export const VIEW_REWARD_CATEGORY_FAILED = "VIEW_REWARD_CATEGORY_FAILED"
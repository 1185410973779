import React from 'react'
import { Modal, ModalBody } from "reactstrap"

const DeleteCardComfirmModal = ({ visible, onClose, onDelete }) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow'>
        <div className='content text-center bg-primary-100 rounded-lg shadow'>
            <div className='desp text-start p-4 w-75 m-auto'>
            <h4 className='text-primary-800 fw-regular ft-default mb-4'>Delete Card</h4>
                <h5 className='text-gray-600 fw-regular ft-default mb-5'>Are you sure? You want to delete the Card details?</h5>
                <div className='button-box d-md-flex'>
                    <div className='btn submit-btn btn-secondary me-3' onClick={onClose}>Cancel</div>
                    <div className='btn submit-btn btn-primary' onClick={onDelete}>Yes</div>
                </div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}


export default DeleteCardComfirmModal
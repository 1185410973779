import React, { useEffect } from 'react'
import { Routes, Route, useLocation  } from "react-router-dom"

import './assets/scss/styles.scss'

import { publicRoutes, userRoutes, setupRoutes, growthRoutes } from './routes/allRoutes'
import Layout from './components/Layout/Layout'
import Growth from './pages/Growth/Growth'

import Authmiddleware from './routes/middleware/AuthMiddleware';
import LoggedMiddleware from './routes/middleware/LoggedMiddleware'

const App = () => {

  const location = useLocation()

  useEffect(() => {
    var path = location.pathname.split('/')
    if(path[1] !== "")
      document.body.className = path[1]
  },[location]);

  return (
    <React.Fragment>
      <Routes>
          
          { publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={<LoggedMiddleware><route.component/></LoggedMiddleware>} />
          ))}

          { setupRoutes.map((route, index) => (
              <Route key={index} element={<LoggedMiddleware><Layout isSetup={true} /></LoggedMiddleware>}>
                <Route key={index} path={route.path} element={<route.component/>} />
              </Route>
          ))}

          { userRoutes.map((route, index) => (
              <Route key={index} element={<Authmiddleware><Layout isSidebar={true} /></Authmiddleware>}>
                <Route key={index} path={route.path} element={ <route.component/>} />
              </Route>
          ))}

          { growthRoutes.map((route, index) => (
              <Route key={index} element={<Authmiddleware><Layout isSidebar={true} /></Authmiddleware>}>
                <Route key={index} element={ <Growth/> }>
                  <Route key={index} path={route.path} element={ <route.component/>} />
                </Route>
              </Route>
          ))}
          
        </Routes>
    </React.Fragment>
  )
}

export default App
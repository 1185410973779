import {
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILED,
    VIEW_CARD_SUCCESS,
    VIEW_CARD_FAILED,
    UPDATE_CARD_SUCCESS,
    UPDATE_CARD_FAILED,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_FAILED
   } from './actionTypes'
     
   const initialState = {
       error: "",
       loading: false,
       cards: {}
   }
 
   const CardReducer = (state = initialState, action) => {
     
     switch (action.type) {

      case ADD_CARD_SUCCESS:{
        const cards = action.payload
        return {
          ...state,
          cards
        }
      }
      case ADD_CARD_FAILED: {
        return {
          ...state,
          error: action.payload
        }
      }
         
         case VIEW_CARD_SUCCESS:{
           const cards = action.payload
           return {
             ...state,
             cards
           }
         }
         case VIEW_CARD_FAILED: {
           return {
             ...state,
             error: action.payload
           }
         }
         case UPDATE_CARD_SUCCESS:{
          const id = action.payload.data.id
          return {
            ...state,
            cards: state.cards.filter(item => {
              if(item.id === id) {
                item = action.payload.data
              }
              return item
            })
          }
        }
        case UPDATE_CARD_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case DELETE_CARD_SUCCESS:{
          const id = action.payload.data.id
          return {
            ...state,
            cards: state.cards.filter(
              card => card.id.toString() !== id.toString()
            ),
          }
        }
        case DELETE_CARD_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
         default:
           return state
       }
   
     }
     
     export default CardReducer
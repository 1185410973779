import {
    GET_GRADES,
    GET_GRADES_SUCCESS,
    GET_GRADES_FAILED
} from './actionTypes';

export const getGrades = () => {
    return {
        type: GET_GRADES,
        meta:{
            thunk:true
        }
    }
}
  
export const getGradesSuccess = (keyStages, { thunk }) => ({
    type: GET_GRADES_SUCCESS,
    payload: keyStages,
    meta: {
        thunk
    }
})
  
export const getGradesFailed = (error, { thunk }) => ({
    type: GET_GRADES_FAILED,
    payload: error,
    meta: {
        thunk
    }
})
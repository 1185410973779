import {
    VIEW_EXAM_TYPE_SUCCESS,
    VIEW_EXAM_TYPE_FAILED,
    VIEW_CHAPTERS_SUCCESS,
    VIEW_CHAPTERS_FAILED,
    VIEW_LESSON_SCORE_SUCCESS,
    VIEW_LESSON_SCORE_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    exam_type: null,
    chapters: null,
    score: null
}

const LessonScoreReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case VIEW_EXAM_TYPE_SUCCESS: {
        return {
          ...state,
          loading: false,
          exam_type: action.payload,
          error: "",
        }
      }
      case VIEW_EXAM_TYPE_FAILED: {
        return {
          ...state,
          loading: false,
          exam_type: null,
          error: action.payload,
        }
      }
      case VIEW_CHAPTERS_SUCCESS: {
        return {
          ...state,
          loading: false,
          chapters: action.payload,
          error: "",
        }
      }
      case VIEW_CHAPTERS_FAILED: {
        return {
          ...state,
          loading: false,
          chapters: null,
          error: action.payload,
        }
      }
      case VIEW_LESSON_SCORE_SUCCESS: {
        return {
          ...state,
          loading: false,
          score: action.payload,
          error: "",
        }
      }
      case VIEW_LESSON_SCORE_FAILED: {
        return {
          ...state,
          loading: false,
          score: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default LessonScoreReducer;
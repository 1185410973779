
export const freePlanFeatures = [
    "1 Student Account",
    "Videos 1 per chapter",
    "Selftest 1 per Chapter",
    "All past paper pfd download",
    "Revision 1 per chapter",
]

export const proPlanFeatures = [
    "2+ Student Account",
    "Videos all chapter",
    "Worksheet all chapter",
    "All past paper pfd download",
    "Revision all chapter",
    "Challenges all chapter",
    "Queries all chapter"
]
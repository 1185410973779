import { takeEvery, put, call } from "redux-saga/effects"

import { 
    BUY_PLAN, VIEW_ALL_PLANS, VIEW_PLANS, VIEW_MY_SUBSCRIPTIONS, 
    UPDATE_RENEWAL, CHANGE_SUBSCRIPTION, MAKE_PAYMENT, 
    APPLY_COUPON, UPDATE_SUBSCRIPTION, BILLING_DETAILS_SUBMIT, 
    ADD_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD, 
    DELETE_CARD_DETAILS, VIEW_CARD_DETAILS } 
from './actionTypes'
import { 
    buyPlanSuccess, buyPlanFailed, viewAllPlansSuccess, viewAllPlansFailed, viewPlansSuccess, viewPlansFailed, viewSubscriptionsSuccess, viewSubscriptionsFailed, 
    updateRenewalSuccess, updateRenewalFailed, changeSubscriptionSuccess, changeSubscriptionFailed, 
    makePaymentSuccess, makePaymentFailed, applyCouponSuccess, 
    applyCouponFailed, updateSubscriptionSuccess, updateSubscriptionFailed, 
    billingDetailsSubmitSuccess, billingDetailsSubmitFailed, addPaymentMethodSuccess, 
    addPaymentMethodFailed, updatePaymentMethodSuccess, updatePaymentMethodFailed,
    deleteCardDetailsSuccess, deleteCardDetailsFailed, viewCardDetailsSuccess, viewCardDetailsFailed
    } from './actions'

import { buyPlanApi, viewAllPlansApi, viewPlansApi, viewSubscriptionsApi, updateRenewalApi, changeSubscriptionApi, makePaymentApi, applyCouponApi, updateSubscriptionApi, addPaymentMethodApi, updatePaymentMethodApi, deleteCardDetailsApi, viewCardDetailsApi } from "../../helpers/api/api"

function* viewAllPlans({ meta }) {
    try {
        const response = yield call(viewAllPlansApi)
        yield put(viewAllPlansSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewAllPlansFailed(message, meta))
    }
}

function* buyPlan({ payload:data , meta }) {
    try {
        const response = yield call(buyPlanApi,data)
        yield put(buyPlanSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(buyPlanFailed(message, meta))
    }
}

function* viewPlans({ meta }) {
    try {
        const response = yield call(viewPlansApi)
        yield put(viewPlansSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewPlansFailed(message, meta))
    }
}

function* viewSubscriptions({ payload:details, meta }) {
    try {
        const response = yield call(viewSubscriptionsApi, details)
        const { data } = response
        yield put(viewSubscriptionsSuccess(data, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewSubscriptionsFailed(message, meta))
    }
}

function* changeSubscription({ payload:data, meta }) {
    try {
        const response = yield call(changeSubscriptionApi, data)
        yield put(changeSubscriptionSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(changeSubscriptionFailed(message, meta))
    }
}

function* updateRenewal({ payload: data, meta }) {
    try {
        const response = yield call(updateRenewalApi, data)
        const { message } = response
        yield put(updateRenewalSuccess(message, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateRenewalFailed(message, meta))
    }
}

function* makePayment({ payload:data, meta }) {
    try {
        const response = yield call(makePaymentApi, data)
        yield put(makePaymentSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data
        yield put(makePaymentFailed(message, meta))
    }
}

function* billingDetailsSubmit({ payload:data, meta }) {
    try {
        yield put(billingDetailsSubmitSuccess(data, meta))
    }
    catch(error) {
        yield put(billingDetailsSubmitFailed(error, meta))
    }
}

function* applyCoupon({ payload:data, meta }) {
    try {
        const response = yield call(applyCouponApi, data)
        yield put(applyCouponSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data.coupon_code
        yield put(applyCouponFailed(message, meta))
    }
}

function* updateSubscription({ payload:data, meta }) {
    try {
        const response = yield call(updateSubscriptionApi, data)
        yield put(updateSubscriptionSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateSubscriptionFailed(message, meta))
    }
}

function* addAddPaymentMethod({ payload:data, meta }) {
    try {
        const response = yield call(addPaymentMethodApi, data)
        yield put(addPaymentMethodSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data
        yield put(addPaymentMethodFailed(message, meta))
    }
}

function* updateAddPaymentMethod({ payload:data, meta }) {
    try {
        const response = yield call(updatePaymentMethodApi, data)
        yield put(updatePaymentMethodSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data
        yield put(updatePaymentMethodFailed(message, meta))
    }
}

function* deleteCardDetails({ payload:data, meta }) {
    try {
        const response = yield call(deleteCardDetailsApi, data)
        yield put(deleteCardDetailsSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data
        yield put(deleteCardDetailsFailed(message, meta))
    }
}

function* viewCardDetails({ payload:data, meta }) {
    try {
        const response = yield call(viewCardDetailsApi, data)
        yield put(viewCardDetailsSuccess(response.data, meta))
    }
    catch(error) {
        const message=error.response.data
        yield put(viewCardDetailsFailed(message, meta))
    }
}

function* PlanAndPricesSaga() {
    yield takeEvery(BUY_PLAN, buyPlan)
    yield takeEvery(VIEW_ALL_PLANS, viewAllPlans)
    yield takeEvery(VIEW_PLANS, viewPlans)
    yield takeEvery(VIEW_MY_SUBSCRIPTIONS, viewSubscriptions)
    yield takeEvery(UPDATE_RENEWAL, updateRenewal)
    yield takeEvery(CHANGE_SUBSCRIPTION, changeSubscription)
    yield takeEvery(MAKE_PAYMENT, makePayment)
    yield takeEvery(APPLY_COUPON, applyCoupon)
    yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription)
    yield takeEvery(BILLING_DETAILS_SUBMIT, billingDetailsSubmit)
    yield takeEvery(ADD_PAYMENT_METHOD, addAddPaymentMethod)
    yield takeEvery(UPDATE_PAYMENT_METHOD, updateAddPaymentMethod)
    yield takeEvery(DELETE_CARD_DETAILS, deleteCardDetails)
    yield takeEvery(VIEW_CARD_DETAILS, viewCardDetails)
}

export default PlanAndPricesSaga
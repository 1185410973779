import {
    VIEW_LESSON_ACTIVITY_RESULT,
    VIEW_LESSON_ACTIVITY_RESULT_SUCCESS,
    VIEW_LESSON_ACTIVITY_RESULT_FAILED
} from './actionTypes'

export const viewLessonActivityResult = (activity) => {
    return {
        type: VIEW_LESSON_ACTIVITY_RESULT,
        payload: activity,
        meta:{
            thunk:true
        }
    }
}

export const viewLessonActivityResultSuccess = (activity, {thunk}) => {
    return {
        type: VIEW_LESSON_ACTIVITY_RESULT_SUCCESS,
        payload: activity,
        meta: {
            thunk
        }
    }
}

export const viewLessonActivityResultFailed = (activity, {thunk}) => {
    return {
        type: VIEW_LESSON_ACTIVITY_RESULT_FAILED,
        payload: activity,
        error: true,
        meta: {
            thunk
        }
    }
}
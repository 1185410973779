import React, { useState, useEffect } from 'react'
import { Modal } from "reactstrap"

import { useLocation  } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';

import { viewStudent as onViewStudent } from '../../../store/student/actions';
import { viewCompletedAssignment as onViewCompletedAssignment } from '../../../store/assignment/actions';

import ReviewPreSummary from './ReviewPreSummary';
import ActivityQuestionAnsPaper from './ActivityQuestionAnsPaper';

const ReviewModal = ({ visible, selectedLessonItem, onClose }) => {

    const location = useLocation();
    const dispatch = useDispatch()

    const { student } = useSelector(state => ({
        student: state.StudentReducer.student
    }))

    const [ isResultsVisible, setResultsVisible ] = useState(true)

    const reviewAnswer = (status) => {
        setResultsVisible(!status)
    }

    const resetReview = () => {
        onClose()
        setTimeout(() => {
            setResultsVisible(true)
        }, 1000)
    }

    useEffect(() => {
        let currentUrlParams = new URLSearchParams(location.search);
        let student_params = {
            id: currentUrlParams.get('sid')
        }
        dispatch(onViewStudent(student_params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(visible) {
            let currentUrlParams = new URLSearchParams(location.search);
            let assignment_params = {
                status: "complete",
                id: currentUrlParams.get('sid')
            }
            dispatch(onViewCompletedAssignment(assignment_params))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])


    return (
      <Modal isOpen={visible} toggle={onClose} className='fullscreen' backdrop='static'>
          <div className='preview-review-wrapper review'>
            <div className='top bg-primary-500 text-white text-center ft-default fw-medium'>You are currently reviewing {student[0].full_name}’s answer</div>
            { isResultsVisible ? 
                <ReviewPreSummary selectedLessonItem={selectedLessonItem} onReviewAnswer={reviewAnswer} onClose={onClose} />
                :
                <ActivityQuestionAnsPaper selectedLessonItem={selectedLessonItem} onClose={resetReview} type="review" />
            }
          </div>
      </Modal>
    )
  }
  
  export default ReviewModal
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { signupFreeWidget, signupProWidget, upgradeWidget } from '../../../../constants/signupwidget'

const AccountStepper = props => {

  const [ stepperData, setStepperData ] = useState(null)
  const [ stepperIndex, setStepperIndex ] = useState(0)


  const location = useLocation();
  const query = location.search
  
  useEffect(() => {
    let params = new URLSearchParams(query);
    if(query) {
      if(params.get('plan') === 'free') {
        setStepperData(signupFreeWidget)
      }
      else if(params.get('plan') === 'pro') {
        if(params.get('upgrade') === "true") {
          setStepperData(upgradeWidget)
        }
        else {
          setStepperData(signupProWidget)
        }
      }
      else {

      }
    }
    setStepperIndex(parseInt(params.get('sid')))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleStepperIndex = (id) => {
    let className = "";
    if(id < stepperIndex) {
      if(stepperIndex === 2) { // you can go back and switch plan when you are in signup page
        className = "visible"
      }
      else {
        className = "visible no-event"
      }
    }
    else if(id === stepperIndex) {
      className = "visible no-event"
    }
    else {
      className = "no-event"
    }
    return className
  }

  return (
    <div className='create-account-stepper-wrapper'>
        <div className='d-none d-lg-block'>
          { stepperData != null &&  
          <div className='stepper-cointainer'>
            {
              stepperData.map((item, index) => {
                return (
                  <Link key={index} className={`widget-box ${handleStepperIndex(item.id)}  `} to={`/${item.path}`}>
                    <div className='widget-item'>
                      <div className='widget'>
                        <div className="inner"></div>
                      </div>
                      <p className='text-normal position-absolute'>{item.name}</p>
                    </div>
                  </Link>
                )
              })
            }
          </div>
          }
        </div>
        <div className='d-block d-lg-none'></div>
    </div>
  )
}

export default AccountStepper
import React, { useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"

import noAssignmentImg from '../../assets/images/no-assignment-img.png'

const ActivityNoAssignmentModal = ({ visible, onClose}) => {
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow bg-primary-100'>
        <div className='no-assignment-wrapper'>
            <div className='desp text-start px-5 py-3 w-75 m-auto text-center'>
                <h5 className='mb-3'>Oops!</h5>
                <img src={noAssignmentImg} alt="" />
                <p className='mt-3'>There is no any assignment for senior student.</p>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}

export default ActivityNoAssignmentModal
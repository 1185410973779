import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pending, fulfilled } from 'redux-saga-thunk';
import _ from 'underscore'
import moment from 'moment';

import noStudentsImg  from '../../assets/images/growth-block-img.png'


import { viewStudent as onViewStudent } from '../../store/student/actions';
import { viewPlans as onViewPlans } from '../../store/plans-and-prices/actions';
import StudentDetailsCard from './StudentDetailsCard';
import ChosenPlan from '../../components/Common/ChosenPlan';


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../../components/Common/Loader';


library.add(fas, fab);

const StudentProfile = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ planDetails, setplanDetails ] = useState({})
  const [ availableStudents, setAvailableStudents ] = useState([])
  const [ planSubscriptionType, setPlanSubscriptionType ] = useState("")

  const { user, myplan, students, get_student, get_student_success, get_plan_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    students: state.StudentReducer.student,
    myplan: state.PlansAndPricesReducer.myplan,
    get_student: pending(state, "VIEW_STUDENT"),
    get_student_success: fulfilled(state, "VIEW_STUDENT"),
    get_plan_success: fulfilled(state, "VIEW_PLANS")
  }))

  const handleAddStudent = () => {
    if(user.children < parseInt(planDetails.no_of_students)) {
      navigate( { pathname: "/parent/add-student", search: `?sid=5&noc=${parseInt(planDetails.no_of_students)}&plan=pro&subscription=${planSubscriptionType}&add=true&&anoc=${parseInt(user.children)+1}` })
    }
    else {
      navigate( { pathname: "/parent/select-plan", search: `?sid=1&noc=${parseInt(planDetails.no_of_students)}&plan=pro&subscription=${planSubscriptionType}&add=true&anoc=${parseInt(user.children)+1}` })
    }
  }

  const onDeleteSuccess = (id) => {
    let data = availableStudents.filter(item => {
      return parseInt(item.id) !== parseInt(id)
    })
    setAvailableStudents(data)
  }

  useEffect(() => {
    if(!_.isEmpty(user)) {
      let data = { parent_id:user.id }
      dispatch(onViewStudent(data))
      dispatch(onViewPlans())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(get_student_success) {
      setAvailableStudents(students)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_student_success])

  useEffect(() => {
    if(get_plan_success) {
      if(myplan.length !== 0) {
        let duration = moment(myplan[0].end_date).diff(moment(myplan[0].start_date), 'days')
        if(duration === 30) {
          setPlanSubscriptionType('monthly')
        }
        else {
          setPlanSubscriptionType('yearly')
        }
        setplanDetails(myplan[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_plan_success])

  return (
    <div className='student-profile-wrapper'>

      { get_student && <Loader /> }
        
      { get_student_success &&
        <>
          { !_.isEmpty(availableStudents) ? 
            <>
              <div className='d-flex align-items-center mb-5'>
                <h5>Student Account Details</h5>
                <ChosenPlan id={parseInt(planDetails?.plan_id)}>
                  <div className='btn btn-big student-btn ms-auto' onClick={handleAddStudent}><FontAwesomeIcon className="text-white text-h6 pointer me-3" icon="fas fa-plus-circle" />Add Student</div>
                </ChosenPlan>
              </div>
              <div className='student-profile-box mt-4'>
                  { availableStudents.map((item, index) => (
                        <StudentDetailsCard key={index} student={item} onDeleteSuccess={onDeleteSuccess} />
                    ))
                  }
              </div>
            </>
            :
            <div className='no-students-container pt-4'>
              <div className='student-box pt-5'>
                <div className='content row'>
                  <div className='col-sm-12 col-md-8 offset-md-2'>
                    <div className='img-container'>
                          <img src={noStudentsImg} alt="" />
                    </div>
                    <div className='desp-box'>
                      <h6 className='my-3'>No student added</h6>
                      <p className='text-gray-400'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      }

    </div>
  )
}

export default StudentProfile
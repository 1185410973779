import React, { useEffect, useState } from 'react'
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk';

import { updateAssignmentStatus as onUpdateAssignmentStatus } from '../../store/assignment/actions';
import { deleteCompletedAssignment as onDeleteCompletedAssignment } from '../../store/assignment/actions';


import DeleteAssignmentModal from './DeleteAssignmentModal';
import DeleteCompletedAssignmentModal from './DeleteCompletedAssignmentModal';
import ReviewModal from './Preview/ReviewModal';
import { showToastMessage } from '../../helpers/toaster';


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const ActivityAssigned = ({ assignment }) => {

    const dispatch = useDispatch()

    const { status, get_status_success, get_delete_success } = useSelector(state => ({
        status: state.AssignmentReducer.status,
        get_status_success: fulfilled(state, "UPDATE_ASSIGNMENT_STATUS"),
        get_delete_success: fulfilled(state, "DELETE_COMPLETED_ASSIGNMENT")
    }))

    const [ isDeleteModalVisible, setDeleteModalVisible ] = useState(false)
    const [ isDeleteCompletedModalVisible, setDeleteCompletedModalVisible ] = useState(false)
    const [ isReviewParentModalVisible, setReviewParentModalVisible ] = useState(false)

    const [ availableAssignedAssignment, setAvailableAssignedAssignment ] = useState([])
    const [ lessonId, setLessonId ] = useState(null)
    const [ selectedLessonItem, setSelectedLessonItem ] = useState(null)

    const showDeleteAssignmentModal = (id) => {
        setDeleteModalVisible(true)
        setLessonId(id)
    }

    const showDeleteCompletedAssignmentModal = (id) => {
        setDeleteCompletedModalVisible(true)
        setLessonId(id)
    }

    const showReviewModal = (item) => {
        setReviewParentModalVisible(true)
        setSelectedLessonItem(item)
    }

    const closeDeleteModal = () => {
        setDeleteModalVisible(!isDeleteModalVisible)
        setLessonId(null)
    }

    const handleDelete = () => {
        let params = {
            status: 'unassigned',
            id: lessonId
        }
        dispatch(onUpdateAssignmentStatus(params))
    }

    const handleCompletedDelete = () => {
        let params = {
            id: lessonId
        }
        dispatch(onDeleteCompletedAssignment(params))
    }

    const closeCompletedDeleteModal = () => {
        setDeleteCompletedModalVisible(!isDeleteCompletedModalVisible)
        setLessonId(null)
    }

    const closeReviewModal = () => {
        setReviewParentModalVisible(!isReviewParentModalVisible)
        setTimeout(() => {
            setSelectedLessonItem(null)
        }, 1000)
    }

    useEffect(() => {
        if(get_status_success && lessonId) { 
            let newData = availableAssignedAssignment.filter(item => {
                return item.id !== parseInt(status.id)
            })
            setAvailableAssignedAssignment(newData)
            
            setLessonId(null)
            setDeleteModalVisible(false)

            dispatch(clean("UPDATE_ASSIGNMENT_STATUS"))
            showToastMessage('success',"Activity deleted")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_status_success])

    useEffect(() => {
        setAvailableAssignedAssignment(assignment)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignment])


    useEffect(() => {
        if(get_delete_success && lessonId) { 
            let newData = availableAssignedAssignment.filter(item => {
                return item.id !== parseInt(lessonId)
            })
            setAvailableAssignedAssignment(newData)
            
            setLessonId(null)
            setDeleteCompletedModalVisible(false)

            dispatch(clean("DELETE_COMPLETED_ASSIGNMENT"))
            showToastMessage('success',"Completed Activity deleted")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_delete_success])

  return (
    <div className='activity-assigned'>
        { availableAssignedAssignment.length !== 0 ? 
            <table className='table table-responsive mb-0'>
                <thead>
                    <tr>
                        <th>Lesson activity</th>
                        <th>Assign date</th>
                        <th>Review answer</th>
                        <th>Score</th>
                        <th>Completed date</th>
                        <th></th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    { availableAssignedAssignment.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.lesson_activity.number}. {item.lesson_activity.name}</td>
                                <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                                <td className='preview text-center'>
                                    { item.score !== "" ? <span className='pointer' onClick={() => {showReviewModal(item)}}>Review</span> : <span>-</span> }
                                </td>
                                <td className='text-center'>{(item.score === "" ? "-" : item.score)}</td>
                                <td className='text-center'>{(item.score === "" ? "-" : moment(item.updated_at).format('DD/MM/YYYY'))}</td>
                                <td>
                                    {(item.score === "" ? "-" : <FontAwesomeIcon className="text-primary-500 text-h6 pointer" icon="fas fa-check" />)}
                                </td>
                                <td className='text-center'>
                                    { item.score === "" ?
                                        <FontAwesomeIcon className="text-gray-280 text-h6 pointer" icon="fas fa-trash" onClick = {() => {showDeleteAssignmentModal(item.id)}} />
                                        :
                                        <FontAwesomeIcon className="text-gray-280 text-h6 pointer" icon="fas fa-trash" onClick = {() => {showDeleteCompletedAssignmentModal(item.id)}} />
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            :
            <div className='p-4 text-center ft-default text-base'>No data available</div>
        }
        <DeleteAssignmentModal visible={isDeleteModalVisible}
            onHandleDelete = { () => {handleDelete()} }
            onClose = {closeDeleteModal}/>
        <DeleteCompletedAssignmentModal visible={isDeleteCompletedModalVisible}
            onHandleDelete = { () => {handleCompletedDelete()} }
            onClose = {closeCompletedDeleteModal}/>
        <ReviewModal visible={isReviewParentModalVisible}
            selectedLessonItem={selectedLessonItem}
            onClose = {closeReviewModal}/>
    </div>
  )
}


export default ActivityAssigned
import { call, put, takeEvery } from "redux-saga/effects"

import { ADD_STUDENT, VIEW_STUDENT, UPDATE_STUDENT, DELETE_STUDENT } from "./actionTypes"
import { addStudentSuccess, addStudentFailed, viewStudentSuccess, viewStudentFailed, updateStudentSuccess, updateStudentFailed, deleteStudentSuccess, deleteStudentFailed } from "./actions"

import { addStudentApi, viewStudentApi, updateStudentApi, deleteStudentApi } from '../../helpers/api/api'

function* addStudent({ payload: student, meta }) {
    try {
        const response = yield call(addStudentApi,student)
        yield put(addStudentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(addStudentFailed(message, meta))
    }
    
}

function* viewStudent({ payload: student, meta }) {
    try {
        const response = yield call(viewStudentApi,student)
        yield put(viewStudentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewStudentFailed(message, meta))
    }
    
}

function* updateStudent({ payload: student, meta }) {
    try {
        const response = yield call(updateStudentApi,student)
       yield put(updateStudentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateStudentFailed(message, meta))
    }
    
}

function* deleteStudent({ payload: student, meta }) {
    try {
       const response = yield call(deleteStudentApi,student)
       yield put(deleteStudentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(deleteStudentFailed(message, meta))
    }
    
}

function* StudentSaga() {
    yield takeEvery(ADD_STUDENT, addStudent)
    yield takeEvery(VIEW_STUDENT, viewStudent)
    yield takeEvery(UPDATE_STUDENT, updateStudent)
    yield takeEvery(DELETE_STUDENT, deleteStudent)
}
  
  export default StudentSaga
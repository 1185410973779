export const VIEW_EXAM_TYPE = "VIEW_EXAM_TYPE"
export const VIEW_EXAM_TYPE_SUCCESS = "VIEW_EXAM_TYPE_SUCCESS"
export const VIEW_EXAM_TYPE_FAILED = "VIEW_EXAM_TYPE_FAILED"

export const VIEW_CHAPTERS = "VIEW_CHAPTERS"
export const VIEW_CHAPTERS_SUCCESS = "VIEW_CHAPTERS_SUCCESS"
export const VIEW_CHAPTERS_FAILED = "VIEW_CHAPTERS_FAILED"

export const VIEW_LESSON_SCORE = "VIEW_LESSON_SCORE"
export const VIEW_LESSON_SCORE_SUCCESS = "VIEW_LESSON_SCORE_SUCCESS"
export const VIEW_LESSON_SCORE_FAILED = "VIEW_LESSON_SCORE_FAILED"
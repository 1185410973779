import {
    GET_KEY_STAGES,
    GET_KEY_STAGES_SUCCESS,
    GET_KEY_STAGES_FAILED
} from './actionTypes';

export const getKeyStages = () => {
    return {
        type: GET_KEY_STAGES,
        meta:{
            thunk:true
        }
    }
}
  
export const getKeyStagesSuccess = (keyStages, { thunk }) => ({
    type: GET_KEY_STAGES_SUCCESS,
    payload: keyStages,
    meta: {
        thunk
    }
})
  
export const getKeyStagesFailed = (error, { thunk }) => ({
    type: GET_KEY_STAGES_FAILED,
    payload: error,
    meta: {
        thunk
    }
})
import React, { useState, useEffect } from 'react'
import _ from 'underscore'


const GrowthScoreCard = ({ score }) => {
  
  const [ totalScore, setTotalScore ] = useState(0)
  const [ percentage, setPercentage ] = useState(0)
  
  useEffect(() => {
    let data = 0
    let percent = 0;
    score.forEach((item, index) => {
      data = data + parseInt(item.score)
      percent = (data/(index+1)).toFixed(2)
      setTotalScore(data)
      setPercentage(percent)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score])

  
  return (
    <div className='growth-score-card-wrapper h-100'>
      { !_.isEmpty(score) ? 
        <div className='inner h-100'>
            <div className='head d-flex bg-gray-150'>
              <div className='name'>Lesson name</div>
              <div className='score'>Score</div>
            </div>
          <div className='bottom d-flex'>
            <div className='list-box'>
              { score.map((item => {
              return (
                <div className='desp d-flex'>
                  <div className='name'>{item.lesson.number}&nbsp;&nbsp;{item.lesson.name}</div>
                  <div className='score'>{item.score}</div>
                </div>
              )
              })) }
            </div>
            <div className='result d-flex'>
              <div className='total'>Total score: {totalScore}</div>
              <div className='average'>Average percentage: {percentage}%</div>
            </div>
          </div>
        </div>
        : 
        <div className='empty'>
          <h6 className='text-gray-350 ft-default'>No data available</h6>
        </div>
      }
      
    </div>
  )
}


export default GrowthScoreCard
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk';

import { Modal, ModalBody } from "reactstrap"

import moment from 'moment';

import closeIcon from "../../../assets/images/modal-close-icon.svg"
import PlanFeatures from '../../../components/Common/PlanFeatures';
import SelectInput from '../../../components/Common/SelectInput';

import {autoRenewalOptionsData} from "../../../constants/variousdata"
import { showToastMessage } from '../../../helpers/toaster';

import { updateRenewal as onUpdateRenewal } from '../../../store/plans-and-prices/actions';
import { viewPlans as onViewPlans } from '../../../store/plans-and-prices/actions';

const AutoRenewalModal = ({ user, subscriptions, visible, isAutoRenewalOn, onClose } ) => {

  const dispatch = useDispatch()
  const [ isOptionSelected, setOptionSelected ] = useState(false)
  const [ planSubscriptionType, setPlanSubscriptionType ] = useState("")

  const { myplan, get_success, get_plan_success } = useSelector(state => ({
    myplan: state.PlansAndPricesReducer.myplan,
    get_success: fulfilled(state, "UPDATE_RENEWAL"),
    get_plan_success: fulfilled(state, "VIEW_PLANS")
  }))

  const handleAutoRenewalOptions = () => {
    setOptionSelected(true)
  }

  const handleAutoRenewal = () => {
    dispatch(onUpdateRenewal())
  }

  useEffect(() => {
    dispatch(onViewPlans())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(get_success && visible) {
      dispatch(clean("UPDATE_RENEWAL"))
      showToastMessage('success',"Renewal Updated")
      setOptionSelected(false)
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[get_success])

  useEffect(() => {
    if(get_plan_success) {
      if(myplan.length !== 0 ) {
        let duration = moment(myplan[0].end_date).diff(moment(myplan[0].start_date), 'days')
        if(duration === 30) {
          setPlanSubscriptionType('monthly')
        }
        else {
          setPlanSubscriptionType('yearly')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[get_plan_success])

  useEffect(() => {
    if(visible) {
        setOptionSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible])

  return (
    <Modal isOpen={visible} toggle={onClose} size='xl'>
        <ModalBody>
            <div className='content text-center bg-primary-100 rounded-lg shadow'>
                <div className='title'>
                    <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
                </div>
                { visible && subscriptions.plan && 
                    <div className='desp text-start mt-5 p-4'>
                        <div className='row'>
                            <div className={`${isAutoRenewalOn ? "col-md-6" : "col-md-12"}`}>
                                <div className='mx-2'>
                                    <h3 className='mb-4 ft-default text-gray-800'>Keep Enjoying pro Until {moment(subscriptions.end_date).format("Do MMMM YYYY")}</h3>
                                    <h4 className='fw-regular ft-default lh-normal text-gray-700'>Since you are {planSubscriptionType === 'monthly' ? "a monthly" : "an annual"} subscriber, you will get to keep these benefits until the end of your plan. Leave auto renewal on to keep the benefits going Even after that.</h4>
                                    <div className='mt-5'>
                                        <PlanFeatures id={subscriptions.plan.id} title={false} inline={false} />
                                    </div>
                                    { !isAutoRenewalOn && <h4 className="fw-regular ft-default text-primary-950 pointer mt-4" onClick={handleAutoRenewal} >Continue to turn { isAutoRenewalOn ? "off" : "on" } auto renewal</h4> }
                                </div>
                            </div>
                            { isAutoRenewalOn &&  <div className='col-md-6'>
                                <div className='mx-2'>
                                    <h3 className='mb-4 ft-default text-gray-800'>Are you sure you want to turn Auto renewal off?</h3>
                                    <h4 className='fw-regular ft-default lh-normal text-gray-700'>Disabling auto renewal means your plan will expire on {moment(subscriptions.end_date).format("Do MMMM YYYY")} at {moment(subscriptions.end_date).format("h:mm:ss")} GMT. You will continue to be billed next year for your plan.</h4>
                                    <div className='mt-4 btn btn-primary rounded-lg w-50' onClick={onClose} >Keep { isAutoRenewalOn ? "On" : "Off" }</div>
                                    <div className='options-box mt-5'>
                                        <h4 className='text-gray-800'>Otherwise tell us why you want to turn it { isAutoRenewalOn ? "off" : "on" }: *</h4>
                                        <form className='mt-4'>
                                            <SelectInput
                                            field="name"
                                            data={autoRenewalOptionsData}  
                                            placeholder="Select an option"
                                            onChange={handleAutoRenewalOptions}
                                            />
                                        </form>
                                        <h4 className={`fw-regular ft-default mt-4 ${ isOptionSelected ? "text-primary-950 pointer" : "text-gray-250 no-event" }`} onClick={handleAutoRenewal} >Continue to turn { isAutoRenewalOn ? "off" : "on" } auto renewal</h4>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                }
            </div>
        </ModalBody>
    </Modal>
  )
}

export default AutoRenewalModal
import { post } from "../api_helper"
import * as api from "../url_helper"

//Authentication
export const loginApi = data => {
    return post(api.PARENT_LOGIN, data)
}
export const viewUserApi = data => {
    return post(api.VIEW_USER_PROFILE, data)
}
export const updateUserApi = data => {
    return post(api.UPDATE_USER_PROFILE, data)
}
export const logoutApi = data => {
    return post(api.PARENT_LOGOUT, data)
}
export const requestPasswordEmailApi = data => {
    return post(api.REQUEST_PASSWORD_EMAIL, data)
}
export const resetPasswordEmailApi = data => {
    return post(api.RESET_PASSWORD_EMAIL, data)
}

export const contactAdminApi = data => {
    return post(api.CONTACT_ADMIN, data)
}

//Signup
export const signupApi = data => {
    return post(api.PARENT_SIGNUP, data)
}

//Otp
export const verifyOtpApi = data => {
    return post(api.VERIFY_OTP, data)
}
export const resendOtpApi = data => {
    return post(api.RESEND_OTP, data)
}

//Plan
export const buyPlanApi = data => {
    return post(api.BUY_PLAN, data)
}
export const viewAllPlansApi = data => {
    return post(api.VIEW_ALL_PLANS, data)
}
export const viewPlansApi = data => {
    return post(api.VIEW_PLANS, data)
}
export const viewSubscriptionsApi = data => {
    return post(api.VIEW_MY_SUBSCRIPTIONS, data)
}
export const updateRenewalApi = data => {
    return post(api.UPDATE_RENEWAL, data)
}
export const changeSubscriptionApi = data => {
    return post(api.CHANGE_SUBSCRIPTION, data)
}
export const makePaymentApi = data => {
    return post(api.MAKE_PAYMENT, data)
}
export const applyCouponApi = data => {
    return post(api.APPLY_COUPON, data)
}
export const updateSubscriptionApi = data => {
    return post(api.UPDATE_SUBSCRIPTION, data)
}
export const addPaymentMethodApi = data => {
    return post(api.ADD_PAYMENT_METHOD, data)
}
export const updatePaymentMethodApi = data => {
    return post(api.UPDATE_PAYMENT_METHOD, data)
}
export const deleteCardDetailsApi = data => {
    return post(api.DELETE_CARD_DETAILS, data)
}
export const viewCardDetailsApi = data => {
    return post(api.VIEW_CARD_DETAILS, data)
}

// Settings
export const getGradesApi = () => {
    return post(api.GET_GRADES)
}
export const getKeyStagesApi = () => {
    return post(api.GET_KEY_STAGES)
}
export const getExamboardsApi = () => {
    return post(api.GET_EXAM_BOARDS)
}

//Student
export const addStudentApi = data => {
    return post(api.ADD_STUDENT, data)
}
export const viewStudentApi = data => {
    return post(api.VIEW_STUDENT, data)
}
export const updateStudentApi = data => {
    return post(api.UPDATE_STUDENT, data)
}
export const deleteStudentApi = data => {
    return post(api.DELETE_STUDENT, data)
}

//Subject
export const viewSubjectApi = data => {
    return post(api.VIEW_SUBJECT, data)
}

//Reward
export const viewRewardApi = data => {
    return post(api.VIEW_REWARD, data)
}
export const viewRewardCategoryApi = data => {
    return post(api.VIEW_REWARD_CATEGORY, data)
}
export const createRewardApi = data => {
    return post(api.CREATE_REWARD, data)
}
export const updateRewardApi = data => {
    return post(api.UPDATE_REWARD, data)
}
export const deleteRewardApi = data => {
    return post(api.DELETE_REWARD, data)
}

//Badge
export const viewBadgesApi = data => {
    return post(api.VIEW_BADGES, data)
}
export const viewBadgesTypeApi = data => {
    return post(api.VIEW_BADGES_TYPE, data)
}
export const createBadgeApi = data => {
    return post(api.CREATE_BADGE, data)
}
export const updateBadgeApi = data => {
    return post(api.UPDATE_BADGE, data)
}
export const deleteBadgeApi = data => {
    return post(api.DELETE_BADGE, data)
}
//Reward Type
export const viewRewardTypeApi = data => {
    return post(api.VIEW_REWARD_TYPE, data)
}

//Lesson
export const viewExamTypeApi = data => {
    return post(api.VIEW_EXAM_TYPE, data)
}
export const viewChaptersApi = data => {
    return post(api.VIEW_CHAPTERS, data)
}
export const viewLessonScoreApi = data => {
    return post(api.VIEW_LESSON_SCORE, data)
}

//Card
export const addCardApi = data => {
    return post(api.ADD_CARD, data)
}
export const viewCardApi = data => {
    return post(api.VIEW_CARD, data)
}
export const updateCardApi = data => {
    return post(api.UPDATE_CARD, data)
}
export const deleteCardApi = data => {
    return post(api.DELETE_CARD, data)
}

//Billing
export const viewBillingInfoApi = data => {
    return post(api.VIEW_BILLING_INFO, data)
}
export const addBillingInfoApi = data => {
    return post(api.ADD_BILLING_INFO, data)
}
export const updateBillingInfoApi = data => {
    return post(api.UPDATE_BILLING_INFO, data)
}


//Notification
export const viewNotificationsApi = data => {
    return post(api.VIEW_NOTIFICATIONS, data)
}
export const updateNotificationApi = data => {
    return post(api.UPDATE_NOTIFICATION, data)
}
export const viewNotificationSettingApi = data => {
    return post(api.VIEW_NOTIFICATION_SETTING, data)
}
export const updatewNotificationSettingApi = data => {
    return post(api.UPDATE_NOTIFICATION_SETTING, data)
}

//Faq
export const viewFaqApi = data => {
    return post(api.VIEW_FAQ, data)
}

//Assignment
export const viewAssignmentLessonApi = data => {
    return post(api.VIEW_ASSIGNMENT_LESSON, data)
}
export const viewAssignedAssignmentApi = data => {
    return post(api.VIEW_ASSIGNED_ASSIGNMENT, data)
}
export const viewAssignmentChapterApi = data => {
    return post(api.VIEW_ASSIGNMENT_CHAPTER, data)
}
export const updateAssignmentStatusApi = data => {
    return post(api.UPDATE_ASSIGNMENT_STATUS, data)
}
export const createPracticeTestApi = data => {
    return post(api.CREATE_PRACTICE_TEST, data)
}
export const deleteCompletedAssignmentApi = data => {
    return post(api.DELETE_COMPLETED_ASSIGNMENT, data)
}
export const viewCompletedAssignmentApi = data => {
    return post(api.VIEW_COMPLETED_ASSIGNMENT, data)
}

//Lesson Activity
export const viewLessonActivityResultApi = data => {
    return post(api.VIEW_LESSON_ACTIVITY_RESULT, data)
}

//Notice
export const viewNoticeApi = data => {
    return post(api.VIEW_NOTICE, data)
}
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'

import { fulfilled, clean } from 'redux-saga-thunk'
import { Tooltip } from "reactstrap"

import _ from 'underscore'

import growthBlockImg  from '../../../assets/images/growth-block-img.png'

import CreateBadgeModal from './CreateBadgeModal';
import GrowthBadgeCard from './GrowthBadgeCard';
import BadgeDeleteModal from './BadgeDeleteModal';

import { viewBadges as onViewBadges } from '../../../store/actions';
import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { viewBadgesType as onViewBadgesType } from '../../../store/actions';

import { viewStudent as onViewStudent } from '../../../store/student/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const GrowthBadges = props => {

  const dispatch = useDispatch()
  const location = useLocation()

  const [ isBadgesModalVisible, setBadgesModalVisible ] = useState(false)
  const [ isBadgeDeleteModalVisible, setBadgeDeleteModalVisible ] = useState(false)

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [isEdit, setEdit] = useState(false);
  const [isStudentAvailable, setStudentAvailable] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState(null);

  const { badges, students, subject, badges_type, get_success, get_subject_success, get_student_success } = useSelector(state => ({
    badges: state.BadgesReducer.badges,
    students: state.StudentReducer.student,
    badges_type: state.BadgesReducer.badges_type,
    subject: state.SubjectReducer.subject,
    get_success: fulfilled(state, "VIEW_BADGES"),
    get_badges_type_success:  fulfilled(state, "VIEW_BADGES_TYPE"),
    get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
    get_student_success: fulfilled(state, "VIEW_STUDENT")
  }))

  const onClose = () => {
    
    setBadgesModalVisible(false)
    setSelectedBadge(null)
    setEdit(false)

  }

  const onCreate = () => {
    
    setBadgesModalVisible(false)
    setSelectedBadge(null)
    setEdit(false)

    let currentUrlParams = new URLSearchParams(location.search);
    let student_id = currentUrlParams.get('sid')

    let data = {
      user_id: student_id
    }
    dispatch(onViewBadges(data))

  }

  const handleCreateBadge = () => {
    setBadgesModalVisible(!isBadgesModalVisible)
  }

  const handleEditBadge = (item) => {
    setEdit(true)
    setSelectedBadge(item)
    setBadgesModalVisible(!isBadgesModalVisible)
  }

  const showDeleteModal = () => {
    setBadgesModalVisible(false)
    setBadgeDeleteModalVisible(!isBadgeDeleteModalVisible)
  }

  const onCloseDelete = () => {
    setBadgeDeleteModalVisible(!isBadgeDeleteModalVisible)
    setSelectedBadge(null)
  }


  useEffect(() => {

    let currentUrlParams = new URLSearchParams(location.search);
    let student_id = currentUrlParams.get('sid')

    if(student_id) {

      setStudentAvailable(true)

      let data = {
        user_id: student_id
      }
      dispatch(onViewBadges(data))
      dispatch(onViewSubject())
      dispatch(onViewBadgesType())
      setBadgesModalVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(location.search);
    let student_id = currentUrlParams.get('sid')
    let data = {
      user_id: student_id
    }
    dispatch(onViewStudent(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_success) {
      dispatch(clean("VIEW_BADGES"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    if(get_student_success) {
      dispatch(clean("VIEW_STUDENT"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_student_success])


  return (
    <div className='growth-inner-wrapper badges-wrapper'>
      <div className='btn-container d-flex justify-content-end'>
        <div className='btn tab-btn sp-btn' onClick={handleCreateBadge}>
        <span className='circle'>
          <FontAwesomeIcon icon="fa-solid fa-plus" />
        </span>
          <span className='d-none d-xl-inline-block'>Create Badges</span>
        </div>
      </div>
      <div className='left-box bg-white'>
        
        { _.isEmpty(badges) ? (
          <div className='no-results'>
            <div className='img-container'>
              <img src={growthBlockImg} alt="" />
            </div>
            <div className='desp-box'>
              <h6 className='title'>No Badge created</h6>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis eligendi atque repudiandae ipsam aspernatur</p>
            </div>
          </div>
        ) : (
          <div className='badges-box'>
            <div className='d-flex align-items-center'>
              <h3 className='text-gray-950 ft-default fw-normal'>Badges</h3>
              <h4 className='ms-4 text-primary-500' id="infoTooltip"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></h4>
              <Tooltip
                isOpen={tooltipOpen}
                placement="right"
                target="infoTooltip"
                toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                To create new reward click on the create new button
              </Tooltip>
            </div>
            { isStudentAvailable && <h3 className='text-gray-950 ft-default fw-semibold mt-3 mb-5'>Hello {students[0]?.full_name}, <span className='fw-normal'>We have some badges for you!</span></h3> }
            <div className='row'>
              { get_subject_success && badges && badges.map((item, index) => {
                return (
                  <div key={index} className='col-xs-12 col-sm-6 col-md-4 col-xl-3'>
                    <GrowthBadgeCard badge={item} subject={subject} badges_type={badges_type} onHandleEditBadge={handleEditBadge} />
                  </div>
                )
              }) }
            </div>
          </div>
        ) }
      </div>
      <CreateBadgeModal 
        visible={isBadgesModalVisible}
        isEdit={isEdit}
        badge={selectedBadge}
        subject={subject}
        badges_type={badges_type}
        onClose = {onClose}
        onDelete = {showDeleteModal}
        onCreate = {onCreate}
      />
      <BadgeDeleteModal
        visible={isBadgeDeleteModalVisible}
        badge={selectedBadge}
        onClose = {onCloseDelete}
      />
    </div>
  )
}


export default GrowthBadges
import {
    BUY_PLAN, 
    BUY_PLAN_SUCCESS,
    BUY_PLAN_FAILED,
    VIEW_ALL_PLANS,
    VIEW_ALL_PLANS_SUCCESS,
    VIEW_ALL_PLANS_FAILED,
    VIEW_PLANS,
    VIEW_PLANS_SUCCESS,
    VIEW_PLANS_FAILED,
    VIEW_MY_SUBSCRIPTIONS,
    VIEW_MY_SUBSCRIPTIONS_SUCCESS,
    VIEW_MY_SUBSCRIPTIONS_FAILED,
    UPDATE_RENEWAL_SUCCESS,
    UPDATE_RENEWAL_FAILED,
    CHANGE_SUBSCRIPTION_SUCCESS,
    CHANGE_SUBSCRIPTION_FAILED,
    MAKE_PAYMENT_SUCCESS,
    MAKE_PAYMENT_FAILED,
    BILLING_DETAILS_SUBMIT_SUCCESS,
    BILLING_DETAILS_SUBMIT_FAILED,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAILED,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_FAILED,
    ADD_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD_FAILED,
    DELETE_CARD_DETAILS_SUCCESS,
    DELETE_CARD_DETAILS_FAILED,
    VIEW_CARD_DETAILS_SUCCESS,
    VIEW_CARD_DETAILS_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    plan: null,
    all_plans: null,
    myplan: null,
    subscriptions: null,
    autorenewal: "",
    payment: null,
    coupon: null,
    billing: null,
    error_payment: null,
    cards: null
}


const PlansAndPricesReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case BUY_PLAN: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case BUY_PLAN_SUCCESS: {
        return {
          ...state,
          loading: false,
          plan: action.payload,
          error: "",
        }
      }
      case BUY_PLAN_FAILED: {
        return {
          ...state,
          loading: false,
          plan: null,
          error: action.payload,
        }
      }
      case VIEW_ALL_PLANS: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case VIEW_ALL_PLANS_SUCCESS: {
        return {
          ...state,
          loading: false,
          all_plans: action.payload,
          error: "",
        }
      }
      case VIEW_ALL_PLANS_FAILED: {
        return {
          ...state,
          loading: false,
          all_plans: null,
          error: action.payload,
        }
      }
      case VIEW_PLANS: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case VIEW_PLANS_SUCCESS: {
        return {
          ...state,
          loading: false,
          myplan: action.payload,
          error: "",
        }
      }
      case VIEW_PLANS_FAILED: {
        return {
          ...state,
          loading: false,
          myplan: null,
          error: action.payload,
        }
      }
      case VIEW_MY_SUBSCRIPTIONS: {
        return {
          ...state,
          loading: true,
          error: "",
        }
      }
      case VIEW_MY_SUBSCRIPTIONS_SUCCESS: {
        return {
          ...state,
          loading: false,
          subscriptions: action.payload,
          error: "",
        }
      }
      case VIEW_MY_SUBSCRIPTIONS_FAILED: {
        return {
          ...state,
          loading: false,
          subscriptions: null,
          error: action.payload,
        }
      }
      case CHANGE_SUBSCRIPTION_SUCCESS: {
        return {
          ...state,
          loading: false,
          subscriptions: action.payload,
          error: "",
        }
      }
      case CHANGE_SUBSCRIPTION_FAILED: {
        return {
          ...state,
          loading: false,
          subscriptions: null,
          error: action.payload,
        }
      }
      case UPDATE_RENEWAL_SUCCESS: {
        return {
          ...state,
          autorenewal: action.payload
        }
      }
      case UPDATE_RENEWAL_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      case MAKE_PAYMENT_SUCCESS: {
        return {
          ...state,
          payment: action.payload
        }
      }
      case MAKE_PAYMENT_FAILED: {
        return {
          ...state,
          error_payment: action.payload,
        }
      }
      case BILLING_DETAILS_SUBMIT_SUCCESS: {
        return {
          ...state,
          billing: action.payload
        }
      }
      case BILLING_DETAILS_SUBMIT_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      case APPLY_COUPON_SUCCESS: {
        return {
          ...state,
          coupon: action.payload
        }
      }
      case APPLY_COUPON_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      case UPDATE_SUBSCRIPTION_SUCCESS: {
        return {
          ...state,
          loading: false,
          subscriptions: action.payload,
          error: "",
        }
      }
      case UPDATE_SUBSCRIPTION_FAILED: {
        return {
          ...state,
          loading: false,
          subscriptions: null,
          error: action.payload,
        }
      }
      case ADD_PAYMENT_METHOD_SUCCESS: {
        return {
          ...state,
          payment: action.payload
        }
      }
      case ADD_PAYMENT_METHOD_FAILED: {
        return {
          ...state,
          error_payment: action.payload,
        }
      }
      case VIEW_CARD_DETAILS_SUCCESS: {
        return {
          ...state,
          cards: action.payload
        }
      }
      case VIEW_CARD_DETAILS_FAILED: {
        return {
          ...state,
          error_payment: action.payload,
        }
      }
      case DELETE_CARD_DETAILS_SUCCESS: {
      
        return {
          ...state,
          cards: action.payload
        }
      }
      case DELETE_CARD_DETAILS_FAILED: {
        return {
          ...state,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default PlansAndPricesReducer;
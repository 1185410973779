import {
    VIEW_EXAM_TYPE,
    VIEW_EXAM_TYPE_SUCCESS,
    VIEW_EXAM_TYPE_FAILED,
    VIEW_CHAPTERS,
    VIEW_CHAPTERS_SUCCESS,
    VIEW_CHAPTERS_FAILED,
    VIEW_LESSON_SCORE,
    VIEW_LESSON_SCORE_SUCCESS,
    VIEW_LESSON_SCORE_FAILED
} from './actionTypes'

export const viewExamType = (exam) => {
    return {
        type: VIEW_EXAM_TYPE,
        payload: exam,
        meta:{
            thunk:true
        }
    }
}

export const viewExamTypeSuccess = (exam, {thunk}) => {
    return {
        type: VIEW_EXAM_TYPE_SUCCESS,
        payload: exam,
        meta: {
            thunk
        }
    }
}

export const viewExamTypeFailed = (exam, {thunk}) => {
    return {
        type: VIEW_EXAM_TYPE_FAILED,
        payload: exam,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewChapters = (chapters) => {
    return {
        type: VIEW_CHAPTERS,
        payload: chapters,
        meta:{
            thunk:true
        }
    }
}

export const viewChaptersSuccess = (chapters, {thunk}) => {
    return {
        type: VIEW_CHAPTERS_SUCCESS,
        payload: chapters,
        meta: {
            thunk
        }
    }
}

export const viewChaptersFailed = (chapters, {thunk}) => {
    return {
        type: VIEW_CHAPTERS_FAILED,
        payload: chapters,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewLessonScore = (score) => {
    return {
        type: VIEW_LESSON_SCORE,
        payload: score,
        meta:{
            thunk:true
        }
    }
}

export const viewLessonScoreSuccess = (score, {thunk}) => {
    return {
        type: VIEW_LESSON_SCORE_SUCCESS,
        payload: score,
        meta: {
            thunk
        }
    }
}

export const viewLessonScoreFailed = (score, {thunk}) => {
    return {
        type: VIEW_LESSON_SCORE_FAILED,
        payload: score,
        error: true,
        meta: {
            thunk
        }
    }
}
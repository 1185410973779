import {
  REQUEST_PASSWORD_EMAIL, REQUEST_PASSWORD_EMAIL_SUCCESS, REQUEST_PASSWORD_EMAIL_FAILED,
  RESET_PASSWORD_EMAIL, RESET_PASSWORD_EMAIL_SUCCESS, RESET_PASSWORD_EMAIL_FAILED
  } from './actionTypes'
  
  
  const INIT_STATE = {
    error: null,
    message: null,
    loading: false,
    email: null,
    reset: null
  }
  
  const RequestPasswordReducer = (state = INIT_STATE, action) => {
  
      switch (action.type) {
        
        case REQUEST_PASSWORD_EMAIL: {
          return {
            ...state,
            loading: true,
            error: "",
          }
        }
        case REQUEST_PASSWORD_EMAIL_SUCCESS: {
          const email = action.payload
          return {
            ...state,
            loading: false,
            email,
            error: "",
          }
        }
        case REQUEST_PASSWORD_EMAIL_FAILED: {
          return {
            ...state,
            loading: false,
            email: null,
            error: action.payload,
          }
        }
        case RESET_PASSWORD_EMAIL: {
          return {
            ...state,
            loading: true,
            error: "",
          }
        }
        case RESET_PASSWORD_EMAIL_SUCCESS: {
          const reset = action.payload
          return {
            ...state,
            loading: false,
            reset,
            error: "",
          }
        }
        case RESET_PASSWORD_EMAIL_FAILED: {
          return {
            ...state,
            loading: false,
            reset: null,
            error: action.payload,
          }
        }
        default:
          return state
      }
  
  }
  
  export default RequestPasswordReducer;
import React from 'react'

import logo from "../../../assets/images/logo-big.png"
import blockBg from "../../../assets/images/signin-block-bg.png"

import SignInForm from './SignInForm'

const SignIn = props => {
  return (
    <div className="signin-wrapper d-inline-block w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="logo-container d-none d-lg-flex">
              <img src={logo} alt="" />
            </div>
            <div className="content-container d-flex w-100">
              <div className="left d-none d-lg-block">
                <img className="img-fluid block-img" src={blockBg} alt="" />
              </div>
              <div className="right">
                <div className="logo-container position-static d-lg-none">
                  <img src={logo} alt="" />
                </div>
                <div className="row">
                  <div className="col-12 col-sm-10 col-lg-8 offset-lg-2 offset-sm-1">
                    <SignInForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default SignIn
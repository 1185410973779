import {
    CONTACT_ADMIN,
    CONTACT_ADMIN_SUCCESS,
    CONTACT_ADMIN_FAILED
} from './actionTypes'

export const contactAdmin = (data) => {
    return {
        type: CONTACT_ADMIN,
        payload: data,
        meta:{
            thunk:true
        }
    }
}

export const contactAdminSuccess = (data, {thunk}) => {
    return {
        type: CONTACT_ADMIN_SUCCESS,
        payload: data,
        meta: {
            thunk
        }
    }
}

export const contactAdminFailed = (error, {thunk}) => {
    return {
        type: CONTACT_ADMIN_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';

import MyStudent from './MyStudent';
import AssignedLesson from './AssignedLesson';

import { viewNotice as onViewNotice } from '../../store/notice/actions'

const Dashboard = props => {

  const dispatch = useDispatch()

  const [ availableNotice, setAvailableNotice ] = useState([])

  const { user, notice, get_notice_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    notice: state.NoticeReducer.notice,
    get_notice_success: fulfilled(state, "VIEW_NOTICE")
  }))

  useEffect(() => {
    dispatch(onViewNotice())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_notice_success) {
      setAvailableNotice(notice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_notice_success])

  return (
    <div className='dashboard-wrapper bg-primary-100'>
      <div className='dashboard-container'>
        <h4 className='text-capitalize mb-3 fw-light'>Good Morning, <span className='fw-medium'>{user.first_name}</span></h4>
        <div className='content'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='feature-card bg-primary-300 h-100'>
                { availableNotice.map((item, index) => {
                  return (
                    <div key={index} className='notice-box'>
                      <h3 className='mb-3 fw-semibold'>{item.title}</h3>
                      <p className='text-sm'>{item.description}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='col-md-4'>
              <AssignedLesson />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-xl-10'>
             <MyStudent />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
import React from 'react'
import { Modal, ModalBody } from "reactstrap"


const DeleteCompletedAssignmentModal = ({ visible, onClose, onHandleDelete }) => {

  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow'>
        <div className='delete-assignment-wrapper content text-center bg-primary-100 rounded-lg shadow'>
            <div className='desp text-start my-4 p-5 bg-white w-75 m-auto'>
                <h4 className='text-primary-500 mb-4 ft-default'>Delete completed lesson activity?</h4>
                <p className='text-gray-700 text-h6 ft-default pb-5'>Are you sure you want to delete the Completed lesson activity.</p>
                <div className='button-box d-md-flex'>
                    <div className='btn btn-secondary' onClick={onClose}>Cancel</div>
                    <div className='btn btn-primary' onClick={onHandleDelete}>Yes</div>
                </div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}

export default DeleteCompletedAssignmentModal
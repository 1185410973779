import {
  VIEW_BILLING_INFO_SUCCESS,
  VIEW_BILLING_INFO_FAILED,
  ADD_BILLING_INFO_SUCCESS,
  ADD_BILLING_INFO_FAILED,
  UPDATE_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_FAILED
} from './actionTypes'

const initialState = {
    error: "",
    loading: false,
    bills: []
}

const BillingReducer = (state = initialState, action) => {
     
    switch (action.type) {
        
        case VIEW_BILLING_INFO_SUCCESS:{
          const bills = action.payload
          return {
            ...state,
            bills
          }
        }
        case VIEW_BILLING_INFO_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case ADD_BILLING_INFO_SUCCESS:{
          const bills = action.payload
         return {
           ...state,
           bills
         }
       }
       case ADD_BILLING_INFO_FAILED: {
         return {
           ...state,
           error: action.payload
         }
       }
       case UPDATE_BILLING_INFO_SUCCESS:{
        const bill = action.payload.data
       return {
         ...state,
         bills: [bill]
       }
     }
     case UPDATE_BILLING_INFO_FAILED: {
       return {
         ...state,
         error: action.payload
       }
     }
        default:
          return state
      }
  
    }
    
    export default BillingReducer
     
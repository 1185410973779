import React from 'react'
import { Modal } from "reactstrap"

import ActivityQuestionAnsPaper from './ActivityQuestionAnsPaper';

const PreviewModal = ({ visible, selectedLessonItem, onClose, onAssign }) => {

  return (
    <Modal isOpen={visible} toggle={onClose} className='fullscreen' backdrop='static'>
        <div className='preview-review-wrapper preview'>
            <div className='top bg-primary-500 text-white text-center ft-default fw-medium'>You are previewing this activity . Want your student to complete it ?.&nbsp;&nbsp;
            <span className='assign'  onClick={onAssign}>Assign it now</span></div>
            <ActivityQuestionAnsPaper selectedLessonItem={selectedLessonItem} onClose={onClose} type="preview" />
        </div>
    </Modal>
  )
}

export default PreviewModal
import React, { useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, rejected, clean } from 'redux-saga-thunk'

import { showToastMessage } from '../../../helpers/toaster'

import { deleteBadge as onDeleteBadge} from '../../../store/badges/actions';


const BadgeDeleteModal = ({visible, badge, onClose}) => {


    const dispatch = useDispatch()

    const { error, get_delete_success, get_delete_error } = useSelector(state => ({
      error: state.BadgesReducer.error,
      get_delete_success: fulfilled(state, "DELETE_BADGE"),
      get_delete_error: rejected(state, "DELETE_BADGE"),
      
    }))

    const handleDelete = () => {
      let data = {
        id: badge.id
      }
      dispatch(onDeleteBadge(data))
    }

    useEffect(() => {
      if(get_delete_success) {
          dispatch(clean("DELETE_BADGE"))
          showToastMessage('success',"Badge deleted")
          onClose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [get_delete_success])

   useEffect(() => {
    if(get_delete_error) {
        dispatch(clean("DELETE_BADGE"))
        showToastMessage('error',error)
        onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [get_delete_error])


  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
        <div className='signout-confirm-wrapper m-auto'>
            <h4 className='text-primary-800 fw-regular ft-default mb-4'>Delete badges</h4>
            <h5 className='text-gray-600 fw-regular ft-default mb-5'>Are you sure want to Delete this Badges?</h5>
            <div className='button-box d-md-flex'>
                <div className='btn btn-secondary' onClick={onClose}>Cancel</div>
                <div className='btn btn-primary' onClick={handleDelete}>Yes</div>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}

export default BadgeDeleteModal
import { call, put, takeEvery } from "redux-saga/effects"

import { VIEW_ASSIGNMENT_LESSON, VIEW_ASSIGNED_ASSIGNMENT, VIEW_ASSIGNMENT_CHAPTER, UPDATE_ASSIGNMENT_STATUS, CREATE_PRACTICE_TEST, DELETE_COMPLETED_ASSIGNMENT, VIEW_COMPLETED_ASSIGNMENT } from "./actionTypes"
import { viewAssignmentLessonSuccess, 
    viewAssignmentLessonFailed, 
    viewAssignedAssignmentSuccess, 
    viewAssignedAssignmentFailed, 
    viewAssignmentChapterSuccess, 
    viewAssignmentChapterFailed, 
    updateAssignmentStatusSuccess, 
    updateAssignmentStatusFailed,
    createPracticeTestSuccess,
    createPracticeTestFailed,
    deleteCompletedAssignmentSuccess,
    deleteCompletedAssignmentFailed,
    viewCompletedAssignmentSuccess,
    viewCompletedAssignmentFailed
} from "./actions"

import { viewAssignmentLessonApi, viewAssignedAssignmentApi, viewAssignmentChapterApi, updateAssignmentStatusApi, createPracticeTestApi, deleteCompletedAssignmentApi, viewCompletedAssignmentApi } from '../../helpers/api/api'

function* viewAssignmentLesson({ payload:details, meta }) {
    try {
        const response = yield call(viewAssignmentLessonApi, details)
        yield put(viewAssignmentLessonSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewAssignmentLessonFailed(message, meta))
    }
}

function* viewAssignedAssignment({ payload:details, meta }) {
    try {
        const response = yield call(viewAssignedAssignmentApi, details)
        yield put(viewAssignedAssignmentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewAssignedAssignmentFailed(message, meta))
    }
}

function* viewAssignmentChapter({ payload:details, meta }) {
    try {
        const response = yield call(viewAssignmentChapterApi, details)
        yield put(viewAssignmentChapterSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewAssignmentChapterFailed(message, meta))
    }
}

function* updateAssignmentStatus({ payload:details, meta }) {
    try {
        const response = yield call(updateAssignmentStatusApi, details)
        yield put(updateAssignmentStatusSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateAssignmentStatusFailed(message, meta))
    }
}

function* createPracticeTest({ payload:details, meta }) {
    try {
        const response = yield call(createPracticeTestApi, details)
        yield put(createPracticeTestSuccess(response.data, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(createPracticeTestFailed(message, meta))
    }
}

function* deleteCompletedAssignment({ payload:details, meta }) {
    try {
        const response = yield call(deleteCompletedAssignmentApi, details)
        yield put(deleteCompletedAssignmentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(deleteCompletedAssignmentFailed(message, meta))
    }
}

function* viewCompletedAssignment({ payload:details, meta }) {
    try {
        const response = yield call(viewCompletedAssignmentApi, details)
        yield put(viewCompletedAssignmentSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewCompletedAssignmentFailed(message, meta))
    }
}

function* AssignmentSaga() {
    yield takeEvery(VIEW_ASSIGNMENT_LESSON, viewAssignmentLesson)
    yield takeEvery(VIEW_ASSIGNED_ASSIGNMENT, viewAssignedAssignment)
    yield takeEvery(VIEW_ASSIGNMENT_CHAPTER, viewAssignmentChapter)
    yield takeEvery(UPDATE_ASSIGNMENT_STATUS, updateAssignmentStatus)
    yield takeEvery(CREATE_PRACTICE_TEST, createPracticeTest)
    yield takeEvery(DELETE_COMPLETED_ASSIGNMENT, deleteCompletedAssignment)
    yield takeEvery(VIEW_COMPLETED_ASSIGNMENT, viewCompletedAssignment)
}

export default AssignmentSaga
import React, { useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"

import { useLocation  } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';

import { viewStudent as onViewStudent } from '../../store/student/actions';

import checkImg from "../../assets/images/welcome-check-img.png"


const ActivityAssignedSuccessModal = ({ visible, onClose}) => {

    const location = useLocation();
    const dispatch = useDispatch()

    const { student} = useSelector(state => ({
        student: state.StudentReducer.student
    }))

    useEffect(() => {
        
        let currentUrlParams = new URLSearchParams(location.search);
        let student_params = {
            id: currentUrlParams.get('sid')
        }
        dispatch(onViewStudent(student_params))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
       if(visible) {
        setTimeout(() => {
            onClose()
        }, 5000)
       }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody className='rounded-lg shadow bg-primary-100'>
        <div className='assignment-assignment-confirm-wrapper'>
            <div className='desp text-start px-5 py-3 w-75 m-auto text-center'>
                <div className='mb-4'><img className='check-img' src={checkImg} alt="" /></div>
                <p className='text-h4 ft-default m-0 pb-2'>Activity is successfully assigned to</p>
                <p className='text-h4 ft-default fw-semibold'>{student[0].full_name}.</p>
            </div>
        </div>
        </ModalBody>
    </Modal>
  )
}

ActivityAssignedSuccessModal.propTypes = {}

export default ActivityAssignedSuccessModal
import {
    VIEW_LESSON_ACTIVITY_RESULT_SUCCESS,
    VIEW_LESSON_ACTIVITY_RESULT_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    activity: null
}

const LessonActivityReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      
      case VIEW_LESSON_ACTIVITY_RESULT_SUCCESS: {
        return {
          ...state,
          loading: false,
          activity: action.payload,
          error: "",
        }
      }
      case VIEW_LESSON_ACTIVITY_RESULT_FAILED: {
        return {
          ...state,
          loading: false,
          activity: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default LessonActivityReducer;
import { call, put, takeEvery } from "redux-saga/effects"

import { VIEW_SUBJECT } from "./actionTypes"
import { viewSubjectSuccess, viewSubjectFailed } from "./actions"

import { viewSubjectApi } from '../../helpers/api/api'

function* viewSubject({ meta }) {
    try {
        const response = yield call(viewSubjectApi)
        yield put(viewSubjectSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewSubjectFailed(message, meta))
    }
    
}

function* SubjectSaga() {
    yield takeEvery(VIEW_SUBJECT, viewSubject)
}

export default SubjectSaga
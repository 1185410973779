import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, NavLink, Outlet  } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';

import SelectInputOverlap from '../../components/Common/SelectInputOverlap';
import { viewStudent as onViewStudent } from '../../store/student/actions';

const Growth = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const { search } = useLocation();

  const [ availableStudents, setAvailableStudents ] = useState([])
  const [ defaultStudent, setDefaultStudent ] = useState(null)
  const [ defaultStudentId, setDefaultStudentId ] = useState(null)

  const { user, students, get_student } = useSelector(state => ({
    user: state.LoginReducer.user,
    students: state.StudentReducer.student,
    get_student: fulfilled(state, "VIEW_STUDENT")
  }))

  const handleStudent = (id) => {
    if(id !== null)
    navigate(`${location.pathname}?sid=${id}`)
  }
  
  useEffect(() => {
    if(user) {
      let data = { id:user.id }
      dispatch(onViewStudent(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    
    if(get_student) {
      
      if(students.length !== 0) {
        setAvailableStudents(students)

        let currentUrlParams = new URLSearchParams(location.search);
        if(currentUrlParams.get('sid') === null) {
          setDefaultStudent(students[0])
          setDefaultStudentId(students[0].id)
        }
        else {
          let data = students.filter(item => {
            return item.id === parseInt(currentUrlParams.get('sid'))
          })
          if(data.length !== 0) {
            setDefaultStudent(data[0])
            setDefaultStudentId(data[0].id)
          }
          else {
            navigate( { pathname: "/page-not-found" } )
          }
        }
      }
      
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [students])


  useEffect(() => {
    handleStudent(defaultStudentId)
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [defaultStudentId])
  
  return (
    <div className='growth-wrapper'>
      <h5 className='mb-4'>Growth</h5>
      <div className='growth-container'>
        <div className='tabs-box'>
          <SelectInputOverlap
            defaultItem={defaultStudent}
            fieldType="full_name"
            data={availableStudents}  
            placeholder="Select student"
            onChange={handleStudent}
            />
            <NavLink to={`/parent/growth/score${search}`} className='btn tab-btn me-3' activeclassname ="active">Score</NavLink>
            <NavLink to={`/parent/growth/reward${search}`} className='btn tab-btn me-3' activeclassname="active">Reward</NavLink>
            {/*<NavLink to={`/parent/growth/badges${search}`} className='btn tab-btn me-3' activeclassname="active">Badges</NavLink>*/}
        </div>
        <div className='tabs-content'>
            <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Growth
import React, { useState } from "react"

import { toggleSidebarFetch as onToggleSidebar } from '../../../store/layout/actions'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from "../../../assets/images/logo.png"
import searchIcon from "../../../assets/images/search-icon.svg"

import HeaderNotification from './HeaderNotification'
import ProfileMenu from './ProfileMenu'
import { sessionData } from "../../../constants/sessiondata";

import SignoutConfirmModal from "./SignoutConfirmModal";

import _ from 'underscore'

const Header = ({ isSetup, isToggleAvailable }) => {

  const dispatch = useDispatch()
  const isNotAuth = _.isNull(sessionData.getAuthUser() && sessionData.getParentId() && sessionData.getEmailVerified())

  const [ isConfirmAvailable, setConfirmAvailable ] = useState(false)

  const { isSideBarExpanded  } = useSelector(state => ({
    isSideBarExpanded: state.LayoutReducer.isSideBarExpanded
  }))

  const toggleSideBar = (data) => {
    dispatch(onToggleSidebar(!data))
  }

  const handleSubmit = () => {

  }

  return (

    <div className='header bg-primary-300 d-flex'>
      
      <div className='logo-wrapper d-flex align-items-center h-100'>
        <div className='logo h-100 d-flex justify-content-center align-items-center'><img className='img-fluid' src={logo} alt="" /></div>
        { isToggleAvailable &&  
          <div className='hamburger d-lg-none ms-4' onClick={() => {toggleSideBar(isSideBarExpanded)}}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        }
      </div>

      {
        isNotAuth ? (
          <div className="ms-auto signin-box">
              <Link className="btn btn-gray rounded-lg ft-default" to="sign-in">Sign in</Link>
          </div>
        ) : (
          <>
            { !isSetup ? ( 
              <div className="other-box d-flex">
                <div className="search-bar">
                  <form onSubmit={handleSubmit}>
                    <div className="input-box mb-0 position-relative d-none d-lg-block">
                      <img src={searchIcon} alt="" />
                      <input className="form-control" type="text" name="search"  placeholder="Search for anything ( Class, Subjects, Exams etc)" />
                    </div>
                  </form>
                </div>
                <div className="d-flex ms-auto">
                  <div className="search-icon-md d-block d-lg-none">
                    <img src={searchIcon} alt="" />
                  </div>
                  <HeaderNotification />
                  <ProfileMenu/>
                </div>
              </div>

             ) : (
              <div className="ms-auto signin-box">
                <div className="btn btn-gray rounded-lg ft-default" onClick={() => {setConfirmAvailable(!isConfirmAvailable)}}>Sign out</div>
              </div>
            ) 
            }
          </>
        )
      }
      <SignoutConfirmModal visible={isConfirmAvailable} 
            onClose = { () => {setConfirmAvailable(!isConfirmAvailable)} }/>
    </div>
  )
}

export default Header
import React from 'react'
import { Modal, ModalBody } from "reactstrap"

import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { logoutUser as onLogoutUser } from "../../../store/auth/login/actions";

const SignoutConfirmModal = ({ visible, onClose }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSignOut = () => {
        dispatch(onLogoutUser(navigate, 'parent'))
    }

  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
            <div className='signout-confirm-wrapper m-auto'>
                <h4 className='text-primary-800 fw-regular ft-default mb-4'>Sign Out?</h4>
                <h5 className='text-gray-600 fw-regular ft-default mb-5'>Are you sure you want to sign out.</h5>
                <div className='button-box d-md-flex'>
                    <div className='btn btn-primary' onClick={handleSignOut}>Yes</div>
                    <div className='btn btn-secondary' onClick={onClose}>Cancel</div>
                </div>
            </div>
        </ModalBody>
    </Modal>
  )
}

export default SignoutConfirmModal
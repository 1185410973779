
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk';

import { useFormik } from 'formik';
import { Alert } from "reactstrap"
import { showToastMessage } from '../../helpers/toaster';

import { applyCoupon as onApplyCoupon } from '../../store/plans-and-prices/actions';
import { viewSubscriptions as onViewSubscriptions } from '../../store/plans-and-prices/actions';

import _ from 'underscore'

const PaymentSummary = ({isAddition}) => {


    const dispatch = useDispatch()

    const initialValues = {
        coupon_code: ""
    }

    const { subscriptions, coupon, error, get_subscription_success, get_coupon_success } = useSelector(state => ({
        subscriptions: state.PlansAndPricesReducer.subscriptions,
        coupon: state.PlansAndPricesReducer.coupon,
        error: state.PlansAndPricesReducer.error,
        get_subscription_success: fulfilled(state, "VIEW_MY_SUBSCRIPTIONS"),
        get_coupon_success: fulfilled(state, "APPLY_COUPON")
    }))
    
    const [ subscriptionDetails, setSubscriptionDetails ] = useState(null)
    const [ isCouponApplied, setCouponApplied ] = useState(false)

    const onSubmit = (values) => {
        let data = {
            coupon_code: formik.values.coupon_code
        }
        dispatch(onApplyCoupon(data))
    }

    const validate = (values) => {

        let errors = {}
        return errors
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    useEffect(() => {
        dispatch(onViewSubscriptions())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        dispatch(clean("APPLY_COUPON"))
        if(get_coupon_success) {
            if(coupon?.code === 201) {
                dispatch(onViewSubscriptions())
                setCouponApplied(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_coupon_success])

    useEffect(() => {
        if(get_subscription_success) {
            setSubscriptionDetails(subscriptions)
            if(isCouponApplied) {
                showToastMessage('success',"Coupon Applied")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_subscription_success])

  return (
    <div className='payment-summary-wrapper'>
        { get_subscription_success && !_.isEmpty(subscriptionDetails) && 
            <form onSubmit={formik.handleSubmit}>
                <div className='mt-3'>
                    { error && <Alert color="danger">{error}</Alert> }
                    { coupon?.code === 200 && <Alert color="danger">{coupon.status}</Alert> }
                </div>
                <div className='d-flex'>
                    <h6 className='ft-default fw-regular text-h6 text-gray-950'>Package</h6>
                    <h6 className='ms-auto fw-regular'>{subscriptionDetails?.plan_id === "3" ? "Pro" : "Free"}</h6>
                </div>
                <div className='coupon-box'>
                    <div className='input-box mt-4 mb-0'>
                        <input type="text" className='form-control border border-text-gray-180 coupon-input' placeholder='Enter coupon code' name="coupon_code" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coupon_code} />
                    </div>
                    <button type='submit' className='btn coupon-btn fw-regular text-h6 text-gray-950'>Apply coupon</button>
                </div>
                <div className='sub-total border-top border-text-gray-700 py-4 mt-3'>
                    <div className='d-flex mb-4'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Sub Total</h6>
                        { !isAddition && <h6 className='ms-auto fw-regular'>{(subscriptionDetails?.data.latest_invoice.subtotal/100).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.data.latest_invoice.currency}</span></h6> }
                        { isAddition && <h6 className='ms-auto fw-regular'>{(subscriptionDetails?.invoices.subtotal/100).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.invoices.currency}</span></h6> }
                    </div>
                    <div className='d-flex'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Coupon discounts</h6>
                        { !isAddition && <h6 className='ms-auto fw-regular'>{((subscriptionDetails?.data.latest_invoice.subtotal/100) - (subscriptionDetails?.data.latest_invoice.total/100)).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.data.latest_invoice.currency}</span></h6> }
                        { isAddition && <h6 className='ms-auto fw-regular'>{((subscriptionDetails?.invoices.subtotal/100) - (subscriptionDetails?.invoices.total/100)).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.invoices.currency}</span></h6> }
                    </div>
                </div>
                <div className='sub-total border-top border-text-gray-700 py-4 mt-2'>
                    <div className='d-flex'>
                        <h6 className='ft-default fw-regular text-h6 text-gray-950'>Total</h6>
                        { !isAddition && <h6 className='ms-auto fw-regular'>{(subscriptionDetails?.data.latest_invoice.total/100).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.data.latest_invoice.currency}</span></h6> }
                        { isAddition && <h6 className='ms-auto fw-regular'>{(subscriptionDetails?.invoices.total/100).toFixed(2)} <span className='text-uppercase text-base'>{subscriptionDetails?.invoices.currency}</span></h6> }
                    </div>
                </div>
            </form>
        }
    </div>
  )
}

export default PaymentSummary

// Authentication
export const PARENT_LOGIN = "/parentauth/login"

// Signup
export const PARENT_SIGNUP = "/parentauth/signup"

// Otp
export const VERIFY_OTP = "/parentauth/verifyOtp"
export const RESEND_OTP = "/parentauth/requestOtp"

//Reset
export const REQUEST_PASSWORD_EMAIL = "/auth/recovery"
export const RESET_PASSWORD_EMAIL = "/auth/reset"

//Contact
export const CONTACT_ADMIN = "/contact"

//View user
export const VIEW_USER_PROFILE = "/parentprofile/viewProfile"
export const UPDATE_USER_PROFILE = "/parentprofile/updateProfile"

//Notification
export const VIEW_NOTIFICATIONS = "/viewNotificationParent"
export const UPDATE_NOTIFICATION = "/readNotification"
export const VIEW_NOTIFICATION_SETTING = "/viewParentNotificationSetting"
export const UPDATE_NOTIFICATION_SETTING = "/updateParentNotificationSetting"

//Plan
export const BUY_PLAN = "/buyPlan"
export const VIEW_ALL_PLANS = "/auth/viewPlans"
export const VIEW_PLANS = "/viewMyPlans"
export const VIEW_MY_SUBSCRIPTIONS = "/viewMySubscriptionPlans"
export const UPDATE_RENEWAL = "/renewalOnOff"
export const CHANGE_SUBSCRIPTION = "/changePlan"
export const MAKE_PAYMENT = "/makePayment"
export const APPLY_COUPON = "/applyCoupon"
export const UPDATE_SUBSCRIPTION = "/updateSubscription"
export const ADD_PAYMENT_METHOD = "/addCard"
export const UPDATE_PAYMENT_METHOD = "/updateDefaultPaymentMethod"
export const DELETE_CARD_DETAILS = "/deleteCard"
export const VIEW_CARD_DETAILS = "/getCard"

// Settings
export const GET_GRADES = "/viewStudentYear"
export const GET_KEY_STAGES = "/viewStudentKeyStage"
export const GET_EXAM_BOARDS = "/viewStudentExamBoard"

//Student
export const ADD_STUDENT = "/createStudentByParent"
export const VIEW_STUDENT = "/viewStudentByParent"
export const UPDATE_STUDENT = "/updateStudentByParent"
export const DELETE_STUDENT = "/deleteStudentByParent"

//Subject
export const VIEW_SUBJECT = "/viewParentSubject"

//REWARD
export const VIEW_REWARD = "/viewReward"
export const VIEW_REWARD_CATEGORY = "/viewParentRewardCategory"
export const CREATE_REWARD = "/createReward"
export const UPDATE_REWARD = "/updateReward"
export const DELETE_REWARD = "/deleteReward"

//BADGES
export const VIEW_BADGES = "/viewBadge"
export const VIEW_BADGES_TYPE = "/viewParentBadgeType"
export const CREATE_BADGE = "/createBadge"
export const UPDATE_BADGE = "/updateBadge"
export const DELETE_BADGE = "/deleteBadge"

//REWARD TYPE
export const VIEW_REWARD_TYPE = "/viewParentRewardType"

//LESSON SCORE
export const VIEW_EXAM_TYPE = "/viewAllExamType"
export const VIEW_CHAPTERS = "/viewAllParentChapter"
export const VIEW_LESSON_SCORE = "/ViewStudentLessonScore"

//Card
export const ADD_CARD = "/createCard"
//export const VIEW_CARD = "/viewCard"
export const VIEW_CARD = "/getCard"
export const UPDATE_CARD = "/updateCard"
export const DELETE_CARD = "/deleteCard"

//Billing
export const ADD_BILLING_INFO = "/createBilling"
export const VIEW_BILLING_INFO = "/viewBilling"
export const UPDATE_BILLING_INFO = "/updateBilling"

//Faq
export const VIEW_FAQ = "/viewFaqParent"

//Assignment
export const VIEW_ASSIGNMENT_LESSON = "/viewAssginmentLesson"
export const VIEW_ASSIGNED_ASSIGNMENT = "/assignedAssignment"
export const VIEW_ASSIGNMENT_CHAPTER = "/viewAssginmentChapter"
export const UPDATE_ASSIGNMENT_STATUS = "/changeStatus"
export const CREATE_PRACTICE_TEST = "/practiceTest"
export const DELETE_COMPLETED_ASSIGNMENT = "/deleteAssignment"
export const VIEW_COMPLETED_ASSIGNMENT = "/completedAssignment"

//LESSON ACTIVITY
export const VIEW_LESSON_ACTIVITY_RESULT = "/viewLessonActivityResult"

//Logout
export const PARENT_LOGOUT = "/parentauth/logout"

//Notice
export const VIEW_NOTICE = "/viewParentNotice"
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from "redux-saga-thunk"
import _ from 'underscore'

import { viewNotificationSetting as onViewNotificationSetting, updateNotificationSetting as onUpdateNotificationSetting } from '../../store/notifications/actions'
import { showToastMessage } from '../../helpers/toaster'

const NotificationSetting = props => {

    const dispatch = useDispatch()
    const [ paymentNotfications, setPaymentNotifications ] = useState([])
    const [ admintNotfications, setAdminNotifications ] = useState([])

    const { user, notification_settings, get_success, get_update_success  } = useSelector(state => ({
        user: state.LoginReducer.user,
        notification_settings: state.NotificationsReducer.notification_settings,
        get_success: fulfilled(state, "VIEW_NOTIFICATION_SETTING"),
        get_update_success: fulfilled(state, "UPDATE_NOTIFICATION_SETTING")
    }))

    const getName = (data) => {
        if(data) {
            let input = data[0].split("_")
            let final = input[1]+ " " + input[2]
            return final
        }
        else {
            return " "
        }
    }

    const handleChange = (event, item) => {
        let status = event.target.checked
        let data = {}
        data["user_id"] = user.id
        data[Object.keys(item)] = status.toString()
        dispatch(onUpdateNotificationSetting(data))
    }
    
    useEffect(() => {
        if(!_.isEmpty(user)) {
            let data = {
                user_id: user.id
            }
            dispatch(onViewNotificationSetting(data))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if(get_success || get_update_success) {
            
            dispatch(clean("VIEW_NOTIFICATION_SETTING"))
            dispatch(clean("UPDATE_NOTIFICATION_SETTING"))
            
            if(get_update_success) {
                showToastMessage('success',"Notification setting updated")
            }

            let paymentNotifyArray = []
            Object.keys(notification_settings).forEach((key) => {
                if(key.startsWith("payment_")) {
                    let data = {}
                    data[key] = notification_settings[key]
                    paymentNotifyArray.push(data)
                }
            });
            setPaymentNotifications(paymentNotifyArray)

            let adminNotifyArray = []
            Object.keys(notification_settings).forEach((key) => {
                if(key.startsWith("admin_")) {
                    let data = {}
                    data[key] = notification_settings[key]
                    adminNotifyArray.push(data)
                }
            });
            setAdminNotifications(adminNotifyArray)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_success, get_update_success])

  return (
    <div className='card h-100 gray-card shadow setting-card'>
        
        <div className='card-header'><h4 className='ft-default fw-regular'>Notification Setting</h4></div>
            
            <div className='card-body p-4'>
                
                { paymentNotfications.length !== 0 && <div className='notify-list mb-5'>
                    <h6 className='text-gray-920 mt-2 text-base ft-default'>Payment Notifications</h6>
                    { paymentNotfications.map((item, index) => {
                        return(
                            <div key={index} className='switch-container my-4 d-flex align-items-center'>
                                <input className="form-check-input" type="checkbox" checked={item[Object.keys(item)] === "true" ? true : false}  id={Object.keys(item)} onChange={(event) => {handleChange(event, item)}} />
                                <label className="form-check-label ft-head fw-medium" htmlFor={Object.keys(item)}>
                                <div className="switch-box">
                                    <span className="slider"></span>
                                </div>
                                <span className='text-capitalize1'>{getName(Object.keys(item))}</span>
                                </label>
                            </div>
                        )
                    }) }
                </div> }

                { admintNotfications.length !== 0 && <div className='notify-list mb-5'>
                    <h6 className='text-gray-920 mt-2 text-base ft-default'>Admin Notifications</h6>
                    { admintNotfications.map((item, index) => {
                        return(
                            <div key={index} className='switch-container my-4 d-flex align-items-center'>
                                <input className="form-check-input" type="checkbox" checked={item[Object.keys(item)] === "true" ? true : false} id={Object.keys(item)} onChange={(event) => {handleChange(event, item)}} />
                                <label className="form-check-label ft-head fw-medium" htmlFor={Object.keys(item)}>
                                <div className="switch-box">
                                    <span className="slider"></span>
                                </div>
                                <span className='text-capitalize'>{getName(Object.keys(item))}</span>
                                </label>
                            </div>
                        )
                    }) }
                </div> }
        </div>
    </div>
  )
}


export default NotificationSetting
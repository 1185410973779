import { takeEvery, put, call } from "redux-saga/effects"

import { VERIFY_OTP, RESEND_OTP } from './actionTypes'
import { verifyOtpSuccess, verifyOtpFailed } from './actions'

import { verifyOtpApi, resendOtpApi } from "../../../helpers/api/api"

function* verifyOtp({ payload: {data} , meta }) {
    try {
        const response = yield call(verifyOtpApi,data)
        yield put(verifyOtpSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data.status
        yield put(verifyOtpFailed(message, meta))
    }
}

function* resendOtp({ payload: {data} , meta }) {
    try {
        const response = yield call(resendOtpApi,data)
        yield put(verifyOtpSuccess(response, meta))
    }
    catch(error) {
        const message=error.response.data.message
        yield put(verifyOtpFailed(message, meta))
    }
}

function* OtpSaga() {
    yield takeEvery(VERIFY_OTP, verifyOtp)
    yield takeEvery(RESEND_OTP, resendOtp)
}

export default OtpSaga
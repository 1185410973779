import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk';

import DatePicker from "react-datepicker";
import moment from 'moment';

import { createPracticeTest as onCreatePracticeTest } from '../../store/assignment/actions';
import PreviewModal from './Preview/PreviewModal';
import ActivityAssignedSuccessModal from './ActivityAssignedSuccessModal';

const ActivityUnAssigned = ({ assignment, student_id, subject_id, chapter_id, exam_board_id, onRefresh }) => {

  const dispatch = useDispatch()

  const [ isPreviewModalVisible, setPreviewModalVisible ] = useState(false)

  const { practice, get_practice_success } = useSelector(state => ({
    practice: state.AssignmentReducer.practice,
    get_practice_success: fulfilled(state, "CREATE_PRACTICE_TEST")
  }))

  const [ availableAssignment, setAvailableAssignment ] = useState([])
  const [ selectedLessonItem, setSelectedLessonItem ] = useState(null)

  const [ isActivityAssignedModalVisible, setActivityAssignedModalVisible ] = useState(false)

  const handleDate = (data, date) => {
    setTimeout(() => {
        let newData = availableAssignment.map((item, index) => {
            if(item.id === data.id) {
                item.toDoDate = date
                item.to_do_date = moment(date).format('DD/MM/YYYY')
            }
            return item
        })
        setAvailableAssignment(newData)
    }, 100)
  }

  const createPracticeTest = (data) => {

    let params = {
        user_id: student_id,
        subject_id: subject_id,
        chapter_id: chapter_id,
        lesson_activity_id: data.id,
        exam_board_id: exam_board_id,
        to_do_date: data.to_do_date
    }
    dispatch(onCreatePracticeTest(params))
  }

  const showPreviewModal = (item) => {
    setSelectedLessonItem(item)
    setPreviewModalVisible(true)
  }

  const closePreviewModal = () => {
    setSelectedLessonItem(null)
    setPreviewModalVisible(!isPreviewModalVisible)
  }

  const createPracticeTestViaModal = () => {
    createPracticeTest(selectedLessonItem)
  }

  const closeActivityAssignedModal = () => {
    setActivityAssignedModalVisible(!isActivityAssignedModalVisible)
    onRefresh()
    setTimeout(() => {
        document.body.style.overflow = 'visible'
    }, 1000)
  }

  useEffect(() => {
    setAvailableAssignment(assignment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment])

  useEffect(() => {
    if(get_practice_success) {
        
        let newData = availableAssignment.map((item, index) => {
            if(item.id === parseInt(practice.lesson_activity_id)) {
                item.status = practice.status
            }
            return item
        })
        
        setAvailableAssignment(newData)
        dispatch(clean("CREATE_PRACTICE_TEST"))

        setActivityAssignedModalVisible(!isActivityAssignedModalVisible)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_practice_success])

  return (
    <div className='activity-unassigned'>
        { availableAssignment.length !== 0 ?  
            <table className='table table-responsive mb-0'>
                <thead>
                    <tr>
                    <th>Lesson activity</th>
                    <th>Questions</th>
                    <th>To do date</th>
                    <th>Preview question</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    { assignment.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.number}. {item.name}</td>
                                <td>{item.questions_count}</td>
                                <td>
                                    <div className='input-box'>
                                        <div className="input-group">
                                            <form>
                                                <DatePicker 
                                                    className={`form-control simple pointer ${chapter_id === null ? "disabled" : ""}`}
                                                    placeholderText={'DD/MM/YYYY'} 
                                                    id="example-datepicker" 
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    minDate={moment().toDate()}
                                                    dropdownMode="select" 
                                                    selected={item.toDoDate}
                                                    onChange={(date) => handleDate(item, date)} 
                                                    autoComplete="off" 
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </td>
                                <td className='preview'>
                                    <span className={`pointer d-inline-block mt-1 ${!item.to_do_date ? 'disabled' : ''}`} onClick={() => {showPreviewModal(item)}}>Preview</span>
                                </td>
                                <td>
                                    <span className={`assign-btn d-line-block ${chapter_id === null ? 'disabled' : ''} ${item.status === "unassigned" ? "" : "darker"} ${!item.to_do_date ? 'disabled' : ''} `} onClick={() => {createPracticeTest(item)}} >{item.status === "unassigned" ? "Assign" : "Assigned"}</span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            :
            <div className='p-4 text-center ft-default text-base'>No data available</div>
        }
        <PreviewModal visible={isPreviewModalVisible}
            selectedLessonItem={selectedLessonItem}
            onAssign={createPracticeTestViaModal}
            onClose = {closePreviewModal}/>

        <ActivityAssignedSuccessModal
            visible={isActivityAssignedModalVisible}
            onClose = {closeActivityAssignedModal}/>
    </div>
  )
}

export default ActivityUnAssigned
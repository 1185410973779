export const ADD_STUDENT = "ADD_STUDENT"
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS"
export const ADD_STUDENT_FAILED = "ADD_STUDENT_FAILED"

export const VIEW_STUDENT = "VIEW_STUDENT"
export const VIEW_STUDENT_SUCCESS = "VIEW_STUDENT_SUCCESS"
export const VIEW_STUDENT_FAILED = "VIEW_STUDENT_FAILED"

export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAILED = "UPDATE_STUDENT_FAILED"

export const DELETE_STUDENT = "DELETE_STUDENT"
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS"
export const DELETE_STUDENT_FAILED = "DELETE_STUDENT_FAILED"
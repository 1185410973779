import {
    VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILED
} from './actionTypes';

const INIT_STATE = {
    error: "",
    loading: false,
    otp: null,
}

const OtpReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case VERIFY_OTP: {
            return {
              ...state,
              loading: true,
              error: "",
            }
          }
          case VERIFY_OTP_SUCCESS: {
            return {
              ...state,
              loading: false,
              otp: action.payload,
              error: "",
            }
          }
          case VERIFY_OTP_FAILED: {
            return {
              ...state,
              loading: false,
              otp: null,
              error: action.payload,
            }
          }
          default:
            return state
    }

}

export default OtpReducer;
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pending ,fulfilled } from 'redux-saga-thunk';
import _ from 'underscore'

import { viewStudent as onViewStudent } from '../../store/student/actions';
import { viewPlans as onViewPlans } from '../../store/plans-and-prices/actions';
import Loader from '../../components/Common/Loader';

const MyStudent = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [ planDetails, setPlanDetails ] = useState({})

    const { student, get_student, get_student_success, myplan, get_plan, get_plan_success } = useSelector(state => ({
        student: state.StudentReducer.student,
        myplan: state.PlansAndPricesReducer.myplan,
        get_student: pending(state, "VIEW_STUDENT"),
        get_student_success: fulfilled(state, "VIEW_STUDENT"),
        get_plan: pending(state, "VIEW_PLANS"),
        get_plan_success: fulfilled(state, "VIEW_PLANS")
    }))

    const gotoStudent = () => {
        navigate( { pathname: "/parent/student-profile" } )
    }

    useEffect(() => {
        dispatch(onViewStudent())
        dispatch(onViewPlans())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(get_plan_success) {
            if(myplan.length !== 0 ) {
                setPlanDetails(myplan[0])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_plan_success])

  return (
    <div className='dashboard-my-student-wrapper mt-5'>
        { get_student && get_plan && 
            <Loader />
        }
        { get_student_success && get_plan_success && 
            <>
                <h5 className='mb-3 fw-medium'>My Students</h5>
                <div className='row'>
                    { get_student_success && get_plan_success && student.map((item, index) => (
                        <div key={index} className='col-md-4'>
                            <div className='student-box h-100'>
                                <div className='card shadow mb-4 content'>
                                    <div className='card-body h-100 details'>
                                        <div className='d-flex mb-5'>
                                            <div className='img-wrapper me-3'>
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div>
                                                <p className='text-sm mb-2 fw-semibold'>{item.full_name}</p>
                                                <p className='text-xs mb-1'>{item.student_detail?.school_name}</p>
                                                <p className='text-xs'>{item.student_detail?.key_stage?.name}</p>
                                            </div>
                                        </div>
                                        <h6 className='text-orange-400 fw-semibold plan'>{planDetails?.plan_id === "3" ? "Pro" : "Free"} Plan</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    { get_student_success && _.isEmpty(student) &&
                        <div className='col-md-8'>
                            <h6 className='ft-default fw-regular pt-2 text-gray-400 pointer' onClick={gotoStudent}>No student added, to add student go to student profile</h6>
                        </div>
                    }
                </div>
            </>
        }        
    </div> 
  )
}

export default MyStudent
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED"

export const VIEW_USER_PROFILE = "VIEW_USER_PROFILE"
export const VIEW_USER_PROFILE_SUCCESS = "VIEW_USER_PROFILE_SUCCESS"
export const VIEW_USER_PROFILE_FAILED = "VIEW_USER_PROFILE_FAILED"

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS"
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED"
import React, { useEffect } from 'react'

import RegisterAccount from './RegisterAccount'
import AccountStepper from '../AccountStepper/AccountStepper'

const SignUp = props => {

  const query = window.location.search

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
        { query &&  
            <div className="signup-wrapper d-inline-block w-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <AccountStepper  />
                        <RegisterAccount />
                    </div>
                </div>
            </div>
        </div>
        }
    </>
  )
}


export default SignUp
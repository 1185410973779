import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk'
import _ from 'underscore'

import PaymentCard from '../../../components/Common/PaymentCard'
import DeleteCardComfirmModal from './DeleteCardComfirmModal'
import AddNewBilling from './AddNewBilling'
import AddNewCard from './AddNewCard'
import { showToastMessage } from '../../../helpers/toaster';

import { viewCardDetails as onViewCardDetails, deleteCardDetails as onDeleteCardDetails } from '../../../store/plans-and-prices/actions'
import { viewBilling as onViewBilling } from '../../../store/billing/actions'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


library.add(fas, fab);

const BillingSettings = (props) => {

  const dispatch = useDispatch();

  const [ isDeletelModalVisible, setDeletelModalVisible ] = useState(false)
  const [ selectedDeleteId, setSelectedDeleteId ] = useState(null);
  const  [ cardDetails, setCardDetails ] = useState([])

  const [ isNewBillingCardBox, setNewBillingCardBox ] = useState(false)
  const [ isNewBilling, setNewBilling ] = useState(false)

  const { user, cards, get_user_success, get_cards_success, delete_card_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    cards: state.PlansAndPricesReducer.cards,
    get_user_success: fulfilled(state, "VIEW_USER_PROFILE"),
    get_cards_success: fulfilled(state, "VIEW_CARD_DETAILS"),
    delete_card_success: fulfilled(state, "DELETE_CARD_DETAILS")
  }))

  const handleDeleteCardConfirm = (id) => {
    setDeletelModalVisible(true)
    setSelectedDeleteId(id)
  }
  
  const handleDeleteCard = () => {
    setDeletelModalVisible(false)
    let data = {
      payment_method_id: selectedDeleteId
    }
    dispatch(onDeleteCardDetails(data))
  }

  const showBillingInfo = () => {
    setNewBillingCardBox(true)
    setNewBilling(true)
  }

  const onBillingClose = () => {
    setNewBillingCardBox(false)
    setNewBilling(false)
  }

  const onCardClose = () => {
    setNewBilling(true)
  }

  const handleAddNewCard = () => {
    setNewBilling(false)
  }

  const handleDataSubmit = () => {
    setNewBillingCardBox(false)
    dispatch(onViewCardDetails())
  }

  const refreshCardsAndBilling = () => {
    let data = {
      user_id: user.id
    }
    dispatch(onViewCardDetails())
    dispatch(onViewBilling(data))
  }

  const handleUpdatePayment = () => {
    dispatch(onViewCardDetails())
  }

  useEffect(() => {
    if(get_user_success) {
      refreshCardsAndBilling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_user_success])


  useEffect(() => {
    if(get_cards_success) {
      setCardDetails(cards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_cards_success])

  useEffect(() => {
    if(delete_card_success) {
      dispatch(clean("DELETE_CAR_DETAILS"))
      showToastMessage('success',"Card deleted")
      dispatch(onViewCardDetails())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delete_card_success])

  return (
    <div className='billing-settings-wrapper'>
      
      <div className='row'>
        
        <div className='col-sm-12'>
          { !isNewBillingCardBox ? (
            <div className='card gray-card'>
              <div className='card-header'><h4 className='ft-default fw-regular'>Card details</h4></div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                          {
                            !_.isEmpty(cardDetails) && cardDetails.map((item, index) => {
                              return (
                                <PaymentCard key={index} index={index} card={item}  length={cardDetails.length} onDelete={handleDeleteCardConfirm} onUpdatePayment={handleUpdatePayment} />
                              )
                            })
                          }
                          <div className='my-4 billing-info d-flex align-items-center pointer'>
                            <p className='ms-2 text-primary-950 text-base' onClick={showBillingInfo}><span className='me-1'><FontAwesomeIcon icon="fas fa-plus-circle" /></span> Add new billing info</p>
                          </div>
                    </div>
                  </div>
                </div>
            </div>
          ) : (
            <div className='card gray-card'>
              <div className='card-header'><h4 className='ft-default fw-regular'>{ isNewBilling ? "Add billing address" : "Add card details" }</h4></div>
              <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-8'>
                      { isNewBilling ? <AddNewBilling onNewCard={handleAddNewCard} onClose={onBillingClose} /> : <AddNewCard onClose={onCardClose} onDataSubmit={handleDataSubmit} /> }
                    </div>
                  </div>
              </div>
            </div>
          ) }
                
        </div>
    </div>
    <DeleteCardComfirmModal visible={isDeletelModalVisible} 
            onClose = {() => {setDeletelModalVisible(!isDeletelModalVisible)}}
            onDelete = {handleDeleteCard}
            />
  </div>
  )
}

export default BillingSettings
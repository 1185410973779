import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {  useLocation } from 'react-router-dom'
import { fulfilled } from 'redux-saga-thunk'

import { viewCard as onViewCard } from '../../../../store/card/actions'

import AccountStepper from '../AccountStepper/AccountStepper'
import AddCardDetails from '../BillingInfo/AddCardDetails'
import PaymentSummary from '../../../../components/Common/PaymentSummary'
import PaymentCard from '../../../../components/Common/PaymentCard'


const Payment = props => {

    const location = useLocation()
    const dispatch = useDispatch();

    const [ isAddition, setAddition ] = useState(false)
    const  [ cardDetails, setCardDetails ] = useState([])

    const { cards, get_cards_success } = useSelector(state => ({
        cards: state.CardReducer.cards,
        get_cards_success: fulfilled(state, "VIEW_CARD")
    }))

    useEffect(() => {
        dispatch(onViewCard())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(get_cards_success) {
            setCardDetails(cards)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_cards_success])

    useEffect(() => {
        let currentUrlParams = new URLSearchParams(location.search);
        if(currentUrlParams.get('add') === 'true') {
            setAddition(true)
        }
        else {
            setAddition(false)
        }
    }, [location])

  return (
    <div className='payment-info-wrapper'>
        <AccountStepper />
        { !isAddition ? (
            <div className='row'>
                <div className='col-md-7'>
                    <div className='card gray-card h-100'>
                        <div className='card-header'><h4 className='ft-default fw-regular'>Select payment method</h4></div>
                        <div className='card-body p-4'>
                            <AddCardDetails />
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                <div className='card gray-card summary-card h-100'>
                        <div className='card-header'><h4 className='ft-default fw-regular'>Summary</h4></div>
                        <div className='card-body p-4'>
                            <PaymentSummary />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card gray-card h-100'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-md-8 offset-md-2'>
                                <h4 className='ft-default fw-regular'>Add Billing Information</h4>
                            </div>
                        </div>
                    </div>
                    <div className='card-body p-4'>
                        <div className='row'>
                            <div className='col-md-8 offset-md-2'>
                                { get_cards_success && 
                                    <>
                                        { cardDetails.map((item, index) => {
                                            return (
                                                <PaymentCard key={index} card={item} setup={isAddition} length={cardDetails.length} />
                                            )
                                        })}
                                        { /*<div className='my-4 billing-info d-flex align-items-center pointer'>
                                            <p className='ms-2 text-primary-950 text-base' onClick={showBillingInfo}><span className='me-1'><FontAwesomeIcon icon="fas fa-plus-circle" /></span> Add new billing info</p>
                                        </div> */ }
                                    </>
                                }
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        ) }
    </div>
  )
}

export default Payment
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_GRADES } from './actionTypes'

import {
    getGradesSuccess,
    getGradesFailed
} from './actions'

import { getGradesApi } from "../../../helpers/api/api"

function* onGetGrades({ meta }) {
    try {
      const response = yield call(getGradesApi)
      yield put(getGradesSuccess(response, meta))
    } catch (error) {
      yield put(getGradesFailed(error, meta))
    }
  }

function* GradesSaga() {
    yield takeEvery(GET_GRADES, onGetGrades)
}

export default GradesSaga
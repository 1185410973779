import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, rejected, clean } from 'redux-saga-thunk'

import { useFormik } from 'formik'
import { Alert } from "reactstrap"

import ProfilePicUpload from '../../components/Common/ProfilePicUpload'

import { updateProfile as onUpdateProfile } from '../../store/auth/login/actions'
import { viewProfile as onViewProfile } from "../../store/auth/login/actions";

import UpdatePasswordModal from './UpdatePasswordModal'
import { showToastMessage } from '../../helpers/toaster';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const MyProfile = props => {


  const dispatch = useDispatch()
  const [ isEdit, setEdit ] = useState(false)
  const [ isUpdatePasswordVisible, setUpdatePasswordVisible ] = useState(false)
  const [ isProfileImageUpdated, setProfileImageUpdated ] = useState(false)

  const { user, error, get_success, get_error } = useSelector(state => ({
    user: state.LoginReducer.user,
    error: state.LoginReducer.error,
    get_success: fulfilled(state, "UPDATE_USER_PROFILE"),
    get_error: rejected(state, "UPDATE_USER_PROFILE")
  }))

  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    image: "",
    city: ""
  }

  const onSubmit = values => {
    let data = {
        id: user.id,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_new_password: values.confirm_new_password,
        email: values.email,
        city: values.city,
        country: "India"

    }

    if(isProfileImageUpdated) {
        data.image = values.image
    }
    
    if(values.old_password !== "") {
        data.old_password = values.old_password
    }

    if(values.new_password !== "") {
        data.new_password = values.new_password
    }

    if(values.confirm_new_password !== "") {
        data.confirm_new_password = values.confirm_new_password
    }

    dispatch(onUpdateProfile(data))
  }

  const validate = values => {

    let errors = {}

    if(!values.first_name) {
        errors.first_name = "First Name is required!"
    }

    if(!values.phone) {
        errors.phone = "Phone Number is required!"
    }

    if(!values.email) {
        errors.email = "Email is required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
        errors.email = "Enter a valid email address"
    }

    return errors

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })


  const handleImage = async (image) => {
    formik.setFieldValue("image", image)
    setProfileImageUpdated(true)
  }

  const handlePasswordModal = () => {
    setUpdatePasswordVisible(!isUpdatePasswordVisible)
    setEdit(true)
  }

  const onPasswordSubmit = (data) => {
    formik.setFieldValue("old_password", data.old_password)
    formik.setFieldValue("new_password", data.new_password)
    formik.setFieldValue("confirm_new_password", data.confirm_new_password)
  }

  const onImageRemove = () => {
    formik.setFieldValue("image", "")
    setProfileImageUpdated(true)
  }

  useEffect(() => {
    if(user) {
        for (let key in formik.values) {
           if(Object.keys(user).includes(key)) {
            formik.setFieldValue(key, user[key])
           }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(get_success) {
        
        dispatch(clean("UPDATE_USER_PROFILE"))
        setEdit(false)
        showToastMessage('success',"User Updated")

        let data = {
            id: user.id
        }
        dispatch(onViewProfile(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    if(get_error) {
        dispatch(clean("UPDATE_USER_PROFILE"))
        showToastMessage('error',error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_error])

  return (
    <div className='my-profile-wrapper'>
        <h5 className='title'>My Profile</h5>
        <div className='my-profile-box mt-4'>
            <div className='card gray-card'>
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h4 className='ft-default fw-regular'>{ isEdit ? "Edit Details" : "My Details" }</h4>
                    <div className='btn link-btn edit-btn' onClick={() => {setEdit(true)}}>{ isEdit ? "" : "Edit" }</div>
                </div>
                <div className='card-body'>
                    { get_error && <Alert className='mb-5' color="danger">{error}</Alert> }
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='img-box mb-4'>
                                <ProfilePicUpload image={formik.values.image} onImageSelect={handleImage} isEdit={isEdit} onImageRemove={onImageRemove} />
                             </div>
                        </div>
                        <div className='col-md-9'>
                            <form name="myProfileForm" onSubmit={formik.handleSubmit}>
                                
                                <div className='row'>
                                    
                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="first_name">First Name</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="first_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.first_name} readOnly={!isEdit} />
                                            </div>
                                            { formik.touched.first_name && <p className="mt-1 text-sm text-red-500">{formik.errors.first_name}</p> }
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Last Name</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="last_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.last_name} readOnly={!isEdit} />
                                            </div>
                                        </div>
                                    </div>

                                   { /* <div className='col-md-6'>
                                        <div className='input-box'>
                                        <SelectInputGroup 
                                            label="Gender" 
                                            id="GenderSelect" 
                                            placeholder="Select Gender" 
                                            data={genderData}
                                            readonly={!isEdit}
                                            design="simple"
                                            value={formik.values.gender}
                                            onChange={handleOnSelectGender}
                                        />
                                        { formik.touched.gender && <p className="mt-1 text-sm text-red-500">{formik.errors.gender}</p> }
                                        </div>
                                    </div> */}
                                    
                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Phone</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="phone" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.phone} readOnly={!isEdit} />
                                            </div>
                                            { formik.touched.phone && <p className="mt-1 text-sm text-red-500">{formik.errors.phone}</p> }
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Email</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} readOnly={!isEdit} />
                                            </div>
                                            { formik.touched.email && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Password</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="password" name="password" placeholder='************' readOnly />
                                                <span className="input-group-text">
                                                    <FontAwesomeIcon className="text-primary-300 text-h6 pointer" icon="fas fa-edit" onClick = {handlePasswordModal} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    { /* <div className='col-lg-12'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Address</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="address" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address} readOnly={!isEdit} />
                                            </div>
                                            { formik.touched.address && <p className="mt-1 text-sm text-red-500">{formik.errors.address}</p> }
                                        </div>
                                    </div> */ }

                                    <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">City</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="city" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.city} readOnly={!isEdit} />
                                            </div>
                                        </div>
                                    </div>

                                    { /* <div className='col-lg-6'>
                                        <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                            <label htmlFor="firstName">Country</label>
                                            <div className='input-group'>
                                                <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="country" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.country} readOnly={!isEdit} />
                                            </div>
                                            { formik.touched.country && <p className="mt-1 text-sm text-red-500">{formik.errors.country}</p> }
                                        </div>
                                    </div> */ }

                                </div>
                                { isEdit && 
                                    <div className='mt-4'>
                                        <button type='submit' className='btn btn-primary submit-btn'>Update</button>
                                        <div className='btn link-btn link-dark-btn ms-3' onClick={() => {setEdit(false)}}>Cancel</div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UpdatePasswordModal 
            visible={isUpdatePasswordVisible} 
            onClose = { () => {setUpdatePasswordVisible(!isUpdatePasswordVisible)} }
            onSubmitData={onPasswordSubmit} />
    </div>
  )
}

export default MyProfile
import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_FAQ } from './actionTypes'
import { viewFaqSuccess, viewFaqFailed } from './actions'

import { viewFaqApi } from "../../helpers/api/api"

function* viewFaq({ payload:details, meta }) {
    try {
        const response = yield call(viewFaqApi)
        yield put(viewFaqSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewFaqFailed(message, meta))
    }
}

function* FaqSaga() {
    yield takeEvery(VIEW_FAQ, viewFaq)
}

export default FaqSaga


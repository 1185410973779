import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_EXAM_TYPE, VIEW_CHAPTERS, VIEW_LESSON_SCORE } from './actionTypes'
import { viewExamTypeSuccess, viewExamTypeFailed, viewChaptersSuccess, viewChaptersFailed, viewLessonScoreSuccess, viewLessonScoreFailed } from './actions'

import { viewExamTypeApi, viewChaptersApi, viewLessonScoreApi } from "../../helpers/api/api"

function* viewExamType({ meta }) {
    try {
        const response = yield call(viewExamTypeApi)
        yield put(viewExamTypeSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewExamTypeFailed(message, meta))
    }
}

function* viewChapters({ payload: details, meta }) {
    try {
        const response = yield call(viewChaptersApi, details)
        yield put(viewChaptersSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewChaptersFailed(message, meta))
    }
}

function* viewLessonScore({ payload: details, meta }) {
    try {
        const response = yield call(viewLessonScoreApi, details)
        yield put(viewLessonScoreSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewLessonScoreFailed(message, meta))
    }
}

function* LessonScoreSaga() {
    yield takeEvery(VIEW_EXAM_TYPE, viewExamType)
    yield takeEvery(VIEW_CHAPTERS, viewChapters)
    yield takeEvery(VIEW_LESSON_SCORE, viewLessonScore)
}

export default LessonScoreSaga
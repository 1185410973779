import { all, call, fork, takeEvery } from "redux-saga/effects"

import {
    TOGGLE_SIDEBAR
} from './actionTypes'


function manageBodyClass(cssClass, action = false) {

}

function* ToggleSidebar() {
    try {
      yield call(manageBodyClass, "right-bar-enabled", "add")
    } catch (error) {}
  }

export function* watchToggleSidebar() {
    yield takeEvery(TOGGLE_SIDEBAR, ToggleSidebar)
  }

function* LayoutSaga() {
    yield all([
      fork(watchToggleSidebar)
    ])
  }
  
  export default LayoutSaga
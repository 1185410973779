import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, rejected, clean } from "redux-saga-thunk"

import { useFormik } from 'formik'
import { Alert } from "reactstrap"

import { loginUser as onLoginUser } from '../../../store/auth/login/actions';
import { viewCard as onViewCard } from '../../../store/card/actions';

import { sessionData } from '../../../constants/sessiondata';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SignInForm = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();


  const { user, cards, error, get_success, get_cards_success, get_error  } = useSelector(state => ({
    user: state.LoginReducer.user,
    bills: state.BillingReducer.bills,
    cards: state.CardReducer.cards,
    student: state.StudentReducer.student,
    error: state.LoginReducer.error,
    get_success: fulfilled(state, "LOGIN_USER"),
    get_bills_success: fulfilled(state, "VIEW_BILLING_INFO"),
    get_cards_success: fulfilled(state, "VIEW_CARD"),
    get_student_success: fulfilled(state, "VIEW_STUDENT"),
    get_error: rejected(state, "LOGIN_USER")
  }))

  const initialValues = {
    email: "",
    password: "",
    remember_me: false,
    parent_login: true
  }

  const onSubmit = values => {
    
    
    if(formik.values.remember_me) {
      sessionData.setParentEmail(values.email)
    }
    else {
      sessionData.removeParentEmail()
    }
    
    let userData = {
      email: values.email,
      password:values.password
    }

    dispatch(onLoginUser(userData))
    
  }

  const validate = values => {

    let errors = {}
    
    if(!values.email) {
      errors.email = "Email is required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
      errors.email = "Enter a valid email address"
    }

    if(!values.password) {
      errors.password = "Password is required!"
    }
    else if(values.password.length < 6) {
      errors.password = "Password should contain minimum 6 characters!"
    }

    return errors
  }
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  }) 

  const handleLoginType = () => {
    formik.setFieldValue("parent_login", !formik.values.parent_login)
    resetForm()
    if(!formik.values.parent_login) {
      navigate( { pathname: "/sign-in" })
    }
  }

  const handleRememberMe = () => {
    formik.setFieldValue("remember_me", !formik.values.remember_me)
  }

  const resetForm = () => {
    dispatch(clean("LOGIN_USER"))
    formik.setFieldValue("email", "")
    formik.setFieldValue("password", "")
    formik.setFieldValue("remember_me", false)
    setTimeout(() => {
      formik.setFieldTouched("email", false)
      formik.setFieldTouched("password", false)
    }, 10)
  }

  const resetRememberMe = () => {
    let data = sessionData.getParentEmail()
    if(data) {
      formik.setFieldValue("remember_me", true)
      formik.setFieldValue("email", data)
    }
  }

  const reDirect = () => {

    let plan = user?.user_plan;
    let innerPlan = user?.user_plan?.plan;


    if(plan) {
      
      let query = null

      if(user.email_verified_at) {
        sessionData.setEmailVerified("on")
      }
      
      if(!user.email_verified_at) { // if otp not verified
        sessionData.setEmailVerified("off")
        if(plan.plan_id === "2") {
          query = "?sid=2&plan=free"
        }
        else {
          let subscription = ""
          if(innerPlan.yearly_discount === "0")
            subscription = "monthly"
          else
            subscription = "yearly"
          query = `?sid=2&noc=${parseInt(plan.no_of_students)}&plan=pro&subscription=${subscription}`
        }
        navigate( { pathname: "/email-verify", search: query })
      }
      else if(plan.plan_id === "2") {

        let currentUrlParams = new URLSearchParams(location.search);

        if(user.children === 0) {// if no students added
          query = "?sid=3&plan=free"
          navigate( { pathname: "/parent/add-student", search: query })
        }
        else if(user.stripe_customer_id !== null){ // if subscription changed and payment not done
          currentUrlParams.set('upgrade', "true")
          currentUrlParams.set('plan', "pro")
          currentUrlParams.set('sid', 3)
          currentUrlParams.set('noc', parseInt(plan.no_of_students))
          navigate( { pathname: "/parent/billing-info", search: currentUrlParams.toString() }, { replace: true })
        }
        else {
          navigate( { pathname: "/parent/dashboard" })
        }
      }
      else {
          
          let currentUrlParams = new URLSearchParams(location.search);

          currentUrlParams.set('plan', "pro")

          if(innerPlan.yearly_discount === "0")
            currentUrlParams.set('subscription', "monthly")
          else
            currentUrlParams.set('subscription', "yearly")

          if(cards.length === 0){
            currentUrlParams.set('sid', 3)
            currentUrlParams.set('noc', parseInt(plan.no_of_students))
            navigate( { pathname: "/parent/billing-info", search: currentUrlParams.toString() }, { replace: true })
          }
          else if(user.children === 0) {// if no students added
            currentUrlParams.set('sid', 5)
            currentUrlParams.set('noc', parseInt(plan.no_of_students))
            currentUrlParams.set('anoc', parseInt(user.children)+1)
            navigate( { pathname: "/parent/add-student", search: currentUrlParams.toString() }, { replace: true })
          }
          else {
            navigate( { pathname: "/parent/dashboard" })
          }
      }


    }

  }

  useEffect(() => {
    resetRememberMe()
    let currentUrlParams = new URLSearchParams(location.search);
    if(currentUrlParams.get('as')) {
      formik.setFieldValue("parent_login", false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(clean("LOGIN_USER"))
    if(get_success) {

      let plan = user?.user_plan;
      if(plan.plan_id !== "2") {
        let data = {
          user_id: user?.id
        }
        dispatch(onViewCard(data))
      }
      else {
        reDirect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    if(get_cards_success) {
      reDirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_cards_success])


  return (
    <div className="form-container">
      <h2 className="text-gray-980 fw-semibold ls--2 mb-4">Welcome Back.</h2>
      <h5 className="text-gray-980 fw-regular mb-2">Sign in to your account</h5>
      <p className="text-gray-980 text-xs op-50">Enter your email address and password below</p>
      <div className="form-box mt-3">
        <form className="sign-in-form" name="signInForm" onSubmit={formik.handleSubmit}>
          
          <div className="radio-group d-flex align-items-center mb-4">
            <p className="text-base text-gray-980 fw-medium">Sign in as</p>
            <div className="form-check ms-3">
              <input className="form-check-input" type="radio" name="signInType" id="signInParent" checked={formik.values.parent_login} onChange={handleLoginType} />
              <label className="form-check-label" htmlFor="signInParent">Parent</label>
            </div>
            <div className="form-check ms-3">
              <input className="form-check-input" type="radio" name="signInType" id="signInStudent" checked={!formik.values.parent_login} onChange={handleLoginType} />
              <label className="form-check-label" htmlFor="signInStudent">Student</label>
            </div>
          </div>
          {(error && get_error) && ( <Alert color="danger">{error}</Alert>)}
          <div className="input-box auth-box">
            <label htmlFor="signInEmail">Email</label>
            <div className='input-group'>
              <input className="form-control" type="text" name="email" placeholder="Eg: JohnPaterson@gmail.com" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
              {(formik.touched.email && !formik.errors.email) && <span className='input-group-text'>
                <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
              </span>}
            </div>
            { (formik.touched.email && formik.errors.email) && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
          </div>

          <div className="mt-3 input-box auth-box">
            <label htmlFor="signInPassword">Password</label>
            <div className='input-group'>
              <input className="form-control" type="password" name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
              {(formik.touched.password && !formik.errors.password) && <span className='input-group-text'>
                <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
              </span>} 
            </div>
            { (formik.touched.password && formik.errors.password) && <p className="mt-1 text-sm text-red-500">{formik.errors.password}</p> }
          </div>

          <div className="remember-me mt-3 d-flex justify-content-between align-items-center">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="remember_me" id="rememberMeCheck" checked={formik.values.remember_me} onChange={handleRememberMe}  />
              <label className="form-check-label text-gray-960 text-sm" htmlFor="rememberMeCheck">Remember Me</label>
            </div>
            <Link to="/request-password" className="text-blue-500 fw-medium ft-head">Forgot Password?</Link>
          </div>

          <div className="mt-4">
            <button type="submit" className="btn btn-special w-100 text-uppercase ls--15">Log in</button>
          </div>
          <div className="mt-3 text-center">
            <span className="ft-head fw-medium text-sm ls--25">Don't have an account yet?</span> <Link to="/select-plan" className="text-blue-500 fw-medium ft-head">Sign Up</Link>
          </div>

        </form>
      </div>
    </div>
  )
}

export default SignInForm
import React, { useState, useEffect } from 'react'
import { pending, fulfilled } from 'redux-saga-thunk';

import { useSelector, useDispatch } from 'react-redux';
import _ from 'underscore'
import { Tooltip } from "reactstrap"
import questionIconImg from "../../../assets/images/question-rounded-icon.svg"

import { viewSubscriptions  as onViewSubscriptions} from '../../../store/plans-and-prices/actions';
import { viewPlans as onViewPlans } from '../../../store/plans-and-prices/actions';

import CancelSubscriptionModal from './CancelSubscriptionModal';
import AutoRenewalModal from './AutoRenewalModal';

import ChosenPlan from '../../../components/Common/ChosenPlan'
import PlanFeatures from '../../../components/Common/PlanFeatures';

import moment from 'moment';
import Loader from '../../../components/Common/Loader';


const PlanSettings = props => {

  const dispatch = useDispatch()

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [ isCancelModalVisible, setCancelModalVisible ] = useState(false)
  const [ isAutoRenewalModalVisible, setAutoRenewalModalVisible ] = useState(false)
  const [ planDetails, setPlanDetails ] = useState(null)
  const [ isAutoRenewalOn, setRenewalOn ] = useState(false);
  const [ planSubscriptionType, setPlanSubscriptionType ] = useState("")

  const { user, myplan, subscriptions, autorenewal, get_subs, get_subs_success, get_plan_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    subscriptions: state.PlansAndPricesReducer.subscriptions,
    myplan: state.PlansAndPricesReducer.myplan,
    autorenewal: state.PlansAndPricesReducer.autorenewal,
    get_subs: pending(state, "VIEW_MY_SUBSCRIPTIONS"),
    get_subs_success: fulfilled(state, "VIEW_MY_SUBSCRIPTIONS"),
    get_plan_success: fulfilled(state, "VIEW_PLANS")
  }))

  const showCancelSubscriptionModal = () => {
    setCancelModalVisible(true)
  }

  const updateRenewal = () => {
   
  }

  const onRenewalClose = () => {
    setAutoRenewalModalVisible(false)
    updateRenewal()
  }

  useEffect(() => {
    if(!_.isEmpty(user)) {
      let data = { user_id: user.id }
      dispatch(onViewSubscriptions(data))
      dispatch(onViewPlans())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(autorenewal && autorenewal === "auto_renewal_off") {
      setRenewalOn(false)
    }
    else {
      setRenewalOn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[autorenewal])

  useEffect(() => {
    if(get_subs_success) {
      setPlanDetails(subscriptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_subs_success])

  useEffect(() => {
    if(get_plan_success) {
      if(myplan.length !== 0 ) {
        let duration = moment(myplan[0].end_date).diff(moment(myplan[0].start_date), 'days')
        if(duration === 30) {
          setPlanSubscriptionType('monthly')
        }
        else {
          setPlanSubscriptionType('yearly')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_plan_success])

  useEffect(() => {
    if(planDetails) {
      if(planDetails.plan.id === 3) {
        let renewal_data = subscriptions?.data?.pause_collection
        if(renewal_data) {
          setRenewalOn(false)
        }
        else {
          setRenewalOn(true)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails])


  return (
    <div className='plan-settings-wrapper'>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='card gray-card'>
            <div className='card-header'><h4 className='ft-default fw-regular'>Plans Details</h4></div>
            <div className='card-body position-relative'>
            { get_subs && <div className="my-4"><Loader /></div> }
              { get_subs_success && planDetails && <>
              <div className='desp-box pt-4 mb-3'>
                <PlanFeatures id={planDetails?.plan?.id} plan_subscription_type={planSubscriptionType} />
              </div>

              <div className='desp-box pt-4 mb-3'>
                <p className='text-h4'>Plan Expires Date</p>
                <div className='mt-2'>
                  <p className='text-gray-700 text-base'>{moment(planDetails.end_date).format("Do MMMM YYYY")} at {moment(planDetails.end_date).format("h:mm:ss")} GMT</p>
                </div>
              </div>
              
              <ChosenPlan id={planDetails?.plan.id}>
                <div className='desp-box pt-4 mb-3'>
                  <p className='text-h4 renewal-box'>Auto Renewal: <span className='text-uppercase'>{isAutoRenewalOn ? "on" : "off"}</span> <img className='info-icon' id="infoTooltip" src={questionIconImg} alt="" /></p>
                  <Tooltip
                    isOpen={tooltipOpen}
                    placement="right"
                    target="infoTooltip"
                    toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                    Please read all the Details before turn on And off the auto renewal.
                  </Tooltip>
                  <div className='mt-3'>
                    <div className='btn btn-primary rounded-lg text-h5 fw-regular' onClick={() => {setAutoRenewalModalVisible(!isAutoRenewalModalVisible)}}>Turn {isAutoRenewalOn ? "off" : "on"}  auto Renewal</div>
                  </div>
                </div>
              </ChosenPlan>

              { planDetails.plan.id === 3 && <div className='desp-box pt-4 mb-3'>
                <div className='btn btn-link no-decor pointer text-h4 px-0' onClick={showCancelSubscriptionModal}><span className='fw-regular text-primary-500'>Cancel subscription Early?</span></div>
              </div> }
              </> }
            </div>
          </div>
        </div>
      </div>
      <CancelSubscriptionModal visible={isCancelModalVisible}
            onClose = { () => {setCancelModalVisible(!isCancelModalVisible)} }/>
      <AutoRenewalModal user={user} subscriptions={subscriptions} visible={isAutoRenewalModalVisible} isAutoRenewalOn={isAutoRenewalOn}
            onClose = { () => onRenewalClose() }/>
    </div> 
  )
}


export default PlanSettings
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AccountStepper from '../AccountStepper/AccountStepper'
import AddBillingDetails from './AddBillingDetails'

import PaymentSummary from '../../../../components/Common/PaymentSummary'

const BillingInfo = props => {

  const location = useLocation()
  const [ isBillingDetails, setBillingDetails ] = useState(true)

  useEffect(() => {
    let currentUrlParams = new URLSearchParams(location.search);

    if(currentUrlParams.get('sid') === '3') {
        setBillingDetails(true)
    }
    else {
        setBillingDetails(false)
    }

  }, [location])

  return (
    <div className='billing-info-wrapper'>
        <AccountStepper />
        <div className='row'>
                <div className='col-md-7'>
                    <div className='card gray-card h-100'>
                        <div className='card-header'><h4 className='ft-default fw-regular'>{ isBillingDetails ? "Add Billing Information" : "Select payment method" }</h4></div>
                        <div className='card-body p-4'>
                            <AddBillingDetails />
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='card gray-card summary-card h-100'>
                        <div className='card-header'><h4 className='ft-default fw-regular'>Summary</h4></div>
                        <div className='card-body p-4'>
                            <PaymentSummary />
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}


export default BillingInfo
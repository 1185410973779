import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { pending, fulfilled } from 'redux-saga-thunk'

import { useFormik } from 'formik'

import growthBlockImg  from '../../../assets/images/growth-block-img.png'

import GrowthScoreCard from './GrowthScoreCard'
import SelectInputOverlap from '../../../components/Common/SelectInputOverlap'

import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { viewExamType as onViewExamType } from '../../../store/lesson-score/actions';
import { viewChapters as onViewChapters } from '../../../store/lesson-score/actions';
import { viewLessonScore as onViewLessonScore } from '../../../store/lesson-score/actions';
import Loader from '../../../components/Common/Loader';

const GrowthScore = props => {

  const dispatch = useDispatch()
  const location = useLocation()

  const [ isScoreSelected, setScoreSelected ] = useState(false)
  const [ isApplyEnable, setApplyEnable ] = useState(false)
  const  [ availableChapters, setAvailableChapters ] = useState([])

  const { subject, exam_type, chapters, score, get_subject, get_subject_success, get_exam_type, get_exam_type_success, get_chapter_success, get_score_success } = useSelector(state => ({
    subject: state.SubjectReducer.subject,
    exam_type: state.LessonScoreReducer.exam_type,
    chapters: state.LessonScoreReducer.chapters,
    score: state.LessonScoreReducer.score,
    get_subject: pending(state, "VIEW_SUBJECT"),
    get_exam_type: pending(state, "VIEW_EXAM_TYPE"),
    get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
    get_exam_type_success: fulfilled(state, "VIEW_EXAM_TYPE"),
    get_chapter_success: fulfilled(state, "VIEW_CHAPTERS"),
    get_score_success: fulfilled(state, "VIEW_LESSON_SCORE")
}))

  const initialValues = {
    subject_id: "",
    chapter_id: "",
    exam_board_id: ""
  } 

  const validate = values => {

    let errors = {}

    if(!values.subject_id) {
      errors.subject_id = "Subject ID required!"
    }

    if(!values.chapter_id) {
        errors.chapter_id = "Chapter required!"
    }

    if(!values.exam_board_id) {
        errors.exam_board_id = "Exam required!"
    }
    
    return errors

  }

  const onSubmit = values => {

    let currentUrlParams = new URLSearchParams(location.search);
    let user_id = currentUrlParams.get('sid')
    
    let data = {
      user_id: parseInt(user_id),
      subject_id: formik.values.subject_id,
      chapter_id: formik.values.chapter_id,
      lesson_type_id: formik.values.exam_board_id,
    }
    dispatch(onViewLessonScore(data))
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  const handleOnSelectSubject = (id) => {
    formik.setFieldValue("subject_id", id)
  }

  const handleOnSelectExamType = (id) => {
    formik.setFieldValue("exam_board_id", id)
    
  }

  const handleOnSelectChapter = (id) => {
    formik.setFieldValue("chapter_id", id)
  }

  useEffect(() => {
    dispatch(onViewSubject())
    dispatch(onViewExamType())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_score_success)
    setScoreSelected(true) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_score_success])

  useEffect(() => {
    setScoreSelected(false)    
    setApplyEnable(false)
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if(get_chapter_success) {
      setAvailableChapters(chapters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_chapter_success])

  useEffect(() => {
    if(formik.values.subject_id && formik.values.exam_board_id) {
      formik.setFieldValue("chapter_id", "")
      setAvailableChapters([])
      let data = {
        subject_id: formik.values.subject_id,
        exam_board_id: formik.values.exam_board_id
      }
      dispatch(onViewChapters(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.subject_id, formik.values.exam_board_id])

  useEffect(() => {
    if(formik.values.subject_id && formik.values.exam_board_id && formik.values.chapter_id) {
      setApplyEnable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.subject_id, formik.values.exam_board_id, formik.values.chapter_id])

  return (
    <div className='growth-inner-wrapper'>
        <div className='row'>
          <div className='col-md-7'>
            <div className='left-box bg-white h-100'>
              { !isScoreSelected ? 
                <div className='no-results'>
                  <div className='img-container'>
                    <img src={growthBlockImg} alt="" />
                  </div>
                  <div className='desp-box'>
                    <h6 className='title'>To check the score select the given fields.</h6>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum reprehenderit est assumenda doloribus accusantium nobis </p>
                  </div>
                </div> : 
                <GrowthScoreCard score={score} />
              }
            </div>
          </div>
          <div className='col-md-5'>
            <div className='right-box bg-white'>
            { get_subject && get_exam_type && <Loader /> }
              { get_subject_success && get_exam_type_success &&
                <form className="create-lesson-form" name="createLessonForm" onSubmit={formik.handleSubmit}>
              
                  <div className='select-score-box'>
                    <SelectInputOverlap
                        fieldType="name"
                        fieldLabel="selectScoreSubject"
                        data={subject}  
                        placeholder="Select Subject"
                        design="primary"
                        onChange={handleOnSelectSubject}
                    />
                  </div>

                  <div className='select-score-box'>
                    <SelectInputOverlap
                        fieldType="title"
                        fieldLabel="selectScoreEXamType"
                        data={exam_type}  
                        placeholder="Select Exam type"
                        design="primary"
                        onChange={handleOnSelectExamType}
                    />
                  </div>
                  <div className='select-score-box'>
                    <SelectInputOverlap
                        fieldType="name"
                        fieldLabel="selectScoreChapter"
                        data={availableChapters}  
                        placeholder="Select Chapter"
                        design="primary"
                        onChange={handleOnSelectChapter}
                    />
                  </div>

                  <div className='mt-5'>
                    <button className={`btn btn-primary submit-btn ${ isApplyEnable ? "" : "disabled" }`}>Apply</button>
                  </div>
                
                </form>
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default GrowthScore
import React from 'react'

import { freePlanFeatures, proPlanFeatures } from '../../constants/planFeatures'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const PlanFeatures = ({id, plan_subscription_type, title=true, inline=true}) => {
  return (
    <>
    { id === 2 ? (
        <div>
            { title && <p className='text-h4'>Free Subscription</p> }
            <ul className="list-inline-group mt-2">
                {
                freePlanFeatures.map((item, index) => {
                    return (
                    <li key={index} className={`list-inline-item ${inline ? "" : "w-100"}`}>
                        <div className='py-2 text-gray-700 text-base'><FontAwesomeIcon className='text-green-500 me-2' icon="fa-solid fa-circle-check" />{item}</div>
                    </li>
                    )
                })
                }
            </ul>
        </div>
        
    ) : (
        <div>
            { title && <p className='text-h4 text-capitalize'>{plan_subscription_type} Subscription (Pro)</p> }
            <ul className="list-inline-group mt-3">
                {
                proPlanFeatures.map((item, index) => {
                    return (
                    <li key={index} className={`list-inline-item ${inline ? "" : "w-100"}`}>
                        <div className='py-2 text-gray-700 text-base'><FontAwesomeIcon className='text-green-500 me-2' icon="fa-solid fa-circle-check" />{item}</div>
                    </li>
                    )
                })
                }
            </ul>
        </div>
    )}
    </>
  )
}

export default PlanFeatures
import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = props => {
  return (
    <div className='page-not-found-wrapper'>
        <div className='page-not-found-container d-flex justify-content-center align-items-center vh-100'>
            <div className='content text-center'>
                <h2 className='text-huge text-primary-500'>404</h2>
                <h2 className='ft-default text-gray-800 mb-3'>Page Not Found</h2>
                <p className='text-gray-400 mb-5'>We are sorry, the page you requested couldn't be found</p>
                <Link to="/parent/dashboard" className='btn btn-primary'>Go to Dashboard</Link>
            </div>
        </div>
    </div>
  )
}

export default PageNotFound
import {
    VIEW_NOTICE,
    VIEW_NOTICE_SUCCESS,
    VIEW_NOTICE_FAILED
} from './actionTypes'

export const viewNotice = (notice) => {
    return {
        type: VIEW_NOTICE,
        payload: notice,
        meta:{
            thunk:true
        }
    }
}

export const viewNoticeSuccess = (notice, {thunk}) => {
    return {
        type: VIEW_NOTICE_SUCCESS,
        payload: notice,
        meta: {
            thunk
        }
    }
}

export const viewNoticeFailed = (notice, {thunk}) => {
    return {
        type: VIEW_NOTICE_FAILED,
        payload: notice,
        error: true,
        meta: {
            thunk
        }
    }
}

import {
    VIEW_ASSIGNMENT_LESSON,
    VIEW_ASSIGNMENT_LESSON_SUCCESS,
    VIEW_ASSIGNMENT_LESSON_FAILED,
    VIEW_ASSIGNED_ASSIGNMENT,
    VIEW_ASSIGNED_ASSIGNMENT_SUCCESS,
    VIEW_ASSIGNED_ASSIGNMENT_FAILED,
    VIEW_ASSIGNMENT_CHAPTER,
    VIEW_ASSIGNMENT_CHAPTER_SUCCESS,
    VIEW_ASSIGNMENT_CHAPTER_FAILED,
    UPDATE_ASSIGNMENT_STATUS,
    UPDATE_ASSIGNMENT_STATUS_SUCCESS,
    UPDATE_ASSIGNMENT_STATUS_FAILED,
    CREATE_PRACTICE_TEST,
    CREATE_PRACTICE_TEST_SUCCESS,
    CREATE_PRACTICE_TEST_FAILED,
    DELETE_COMPLETED_ASSIGNMENT,
    DELETE_COMPLETED_ASSIGNMENT_SUCCESS,
    DELETE_COMPLETED_ASSIGNMENT_FAILED,
    VIEW_COMPLETED_ASSIGNMENT,
    VIEW_COMPLETED_ASSIGNMENT_SUCCESS,
    VIEW_COMPLETED_ASSIGNMENT_FAILED
} from './actionTypes';

export const viewAssignmentLesson = (assignment) => {
    return {
        type: VIEW_ASSIGNMENT_LESSON,
        payload: assignment,
        meta:{
            thunk:true
        }
    }
}
  
export const viewAssignmentLessonSuccess = (assignment, { thunk }) => ({
    type: VIEW_ASSIGNMENT_LESSON_SUCCESS,
    payload: assignment,
    meta: {
        thunk
    }
})
  
export const viewAssignmentLessonFailed = (error, { thunk }) => ({
    type: VIEW_ASSIGNMENT_LESSON_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const viewAssignedAssignment = (assigned_assignment) => {
    return {
        type: VIEW_ASSIGNED_ASSIGNMENT,
        payload: assigned_assignment,
        meta:{
            thunk:true
        }
    }
}
  
export const viewAssignedAssignmentSuccess = (assigned_assignment, { thunk }) => ({
    type: VIEW_ASSIGNED_ASSIGNMENT_SUCCESS,
    payload: assigned_assignment,
    meta: {
        thunk
    }
})
  
export const viewAssignedAssignmentFailed = (error, { thunk }) => ({
    type: VIEW_ASSIGNED_ASSIGNMENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const viewAssignmentChapter = (chapter) => {
    return {
        type: VIEW_ASSIGNMENT_CHAPTER,
        payload: chapter,
        meta:{
            thunk:true
        }
    }
}
  
export const viewAssignmentChapterSuccess = (chapter, { thunk }) => ({
    type: VIEW_ASSIGNMENT_CHAPTER_SUCCESS,
    payload: chapter,
    meta: {
        thunk
    }
})
  
export const viewAssignmentChapterFailed = (error, { thunk }) => ({
    type: VIEW_ASSIGNMENT_CHAPTER_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const updateAssignmentStatus = (status) => {
    return {
        type: UPDATE_ASSIGNMENT_STATUS,
        payload: status,
        meta:{
            thunk:true
        }
    }
}
  
export const updateAssignmentStatusSuccess = (status, { thunk }) => ({
    type: UPDATE_ASSIGNMENT_STATUS_SUCCESS,
    payload: status,
    meta: {
        thunk
    }
})
  
export const updateAssignmentStatusFailed = (error, { thunk }) => ({
    type: UPDATE_ASSIGNMENT_STATUS_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const createPracticeTest = (status) => {
    return {
        type: CREATE_PRACTICE_TEST,
        payload: status,
        meta:{
            thunk:true
        }
    }
}
  
export const createPracticeTestSuccess = (status, { thunk }) => ({
    type: CREATE_PRACTICE_TEST_SUCCESS,
    payload: status,
    meta: {
        thunk
    }
})
  
export const createPracticeTestFailed = (error, { thunk }) => ({
    type: CREATE_PRACTICE_TEST_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const deleteCompletedAssignment = (data) => {
    return {
        type: DELETE_COMPLETED_ASSIGNMENT,
        payload: data,
        meta:{
            thunk:true
        }
    }
}
  
export const deleteCompletedAssignmentSuccess = (data, { thunk }) => ({
    type: DELETE_COMPLETED_ASSIGNMENT_SUCCESS,
    payload: data,
    meta: {
        thunk
    }
})
  
export const deleteCompletedAssignmentFailed = (error, { thunk }) => ({
    type: DELETE_COMPLETED_ASSIGNMENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const viewCompletedAssignment = (completed_assignment) => {
    return {
        type: VIEW_COMPLETED_ASSIGNMENT,
        payload: completed_assignment,
        meta:{
            thunk:true
        }
    }
}
  
export const viewCompletedAssignmentSuccess = (completed_assignment, { thunk }) => ({
    type: VIEW_COMPLETED_ASSIGNMENT_SUCCESS,
    payload: completed_assignment,
    meta: {
        thunk
    }
})
  
export const viewCompletedAssignmentFailed = (error, { thunk }) => ({
    type: VIEW_COMPLETED_ASSIGNMENT_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})
import React, { useState, useEffect } from 'react'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SimpleUpload = ({ onImageSelect, value }) => {

  const [selectedImagePreview, setSelectedImagePreview] = useState(null)

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const imageHandler = async (e) => {
    setSelectedImagePreview(await toBase64(e.target.files[0]))
  }

  useEffect(() => {
    onImageSelect(selectedImagePreview)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImagePreview])

  useEffect(() => {
    if(!value) {
      setSelectedImagePreview(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className='simple-upload-wrapper'>
      <div className='simple-upload-container'>
        { !selectedImagePreview ? (
          <>
            <div className='simple-upload-box d-flex justify-content-center align-items-center flex-column'>
                <FontAwesomeIcon className='text-primary-500 fs-4' icon="fa-solid fa-camera" />
                <p>Add photo</p>
            </div>
            <input type="file" className='form-control' onChange={imageHandler} />
          </>
        ) : (
          <>
          <div className='img-preview-holder'>
            <img src={selectedImagePreview} alt="" />
          </div>
          </>
        ) }
      </div>
      { selectedImagePreview && (
        <div className='close-icon' onClick={() => {setSelectedImagePreview(!selectedImagePreview)}}>
          <FontAwesomeIcon icon="fas fa-times" />
        </div>
      )}
    </div>
  )
}


export default SimpleUpload
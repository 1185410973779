import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_NOTICE } from './actionTypes'
import { viewNoticeSuccess, viewNoticeFailed } from './actions'

import { viewNoticeApi } from "../../helpers/api/api"

function* viewNotice({ payload:details, meta }) {
    try {
        const response = yield call(viewNoticeApi, details)
        yield put(viewNoticeSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewNoticeFailed(message, meta))
    }
}


function* NoticeSaga() {
    yield takeEvery(VIEW_NOTICE, viewNotice)
}

export default NoticeSaga
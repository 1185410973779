import {
    VIEW_ALL_PLANS,
    VIEW_ALL_PLANS_SUCCESS,
    VIEW_ALL_PLANS_FAILED,
    VIEW_PLANS,
    VIEW_PLANS_SUCCESS,
    VIEW_PLANS_FAILED,
    BUY_PLAN,
    BUY_PLAN_SUCCESS,
    BUY_PLAN_FAILED,
    VIEW_MY_SUBSCRIPTIONS,
    VIEW_MY_SUBSCRIPTIONS_SUCCESS,
    VIEW_MY_SUBSCRIPTIONS_FAILED,
    UPDATE_RENEWAL,
    UPDATE_RENEWAL_SUCCESS,
    UPDATE_RENEWAL_FAILED,
    CHANGE_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION_SUCCESS,
    CHANGE_SUBSCRIPTION_FAILED,
    MAKE_PAYMENT,
    MAKE_PAYMENT_SUCCESS,
    MAKE_PAYMENT_FAILED,
    BILLING_DETAILS_SUBMIT,
    BILLING_DETAILS_SUBMIT_SUCCESS,
    BILLING_DETAILS_SUBMIT_FAILED,
    APPLY_COUPON,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAILED,
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_FAILED,
    ADD_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD_FAILED,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILED,
    DELETE_CARD_DETAILS,
    DELETE_CARD_DETAILS_SUCCESS,
    DELETE_CARD_DETAILS_FAILED,
    VIEW_CARD_DETAILS,
    VIEW_CARD_DETAILS_SUCCESS,
    VIEW_CARD_DETAILS_FAILED
} from './actionTypes'

export const viewAllPlans = () => {
    return {
        type: VIEW_ALL_PLANS,
        meta:{
            thunk:true
        }
    }
}

export const viewAllPlansSuccess = (myplan, {thunk}) => {
    return {
        type: VIEW_ALL_PLANS_SUCCESS,
        payload: myplan,
        meta: {
            thunk
        }
    }
}

export const viewAllPlansFailed = (myplan, {thunk}) => {
    return {
        type: VIEW_ALL_PLANS_FAILED,
        payload: myplan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewPlans = () => {
    return {
        type: VIEW_PLANS,
        meta:{
            thunk:true
        }
    }
}

export const viewPlansSuccess = (myplan, {thunk}) => {
    return {
        type: VIEW_PLANS_SUCCESS,
        payload: myplan,
        meta: {
            thunk
        }
    }
}

export const viewPlansFailed = (myplan, {thunk}) => {
    return {
        type: VIEW_PLANS_FAILED,
        payload: myplan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const buyPlan = (plan) => {
    return {
        type: BUY_PLAN,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const buyPlanSuccess = (plan, {thunk}) => {
    return {
        type: BUY_PLAN_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const buyPlanFailed = (plan, {thunk}) => {
    return {
        type: BUY_PLAN_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewSubscriptions = (plan) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const viewSubscriptionsSuccess = (plan, {thunk}) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const viewSubscriptionsFailed = (plan, {thunk}) => {
    return {
        type: VIEW_MY_SUBSCRIPTIONS_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const changeSubscription = (plan) => {
    return {
        type: CHANGE_SUBSCRIPTION,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const changeSubscriptionSuccess = (plan, {thunk}) => {
    return {
        type: CHANGE_SUBSCRIPTION_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const changeSubscriptionFailed = (plan, {thunk}) => {
    return {
        type: CHANGE_SUBSCRIPTION_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updateRenewal = (renewal) => {
    return {
        type: UPDATE_RENEWAL,
        payload: renewal,
        meta:{
            thunk:true
        }
    }
}

export const updateRenewalSuccess = (renewal, {thunk}) => {
    return {
        type: UPDATE_RENEWAL_SUCCESS,
        payload: renewal,
        meta: {
            thunk
        }
    }
}

export const updateRenewalFailed = (error, {thunk}) => {
    return {
        type: UPDATE_RENEWAL_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const billingDetailsSubmit = (details) => {
    return {
        type: BILLING_DETAILS_SUBMIT,
        payload: details,
        meta:{
            thunk:true
        }
    }
}

export const billingDetailsSubmitSuccess = (details, {thunk}) => {
    return {
        type: BILLING_DETAILS_SUBMIT_SUCCESS,
        payload: details,
        meta: {
            thunk
        }
    }
}

export const billingDetailsSubmitFailed = (error, {thunk}) => {
    return {
        type: BILLING_DETAILS_SUBMIT_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const makePayment = (payment) => {
    return {
        type: MAKE_PAYMENT,
        payload: payment,
        meta:{
            thunk:true
        }
    }
}

export const makePaymentSuccess = (payment, {thunk}) => {
    return {
        type: MAKE_PAYMENT_SUCCESS,
        payload: payment,
        meta: {
            thunk
        }
    }
}

export const makePaymentFailed = (error_payment, {thunk}) => {
    return {
        type: MAKE_PAYMENT_FAILED,
        payload: error_payment,
        error: true,
        meta: {
            thunk
        }
    }
}

export const applyCoupon = (coupon) => {
    return {
        type: APPLY_COUPON,
        payload: coupon,
        meta:{
            thunk:true
        }
    }
}

export const applyCouponSuccess = (coupon, {thunk}) => {
    return {
        type: APPLY_COUPON_SUCCESS,
        payload: coupon,
        meta: {
            thunk
        }
    }
}

export const applyCouponFailed = (error, {thunk}) => {
    return {
        type: APPLY_COUPON_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updateSubscription = (plan) => {
    return {
        type: UPDATE_SUBSCRIPTION,
        payload: plan,
        meta:{
            thunk:true
        }
    }
}

export const updateSubscriptionSuccess = (plan, {thunk}) => {
    return {
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        payload: plan,
        meta: {
            thunk
        }
    }
}

export const updateSubscriptionFailed = (plan, {thunk}) => {
    return {
        type: UPDATE_SUBSCRIPTION_FAILED,
        payload: plan,
        error: true,
        meta: {
            thunk
        }
    }
}

export const addPaymentMethod = (payment) => {
    return {
        type: ADD_PAYMENT_METHOD,
        payload: payment,
        meta:{
            thunk:true
        }
    }
}

export const addPaymentMethodSuccess = (payment, {thunk}) => {
    return {
        type: ADD_PAYMENT_METHOD_SUCCESS,
        payload: payment,
        meta: {
            thunk
        }
    }
}

export const addPaymentMethodFailed = (error_payment, {thunk}) => {
    return {
        type: ADD_PAYMENT_METHOD_FAILED,
        payload: error_payment,
        error: true,
        meta: {
            thunk
        }
    }
}

export const updatePaymentMethod = (payment) => {
    return {
        type: UPDATE_PAYMENT_METHOD,
        payload: payment,
        meta:{
            thunk:true
        }
    }
}

export const updatePaymentMethodSuccess = (payment, {thunk}) => {
    return {
        type: UPDATE_PAYMENT_METHOD_SUCCESS,
        payload: payment,
        meta: {
            thunk
        }
    }
}

export const updatePaymentMethodFailed = (error_payment, {thunk}) => {
    return {
        type: UPDATE_PAYMENT_METHOD_FAILED,
        payload: error_payment,
        error: true,
        meta: {
            thunk
        }
    }
}

export const deleteCardDetails = (card) => {
    return {
        type: DELETE_CARD_DETAILS,
        payload: card,
        meta:{
            thunk:true
        }
    }
}

export const deleteCardDetailsSuccess = (card, {thunk}) => {
    return {
        type: DELETE_CARD_DETAILS_SUCCESS,
        payload: card,
        meta: {
            thunk
        }
    }
}

export const deleteCardDetailsFailed = (error, {thunk}) => {
    return {
        type: DELETE_CARD_DETAILS_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewCardDetails = () => {
    return {
        type: VIEW_CARD_DETAILS,
        meta:{
            thunk:true
        }
    }
}

export const viewCardDetailsSuccess = (card, {thunk}) => {
    return {
        type: VIEW_CARD_DETAILS_SUCCESS,
        payload: card,
        meta: {
            thunk
        }
    }
}

export const viewCardDetailsFailed = (error, {thunk}) => {
    return {
        type: VIEW_CARD_DETAILS_FAILED,
        payload: error,
        error: true,
        meta: {
            thunk
        }
    }
}
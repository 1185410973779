import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_NOTIFICATIONS, UPDATE_NOTIFICATION, VIEW_NOTIFICATION_SETTING, UPDATE_NOTIFICATION_SETTING } from './actionTypes'
import { viewNotificationSuccess, viewNotificationFailed, updateNotificationSuccess, updateNotificationFailed, viewNotificationSettingSuccess, viewNotificationSettingFailed, updateNotificationSettingSuccess, updateNotificationSettingFailed } from './actions'

import { viewNotificationsApi, updateNotificationApi, viewNotificationSettingApi, updatewNotificationSettingApi } from "../../helpers/api/api"

function* viewNotifications({ payload:details, meta }) {
    try {
        const response = yield call(viewNotificationsApi, details)
        yield put(viewNotificationSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewNotificationFailed(message, meta))
    }
}

function* updateNotification({ payload:details, meta }) {
    try {
        const response = yield call(updateNotificationApi, details)
        yield put(updateNotificationSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateNotificationFailed(message, meta))
    }
}

function* viewNotificationSetting({ payload:details, meta }) {
    try {
        const response = yield call(viewNotificationSettingApi, details)
        yield put(viewNotificationSettingSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewNotificationSettingFailed(message, meta))
    }
}

function* updateNotificationSetting({ payload:details, meta }) {
    try {
        const response = yield call(updatewNotificationSettingApi, details)
        let { data } = response
        yield put(updateNotificationSettingSuccess(data, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateNotificationSettingFailed(message, meta))
    }
}

function* NotificationsSaga() {
    yield takeEvery(VIEW_NOTIFICATIONS, viewNotifications)
    yield takeEvery(UPDATE_NOTIFICATION, updateNotification)
    yield takeEvery(VIEW_NOTIFICATION_SETTING, viewNotificationSetting)
    yield takeEvery(UPDATE_NOTIFICATION_SETTING, updateNotificationSetting)
}

export default NotificationsSaga
import React from 'react'

const AssignedLesson = props => {

    const assignedLessonDetails = [
        {
            name: "Math",
            total: "20",
            completed: "12",
            color: 'orange-300'
        },
        {
            name: "Science",
            total: "90",
            completed: "34",
            color: 'primary-700'
        }
    ]

    const getStatus = (completed, total) => {
        let data = ((completed/total)*100)
        return `${data}%`
    }

  return (
    <div className='dashboard-assigned-lesson-wrapper'>
        <div className='card rounded-lg'>
            <div className='card-body p-4'>
                <h5 className='mb-3 fw-semibold'>Assigned Lesson</h5>
                {
                    assignedLessonDetails.map((item, index) => (
                        <div key={index} className='lesson-box'>
                            <div className='progress bg-gray-200 mb-2'>
                                <div style={{width: getStatus(item.completed, item.total)}} className={`status-bar bg-${item.color}`}>&nbsp;</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <p className='text-sm fw-medium'>{item.name}</p>
                                <p className='text-sm fw-semibold text-gray-550'>{item.completed}/{item.total}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div> 
  )
}

export default AssignedLesson
import {
   ADD_STUDENT_SUCCESS,
   ADD_STUDENT_FAILED,
   VIEW_STUDENT_SUCCESS,
   VIEW_STUDENT_FAILED,
   DELETE_STUDENT_SUCCESS,
   DELETE_STUDENT_FAILED,
   UPDATE_STUDENT_SUCCESS,
   UPDATE_STUDENT_FAILED,
  } from './actionTypes'
    
  const initialState = {
      error: "",
      loading: false,
      student: {}
  }

  const StudentReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case ADD_STUDENT_SUCCESS:{
          const student = action.payload
          return {
            ...state,
            loading: false,
            student
          }
        }
        case ADD_STUDENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case VIEW_STUDENT_SUCCESS:{
          const student = action.payload
          return {
            ...state,
            loading: false,
            student
          }
        }
        case VIEW_STUDENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case DELETE_STUDENT_SUCCESS:{
          return {
            ...state,
          }
        }
        case DELETE_STUDENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case UPDATE_STUDENT_SUCCESS:{
          return {
            ...state,
          }
        }
        case UPDATE_STUDENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        default:
          return state
      }
  
    }
    
    export default StudentReducer
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation  } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled } from 'redux-saga-thunk';
import _ from 'underscore'

import SelectInputOverlap from '../../components/Common/SelectInputOverlap';
import ActivityAssigned from './ActivityAssigned';
import ActivityUnAssigned from './ActivityUnAssigned';
import ActivityNoAssignmentModal from './ActivityNoAssignmentModal';

import { viewSubject as onViewSubject } from '../../store/subject/actions';
import { viewStudent as onViewStudent } from '../../store/student/actions';

import { viewAssignmentChapter as onViewAssignmentChapter } from '../../store/assignment/actions';
import { viewAssignmentLesson as onViewAssignmentLesson } from '../../store/assignment/actions';
import { viewAssignedAssignment as onViewAssignedAssignment } from '../../store/assignment/actions';

const Activity = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const [ availableStudents, setAvailableStudents ] = useState([])
  const [ defaultStudent, setDefaultStudent ] = useState(null)
  const [ studentId, setStudentId ] = useState(null)

  const [ availableSubjects, setAvailableSubjects ] = useState([])
  const [ defaultSubject, setDefaultSubject ] = useState(null)
  const [ isSubjectSelected, setSubjectSelected ] = useState(false)
  const [ subjectId, setSubjectId ] = useState(null)

  const [ availableChapters, setAvailableChapters ] = useState([])
  const [ chapterId, setChapterId ] = useState(null)
  const [ examBoardId, setExamBoardId ] = useState(null)

  const [ availableUnAssignedAssignment, setAvailableUnAssignedAssignment ] = useState([])
  const [ availableAssignedAssignment, setAvailableAssignedAssignment ] = useState([])
  const [ isAssigned, setAssigned ] = useState(false)

  const [ isNoAssignmentModalVisible, setNoAssignmentModalVisible ] = useState(false)

  const { user, subjects, students, chapters, assignment, assigned_assignment, get_student, get_subject_success, get_chapter_success, get_assignment_success, get_assigned_assignment_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    students: state.StudentReducer.student,
    subjects: state.SubjectReducer.subject,
    chapters: state.AssignmentReducer.chapters,
    assignment: state.AssignmentReducer.assignment,
    assigned_assignment: state.AssignmentReducer.assigned_assignment,
    get_student: fulfilled(state, "VIEW_STUDENT"),
    get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
    get_chapter_success: fulfilled(state, "VIEW_ASSIGNMENT_CHAPTER"),
    get_assignment_success: fulfilled(state, "VIEW_ASSIGNMENT_LESSON"),
    get_assigned_assignment_success: fulfilled(state, "VIEW_ASSIGNED_ASSIGNMENT")
  }))

  const handleStudent = (id) => {
    if(id != null) {
      setStudentId(id)
    }
  }

  const handleSubject = (id) => {
    if(id != null) {
      setSubjectId(id)
      let data = {
        student_id: studentId
      }
      dispatch(onViewAssignmentChapter(data))
    }
  }

  const handleChange = (event) => {
    setAssigned(event.target.checked)
    onClose()
  }

  const handleChapter = (id, exam_board_id) => {
    if(id)
      {
        setChapterId(parseInt(id))
        setExamBoardId(parseInt(exam_board_id))
      }
    else
      {
        setChapterId(null)
        setExamBoardId(null)
      }
  }

  const handleRefresh = () => {
    setChapterId(null)
    setAssigned(true)
  }

  const onClose = () => {
    setNoAssignmentModalVisible(false)
  }

  useEffect(() => {
    dispatch(onViewSubject())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(user) {
      let data = { parent_id:user.id }
      dispatch(onViewStudent(data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    
    if(get_student && students.length !== 0 && availableStudents.length === 0) {
      setAvailableStudents(students)
      setDefaultStudent(students[0])
      setStudentId(students[0].id)
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [get_student])

  useEffect(() => {
    if(get_subject_success) {
        if(!_.isEmpty(students)) {
          setAvailableSubjects(subjects)
          setDefaultSubject(subjects[0])
          setSubjectId(subjects[0].id)
        }
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [get_subject_success, students])

  useEffect(() => {
    if(get_chapter_success) {
      setSubjectSelected(true)
      setAvailableChapters(chapters)
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [chapters])

  useEffect(() => {
    if(get_assignment_success) {
      setAvailableUnAssignedAssignment(assignment)
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [assignment])

  useEffect(() => {
    if(get_assigned_assignment_success) {
      setAvailableAssignedAssignment(assigned_assignment)
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [assigned_assignment])


  useEffect(() => {
    if(availableUnAssignedAssignment.length === 0) {
      if(get_assignment_success) {
       setNoAssignmentModalVisible(!isNoAssignmentModalVisible)
      }
    }
    else {
      if(get_assignment_success) {
        setNoAssignmentModalVisible(false)
       }
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [availableUnAssignedAssignment])


  useEffect(() => {
    if(availableAssignedAssignment.length === 0) {
      if(get_assigned_assignment_success) {
        setNoAssignmentModalVisible(!isNoAssignmentModalVisible)
      }
    }
    else {
      if(get_assigned_assignment_success) {
        setNoAssignmentModalVisible(false)
       }
    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [availableAssignedAssignment])


  useEffect(() => {
    
    if(!isAssigned) {

      if(subjectId !== null) {
        let data = {
          student_id: studentId
        }
        dispatch(onViewAssignmentChapter(data))
      }

      if(chapterId === null) {
        let data = {
          subject_id: subjectId
        }
        dispatch(onViewAssignmentLesson(data))
      }
      else {
        let data = {
          chapter_id: chapterId,
          subject_id: subjectId
        }
        dispatch(onViewAssignmentLesson(data))
      }

    }
    else {

      if(subjectId !== null) {
        let student_params = {
          student_id: studentId
        }
        dispatch(onViewAssignmentChapter(student_params))
      }

      let assigned_params = {
        student_id: studentId,
        completed: true
      }
      dispatch(onViewAssignedAssignment(assigned_params))

    }
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if(studentId && subjectId)
    navigate( { pathname: location.pathname, search: `?sid=${studentId}&sub_id=${subjectId}&chap_id=${chapterId}&status=${isAssigned}` })
    // eslint-disable-next-line   react-hooks/exhaustive-deps
  }, [studentId, subjectId, chapterId, isAssigned])

  return (
    <div className='activity-wrapper'>
      <h5 className='mb-4'>Assignment</h5>
      <div className='activity-container'>
        <div className='tabs-box'>
          <div className='d-flex mb-3'>
            <div className='me-3'>
              <SelectInputOverlap
                defaultItem={defaultStudent}
                fieldType="full_name"
                data={availableStudents}  
                placeholder="Select student"
                onChange={handleStudent}
              />
            </div>
            <div className='me-2'>
                <SelectInputOverlap
                  defaultItem={defaultSubject}
                  fieldType="name"
                  data={availableSubjects}  
                  placeholder="Select subject"
                  onChange={handleSubject}
                />
            </div>
          </div>
        </div>
        { isSubjectSelected &&  
          <div className='tab-content'>
            <div className='assigned-wrapper mb-4'>
              <div className='assigned-box align-items-center justify-content-center d-flex ms-auto'>
                <p className='fw-medium me-4'>Assigned</p>
                <div className='ms-auto'>
                  <div className='switch-container d-flex align-items-center'>
                    <input className="form-check-input" type="checkbox"  id="assignedInput" checked={isAssigned} onChange={(event) => {handleChange(event)}} />
                    <label className="form-check-label ft-head fw-medium" htmlFor="assignedInput">
                      <div className="switch-box">
                          <span className="slider"></span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-4 col-lg-3'>
              <ul className="list-group">
                { availableChapters.length !== 0 ? 
                  <>
                    <li className={`list-group-item ${!chapterId ? "active" : ""}`} onClick={() => {handleChapter(null, null)}}>All</li>
                    { availableChapters.map(((item, index) => {
                      return (
                        <li className={`list-group-item d-flex ${item.id === chapterId ? "active" : ""}`} onClick={() => {handleChapter(item.id, item.exam_board_id)}}>
                          <div className='d-flex w-100'>
                            <div>{ (index+1) }. {item.name}</div>
                            { !isAssigned && <div className='ms-auto'>{item.number}</div> }
                          </div>
                          <div className='ms-auto'>{item.lesson_count}</div>
                        </li>
                      )
                    })) } 
                  </>
                  :
                  <h6 className='text-gray-350 ft-default'>No chapters available</h6>
                }
              </ul>
              </div>
              <div className='col-sm-12 col-md-8 col-lg-9'>
                <div className='card table-card'>
                  <div className='card-body p-0'>
                    { isAssigned ?
                      <ActivityAssigned assignment={availableAssignedAssignment} />
                      :
                      <ActivityUnAssigned 
                        assignment={availableUnAssignedAssignment} 
                        student_id={studentId} 
                        subject_id={subjectId} 
                        chapter_id={chapterId} 
                        exam_board_id={examBoardId}  
                        onRefresh={handleRefresh}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <ActivityNoAssignmentModal visible={isNoAssignmentModalVisible} onClose = {onClose} />
    </div>
  )
}

export default Activity
import React,  { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { pending, fulfilled, clean } from 'redux-saga-thunk';

import { Carousel } from 'react-responsive-carousel';
import _ from 'underscore'

import { viewAllPlans as onViewAllPlans, viewPlans as onViewPlans, changeSubscription as onChangeSubscription, buyPlan as onBuyPlan } from '../../../../store/plans-and-prices/actions';

import arrowIcon from "../../../../assets/images/arrow-right-green-icon.svg"
import AccountPlanFeaturesModal from './AccountPlanFeaturesModal';

import Loader from '../../../../components/Common/Loader'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SelectPlan = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const{ all_plans, myplan, get_all_plans, get_all_plans_success, get_plan_success, get_change_subscription_success, get_buyplan_success } = useSelector(state => ({
    all_plans: state.PlansAndPricesReducer.all_plans,
    myplan: state.PlansAndPricesReducer.myplan,
    get_all_plans: pending(state, "VIEW_ALL_PLANS"),
    get_all_plans_success: fulfilled(state, "VIEW_ALL_PLANS"),
    get_plan_success: fulfilled(state, "VIEW_PLANS"),
    get_change_subscription_success: fulfilled(state, "CHANGE_SUBSCRIPTION"),
    get_buyplan_success: fulfilled(state, "BUY_PLAN")
  }))

  const [ yearlyPlan, setYearlyPlan ] = useState(true)
  const  [ childrenCount, setChildrenCount ] = useState(1)
  const [ isFreePlanDisabled, setFreePlanDisabled ] = useState(false)
  const [ isFeaturesVisible, setFeaturesVisible ] = useState(false)
  const [ allPlans, setAllPlans ] = useState([])
  const [ myPlan, setMyPlan ] = useState(null)
  const [ currentSlide, setCurrentSlide ] = useState(0)

  const handleChangePlan = (type) => {
    return type === 'yearly' ? setYearlyPlan(true) : setYearlyPlan(false)
  }

  const handleChildren = (type) => {
    return type === 'plus' ? setChildrenCount(childrenCount + 1) : setChildrenCount(childrenCount - 1)
  }

  const showFeatures = () => {
    setFeaturesVisible(true)
  }

  const getDiscount = (item) => {

    const totalMonthly = parseInt(item.monthly_price)*12
    const discount = parseInt(item.yearly_discount)
    const percentage = (discount/totalMonthly) * 100

    return `${percentage}%`
  }

  const handleSignUp = (data) => {

    let type = ""

    if(data === 'Free') {
        type = 'free'
    }
    else {
        type = 'pro'
    }
    
    let query;
    let currentUrlParams = new URLSearchParams(location.search);

    if(currentUrlParams.get("upgrade")) {


      let data = {
            id: myPlan.id,
            plan_id: 3,
            plan_type: yearlyPlan ? "yearly" : "monthly",
            number_of_student: 1
      }

      dispatch(onChangeSubscription(data))
    

    }
    else if(currentUrlParams.get("add")) {
      
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('sid', 4)
      currentUrlParams.set('noc', (parseInt(currentUrlParams.get('noc'))+1))
      navigate( { pathname: "/parent/payment", search: currentUrlParams.toString() }, { replace: true })

    }
    else {
        if(type==="free") {
            query = `?sid=2&plan=${type}`
        }
        else {
            query = `?sid=2&noc=${childrenCount}&plan=${type}&subscription=${yearlyPlan ? "yearly" : "monthly"}`
        }
        navigate({ pathname: "/signup", search: query})
    }

  }

  const handleSlideChange = index => {
    setCurrentSlide(index)
  }

  const getAmount = (item) => {
    const type = item.name
    if(type !== 'Free') {
      if(yearlyPlan) {
        return (parseInt(item.yearly_price)*childrenCount).toFixed(2)
      }
      else {
        return (parseInt(item.monthly_price)*childrenCount).toFixed(2)
      }
    }
    else {
      return parseInt(item.monthly_price).toFixed(2)
    }
  }

  const isFreePlan = () => {
    if(!_.isEmpty(allPlans)) {
      const item = allPlans[currentSlide]
      if(item.name === 'Free') {
        return true
      }
      else {
        return false
      }
    }
    return false
  }

  useEffect(() => {
          
    let currentUrlParams = new URLSearchParams(location.search);
    if(currentUrlParams.get("upgrade") || currentUrlParams.get("add")) {
      setFreePlanDisabled(true)
      setCurrentSlide(1)
    }
    else {
      setFreePlanDisabled(false)
      setCurrentSlide(0)
    }
  }, [location])

  useEffect(() => {
    if(!_.isEmpty(allPlans)) {
      const item = allPlans[currentSlide]
      getAmount(item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  useEffect(() => {
    if(get_plan_success) {
      setMyPlan(myplan[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_plan_success])


  useEffect(() => {
    if(get_change_subscription_success) {
        
      dispatch(clean("CHANGE_SUBSCRIPTION"))

        let data = {
          plan_id: 3,
          plan_type: yearlyPlan ? "yearly" : "monthly",
          number_of_student: 1 //because upgrade will always have only one children
        }
        dispatch(onBuyPlan(data))
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_change_subscription_success])

  useEffect(() => {
    if(get_buyplan_success) {
      
      dispatch(clean("BUY_PLAN"))
      
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('sid', 3)
      currentUrlParams.set('noc', 1)
      currentUrlParams.set('subscription', yearlyPlan ? "yearly" : "monthly")
      navigate( { pathname: "/parent/billing-info", search: currentUrlParams.toString() }, { replace: true })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_buyplan_success])

  useEffect(() => {
    if(get_all_plans_success) {
        setAllPlans(all_plans)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_all_plans_success])

  useEffect(() => {
    
    dispatch(onViewAllPlans())
    let currentUrlParams = new URLSearchParams(location.search);
    if(currentUrlParams.get("upgrade")) {
        dispatch(onViewPlans())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div className='select-plan-wrapper bg-primary-100'>
        { get_all_plans && <Loader /> }
        { get_all_plans_success && 
            <div className="select-plan-container">
                
                <h2 className='text-center text-gray-990 ft-default fw-normal mb-3'>The right plan for your children</h2>
                <h4 className='text-center text-gray-500 ft-default fw-normal mb-4'>Choose plan that works best for you children future</h4>
        
                <div className='choose-term-wrapper d-flex justify-content-center'>
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        
                        <input type="radio" className="btn-check" name="subscriptionType" id="monthlySubscription" autoComplete="off" checked={!yearlyPlan} onChange={() => handleChangePlan('monthly') } />
                        <label className="btn btn-outline-primary" htmlFor="monthlySubscription">Monthly</label>

                        <input type="radio" className="btn-check" name="subscriptionType" id="yearlySubscription" autoComplete="off" checked={yearlyPlan}  onChange={() => handleChangePlan('yearly') } />
                        <label className="btn btn-outline-primary" htmlFor="yearlySubscription">Yearly</label>
                        
                    </div>
                </div>

                <div className='card mt-4'>
                    <div className='card-body p-3 p-md-4'>
                        
                        <div className='children-container'>
                            <p className='text-center text-gray-950 text-base'>Choose the number of children</p>
                            <div className="d-flex m-auto children-box justify-content-center mt-2 border-primary-900 rounded-lg" >
                                <div className={`bg-primary-900 text-white text-base counter-btn d-flex justify-content-center align-items-center ${childrenCount === 1 ? 'disabled' : ''}`} onClick={() => handleChildren('minus')}>
                                    <FontAwesomeIcon icon={['fas', 'fa-minus']} />
                                </div>
                                <div className='value-box d-flex justify-content-center align-items-center flex-grow-1 text-base fw-medium'>{childrenCount}</div>
                                <div className={`bg-primary-900 text-white text-base counter-btn d-flex justify-content-center align-items-center ${isFreePlanDisabled || isFreePlan() ? 'disabled' : ''}`} onClick={() => handleChildren('plus')}>
                                    <FontAwesomeIcon icon={['fas', 'fa-plus']} />
                                </div>
                            </div>
                        </div>

                        <div className='carousel-wrapper mt-4'>
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="carousel-container">
                                      <Carousel showStatus={false} showThumbs={false} showArrows={false} selectedItem={currentSlide} onChange={handleSlideChange}>
                                          { allPlans.map((item, index) => {
                                              return(
                                                  <div key={index} className="plan-container">
                                                      <div className={`plan-box ${isFreePlanDisabled && item.name === 'Free' ? "disabled" : ""}`}>
                                                          <div className='plan-amount text-center'>
                                                              { yearlyPlan && (item.yearly_discount !== '0') && 
                                                              <div className="discount text-gray-950 fw-medium">
                                                                  <div>
                                                                      { getDiscount(item) }
                                                                  </div>
                                                                  <div>save</div>
                                                              </div> }
                                                              <h6 className={`box text-normal ${item.no_of_students === '1' ? "" : "pro"}`}>{item.name}</h6>
                                                              <div className='d-flex justify-content-center align-items-center'>
                                                                  <FontAwesomeIcon className='text-white pound-icon' icon={['fas', 'fa-pound-sign']} />
                                                                  <h2 className='text-white'>{getAmount(item)}</h2>
                                                              </div>
                                                          </div>
                                                          <div className='features text-center py-3'>
                                                              <div className="row">
                                                                  <div className="col-sm-12 col-md-6 offset-md-3">
                                                                      <div className="content d-flex justify-content-center my-3">
                                                                          <FontAwesomeIcon className='tick-icon me-2' icon="fas fa-check-circle" />
                                                                          <p className='text-gray-950 fw-regular text-base'>{item.name}</p>
                                                                      </div>
                                                                      <div className="content d-flex justify-content-center my-3">
                                                                          <FontAwesomeIcon className='tick-icon me-2' icon="fas fa-check-circle" />
                                                                          <p className='text-gray-950 fw-regular text-base'>Videos {item.no_of_students} per chapter</p>
                                                                      </div>
                                                                      <div className='btn btn-primary signup-btn my-3' onClick={() => handleSignUp(item.name)}>{item.name === 'Free' ? "Sign up for free" : "Subscribe Now"}</div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )
                                          })}
                                      </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mt-5 text-center'>
                            <div className=''>
                                <div className='d-inline-block ft-default text-gray-900 text-base me-3 pointer' onClick={showFeatures}>See all features</div>
                                <img src={arrowIcon} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
                <AccountPlanFeaturesModal visible={isFeaturesVisible} 
                    onClose = { () => {setFeaturesVisible(!isFeaturesVisible)} }/>
            </div> }
    </div>
    
  )
}

export default SelectPlan
import {
    VIEW_ASSIGNMENT_LESSON_SUCCESS,
    VIEW_ASSIGNMENT_LESSON_FAILED,
    VIEW_ASSIGNED_ASSIGNMENT_SUCCESS,
    VIEW_ASSIGNED_ASSIGNMENT_FAILED,
    VIEW_ASSIGNMENT_CHAPTER_SUCCESS,
    VIEW_ASSIGNMENT_CHAPTER_FAILED,
    UPDATE_ASSIGNMENT_STATUS_SUCCESS,
    UPDATE_ASSIGNMENT_STATUS_FAILED,
    CREATE_PRACTICE_TEST_SUCCESS,
    CREATE_PRACTICE_TEST_FAILED,
    VIEW_COMPLETED_ASSIGNMENT_SUCCESS,
    VIEW_COMPLETED_ASSIGNMENT_FAILED
} from './actionTypes'

const initialState = {
    error: "",
    loading: false,
    assignment: {},
    assigned_assignment: {},
    status: {},
    chapters: {},
    practice: {},
    completed_assignment: {},
}

const AssignmentReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case VIEW_ASSIGNMENT_LESSON_SUCCESS:{
          const assignment = action.payload
          return {
            ...state,
            loading: false,
            assignment
          }
        }
        case VIEW_ASSIGNMENT_LESSON_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case VIEW_ASSIGNED_ASSIGNMENT_SUCCESS:{
          const assigned_assignment = action.payload
          return {
            ...state,
            loading: false,
            assigned_assignment
          }
        }
        case VIEW_ASSIGNED_ASSIGNMENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case VIEW_ASSIGNMENT_CHAPTER_SUCCESS:{
          const chapters = action.payload
          return {
            ...state,
            loading: false,
            chapters
          }
        }
        case VIEW_ASSIGNMENT_CHAPTER_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case CREATE_PRACTICE_TEST_SUCCESS:{
          const practice = action.payload
          return {
            ...state,
            loading: false,
            practice
          }
        }
        case CREATE_PRACTICE_TEST_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case UPDATE_ASSIGNMENT_STATUS_SUCCESS:{
          const id = action.payload.data.id
          const status = action.payload.data
          return {
            ...state,
            status,
            assigned_assignment: state.assigned_assignment.filter(
              item => parseInt(item.id) !== parseInt(id)
            ),
          }
        }
        case UPDATE_ASSIGNMENT_STATUS_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        case VIEW_COMPLETED_ASSIGNMENT_SUCCESS:{
          const completed_assignment = action.payload
          return {
            ...state,
            loading: false,
            completed_assignment
          }
        }
        case VIEW_COMPLETED_ASSIGNMENT_FAILED: {
          return {
            ...state,
            error: action.payload
          }
        }
        default:
          return state
      }
  
}
    
export default AssignmentReducer
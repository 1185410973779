import React, { useEffect, useState } from 'react'
import { useLocation  } from "react-router-dom"
import { fulfilled } from 'redux-saga-thunk';

import { useDispatch, useSelector } from 'react-redux';

import { viewAssignmentChapter as onViewAssignmentChapter } from '../../../store/assignment/actions';

import moment from 'moment';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);


const ReviewPreSummary = ({ selectedLessonItem, onReviewAnswer, onClose }) => {

    const dispatch = useDispatch()
    const location = useLocation();

    const {chapters, get_chapter_success } = useSelector(state => ({
        chapters: state.AssignmentReducer.chapters,
        get_chapter_success: fulfilled(state, "VIEW_ASSIGNMENT_CHAPTER"),
      }))

    const [ selectedReviewData, setSelectedReviewData ] = useState(null)
    const [ studentScorePercentage, setStudentScorePercentage ] = useState(0)

    const [ chapterId, setChapterId ] = useState(null)
    const [ chapterData, setchapterData ] = useState(null)

    const [ chartData, setChartData ] = useState(
        {
            datasets: [{
                data: [0, 0],
                backgroundColor: ['#4BBB46', '#F73526']
            }]
        }
    )

    const optionsData = {

    }

    const [pluginsData, setPluginsData ] = useState([{
        beforeDraw: function(chart) {
         var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             var fontSize = (height / 160).toFixed(2);
             ctx.font = fontSize + "em sans-serif";
             ctx.textBaseline = "top";
             var text = `${selectedLessonItem.score}/20`,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2;
             ctx.fillText(text, textX, textY);
             ctx.save();
        } 
    }])

    const getQAResult = (data, student_answers, questions, question_index) => {

        let correct_answer_id = parseInt(data.correct_answers[0].id)
        let new_student_answers = []
        student_answers.forEach((item, index) => {
            if(index < questions.length) {
                new_student_answers[index] = item
            }
        })
        if(parseInt(new_student_answers[question_index]).id === correct_answer_id) {
            return 'correct'
        }
        else {
            return 'wrong'
        }
    }


    useEffect(() => {
        
        setSelectedReviewData(selectedLessonItem)
        setChapterId(parseInt(selectedLessonItem?.lesson_activity.chapter_id))
        setStudentScorePercentage((selectedLessonItem.score/20)*100)

        let currentUrlParams = new URLSearchParams(location.search);
        let data = {
            student_id: currentUrlParams.get('sid')
        }
        dispatch(onViewAssignmentChapter(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setChartData(
            {
                datasets: [{
                    data: [(studentScorePercentage-100), studentScorePercentage],
                    backgroundColor: ['#F73526', '#4BBB46']
                }]
            }
        )
    }, [studentScorePercentage])


    useEffect(() => {
        if(get_chapter_success) {
            let data = chapters.filter(item => {
                return parseInt(item.id) === chapterId
            })
            setchapterData(data[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_chapter_success])

    return (
      <div className='review-pre-summary-wrapper'>
          <div className='paper-container'>
                <div className='content'>
                    <div className='stepper-wrapper'>
                        <div className='stepper-box d-flex justify-content-center align-items-center'>
                            { selectedReviewData && selectedReviewData?.lesson_activity.questions_data.map((item, index) => {
                                return(
                                    <div className={`circle d-flex justify-content-center align-items-center fw-medium ft-default ${getQAResult(item, selectedReviewData.student_answers, selectedReviewData?.lesson_activity.questions_data, index)}`}>{index+1}</div>
                                )
                            })}
                        </div>
                        <div className='close-btn text-base fw-medium ft-default pointer' onClick={onClose}>Close</div>
                    </div>
                    <div className='results-box'>
                        <div className='inner'>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-4'>
                                    <div className='text-center'>
                                        <Doughnut 
                                            data={chartData}
                                            options = {optionsData}
                                            plugins={pluginsData}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-12 col-lg-8'>
                                    <div className='ft-default text-h6 ms-3 summary-box'>
                                        <div className='d-flex mb-2'>
                                            <div className='fw-semibold me-2 w-50'>Completion date:</div>
                                            <div className=''>{moment(selectedReviewData?.to_do_date).format('D MMM YYYY')}</div>
                                        </div>
                                        <div className='d-flex mb-2'>
                                            <div className='fw-semibold me-2 w-50'>Chapter name:</div>
                                            <div className=''>{chapterData?.number}. {chapterData?.name}</div>
                                        </div>
                                        <div className='d-flex mb-2'>
                                            <div className='fw-semibold me-2 w-50'>Lesson activity:</div>
                                            <div className=''>{selectedReviewData?.lesson_activity.number}. {selectedReviewData?.lesson_activity.name}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='fw-semibold me-2 w-50'>Question:</div>
                                            <div className=''>{selectedReviewData?.lesson_activity.questions_data.length}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='review-ans-wrapper text-center mt-4'>
                            <div className='review-ans-btn btn btn-primary text-base' onClick={() => onReviewAnswer(true)}>Review answer</div>
                        </div>
                    </div>
                </div>
           </div>
      </div>
    )
}
  
export default ReviewPreSummary

export const BUY_PLAN = "BUY_PLAN"
export const BUY_PLAN_SUCCESS = "BUY_PLAN_SUCCESS"
export const BUY_PLAN_FAILED = "BUY_PLAN_FAILED"

export const VIEW_ALL_PLANS = "VIEW_ALL_PLANS"
export const VIEW_ALL_PLANS_SUCCESS = "VIEW_ALL_PLANS_SUCCESS"
export const VIEW_ALL_PLANS_FAILED = "VIEW_ALL_PLANS_FAILED"

export const VIEW_PLANS = "VIEW_PLANS"
export const VIEW_PLANS_SUCCESS = "VIEW_PLANS_SUCCESS"
export const VIEW_PLANS_FAILED = "VIEW_PLANS_FAILED"

export const VIEW_MY_SUBSCRIPTIONS = "VIEW_MY_SUBSCRIPTIONS"
export const VIEW_MY_SUBSCRIPTIONS_SUCCESS = "VIEW_MY_SUBSCRIPTIONS_SUCCESS"
export const VIEW_MY_SUBSCRIPTIONS_FAILED = "VIEW_MY_SUBSCRIPTIONS_FAILED"

export const UPDATE_RENEWAL = "UPDATE_RENEWAL"
export const UPDATE_RENEWAL_SUCCESS = "UPDATE_RENEWAL_SUCCESS"
export const UPDATE_RENEWAL_FAILED = "UPDATE_RENEWAL_FAILED"

export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION"
export const CHANGE_SUBSCRIPTION_SUCCESS = "CHANGE_SUBSCRIPTION_SUCCESS"
export const CHANGE_SUBSCRIPTION_FAILED = "CHANGE_SUBSCRIPTION_FAILED"

export const BILLING_DETAILS_SUBMIT = "BILLING_DETAILS_SUBMIT"
export const BILLING_DETAILS_SUBMIT_SUCCESS = "BILLING_DETAILS_SUBMIT_SUCCESS"
export const BILLING_DETAILS_SUBMIT_FAILED = "BILLING_DETAILS_SUBMIT_FAILED"

export const MAKE_PAYMENT = "MAKE_PAYMENT"
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS"
export const MAKE_PAYMENT_FAILED = "MAKE_PAYMENT_FAILED"

export const APPLY_COUPON = "APPLY_COUPON"
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS"
export const APPLY_COUPON_FAILED = "APPLY_COUPON_FAILED"

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED"

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD"
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS"
export const ADD_PAYMENT_METHOD_FAILED = "ADD_PAYMENT_METHOD_FAILED"

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD"
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED"

export const VIEW_CARD_DETAILS = "VIEW_CARD_DETAILS"
export const VIEW_CARD_DETAILS_SUCCESS = "VIEW_CARD_DETAILS_SUCCESS"
export const VIEW_CARD_DETAILS_FAILED = "VIEW_CARD_DETAILS_FAILED"

export const DELETE_CARD_DETAILS = "DELETE_CARD_DETAILS"
export const DELETE_CARD_DETAILS_SUCCESS = "DELETE_CARD_DETAILS_SUCCESS"
export const DELETE_CARD_DETAILS_FAILED = "DELETE_CARD_DETAILS_FAILED"
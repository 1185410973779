import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import blockBg from "../../../assets/images/signin-block-bg.png"
import logo from "../../../assets/images/logo-big.png"
import checkImg from "../../../assets/images/welcome-check-img.png"

import { useFormik } from 'formik'
import { Alert } from "reactstrap"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean, rejected } from 'redux-saga-thunk';

import { resetPasswordEmail as onResetPasswordEmail } from '../../../store/auth/request-password/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const ResetPassword = props => {

    const dispatch = useDispatch()
    const location = useLocation();

    const [ showNewPassword, setShowNewPassword ] = useState(false)
    const [ showNewConfirmPassword, setShowNewConfirmPassword ] = useState(false)
    const [ isPasswordReset, setPasswordReset ] = useState(false)

    const { error, get_success, get_error  } = useSelector(state => ({
        error: state.RequestPasswordReducer.error,
        get_success: fulfilled(state, "RESET_PASSWORD_EMAIL"),
        get_error: rejected(state, "RESET_PASSWORD_EMAIL")
    }))

    const initialValues = {
        password: "",
        password_confirmation: ""
    }

    const onSubmit = values => {

        let currentUrlParams = new URLSearchParams(location.search);
        let urlData = location.pathname.split('/');
        let token = urlData[urlData.length-1]

        let data = {
            email: currentUrlParams.get('email'),
            token: token,
            ...values
        }
        dispatch(onResetPasswordEmail(data))
    }

    const validate = values => {
        
        let errors = {}
        
        if(!values.password) {
            errors.password = "Password is required!"
        }
        else if(values.password.length < 6) {
            errors.password = "Password should contain minimum 6 characters!"
        }

        if(!values.password_confirmation) {
            errors.password_confirmation = "New Password is required!"
        }
        else if(values.password !== values.password_confirmation) {
            errors.password_confirmation = "Password did not match!"
        }

        return errors
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    useEffect(() => {
        setPasswordReset(false)
    }, [])

    useEffect(() => {
        if(get_success) {
            dispatch(clean("RESET_PASSWORD_EMAIL"))
            setPasswordReset(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_success])

    useEffect(() => {
        if(get_error) {
            dispatch(clean("RESET_PASSWORD_EMAIL"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_error])

  return (
    <div className="reset-password-wrapper d-inline-block w-100">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="logo-container d-none d-lg-flex">
                        <img src={logo} alt="" />
                    </div>
                    <div className="content-container d-flex w-100">
                        <div className="left d-none d-lg-block">
                            <img className="img-fluid block-img" src={blockBg} alt="" />
                        </div>
                        <div className="right">
                            <div className="row">
                                <div className="col-12 col-sm-10 offset-sm-1 d-lg-none">
                                    <div className="logo-container position-static">
                                        <img src={logo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 offset-lg-2 offset-sm-1">
                                    <div className="form-container">
                                        <h2 className="text-gray-980 fw-semibold ls--2 mb-4">Reset Password</h2>
                                        {(error && get_error) && ( <Alert color="danger">{error}</Alert>)}
                                        { !isPasswordReset ? 
                                        <>
                                            <form name="requestPasswordForm" onSubmit={formik.handleSubmit}>
                                                <div className='input-box auth-box'>
                                                    <label htmlFor="password" className='mb-3'>New Password</label>
                                                    <div className="input-group">
                                                        <input className='form-control' type={ showNewPassword ? 'text' : 'password' } id="password" name="password" placeholder='************' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} />
                                                        { (formik.touched.password && !formik.errors.password) &&  
                                                            <span className='input-group-text'>
                                                                <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                                                <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowNewPassword(!showNewPassword)}} />
                                                            </span>
                                                        }
                                                    </div>
                                                    { formik.touched.password && <p className="mt-1 text-sm text-red-500">{formik.errors.password}</p> }
                                                </div>
                                                <div className='input-box auth-box'>
                                                    <label htmlFor="password_confirmation" className='mb-3'>Confirm Password</label>
                                                    <div className="input-group">
                                                        <input className='form-control' type={ showNewConfirmPassword ? 'text' : 'password' } id="password_confirmation" name="password_confirmation" placeholder='************' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.confirm_new_password} />
                                                        { (formik.touched.password_confirmation && !formik.errors.password_confirmation) &&  
                                                            <span className='input-group-text'>
                                                                <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                                                <FontAwesomeIcon className='pointer text-sm text-primary-950 ms-3' icon={`fa-solid ${showNewConfirmPassword ? "fa-eye-slash" : "fa-eye"}`} onClick={() => {setShowNewConfirmPassword(!showNewConfirmPassword)}} />
                                                            </span>
                                                        }
                                                    </div>
                                                    { formik.touched.password_confirmation && <p className="mt-1 text-sm text-red-500">{formik.errors.password_confirmation}</p> }
                                                </div>
                                                <div className="mt-5">
                                                    <button className="btn btn-special w-100 text-uppercase ls--15">Reset</button>
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                        <div className='success d-flex'>
                                            <img className='flex-shrink-0' src={checkImg} alt="" />
                                            <h6 className='ms-3 lh-small'>password has been reset successfully</h6>
                                        </div>
                                        </>
                                        }
                                        <div className="mt-5 text-center">
                                            <span className="ft-head text-sm ls--2">Go back to</span> <Link to="/sign-in" className="text-blue-500 fw-medium ft-head">Sign In</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword

const signupFreeWidget = [
    { id: 1, name: 'Plan', path: 'select-plan' },
    { id: 2, name: 'Sign Up', path: 'signup' },
    { id: 3, name: 'Add Student', path: 'add-student' },
    { id: 4, name: 'Welcome to Sqillup', path: 'welcome' }
]

const signupProWidget = [
    { id: 1, name: 'Plan', path: 'select-plan' },
    { id: 2, name: 'Sign Up', path: 'signup' },
    { id: 3, name: 'Billing Info', path: 'billing-info' },
    { id: 4, name: 'Payment', path: 'payment' },
    { id: 5, name: 'Add Student', path: 'add-student' },
    { id: 6, name: 'Welcome to Sqillup', path: 'welcome' }
]

const upgradeWidget = [
    { id: 1, name: 'Plan', path: 'select-plan' },
    { id: 2, name: 'Sign Up', path: 'signup' },
    { id: 3, name: 'Billing Info', path: 'billing-info' },
    { id: 4, name: 'Payment', path: 'payment' },
    { id: 5, name: 'Welcome to Sqillup', path: 'welcome' }
]

export {
    signupFreeWidget,
    signupProWidget,
    upgradeWidget
}
import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap"
import { useSelector } from 'react-redux';

const DeleteStudentConfirmModal = ({ visible, onClose, onDelete }) => {

  const [ planDetails, setplanDetails ] = useState({})

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  useEffect(() => {
    if(visible)
      setplanDetails(user.plans[0])
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal isOpen={visible} toggle={onClose} size="lg">
        <ModalBody>
          <div className='content delete-student-account'>
            <h4 className='text-primary-800 fw-regular ft-default mb-4'>Delete Account ?</h4>
            <p className='text-gray-700 text-h5'>You are subscribe to <span className='text-lowercase'>{planDetails.plan?.name}</span> with two student annual plan, if you will delete the student you will not getting any refund amount. And price Will be automatically upgrade to normal package.</p>
            <div className='mt-5'>
              <div className='btn btn-secondary submit-btn me-4' onClick={onClose}>Cancel</div>
              <button className='btn btn-primary submit-btn' onClick={onDelete}>Yes</button>
            </div>
          </div>
        </ModalBody>
    </Modal>
  )
}

export default DeleteStudentConfirmModal
import { Navigate } from "react-router-dom"


//Public components
import SignIn from "../pages/Public/SignIn/SignIn"
import SelectPlan from "../pages/Public/CreateAccount/SelectPlan/SelectPlan"
import SignUp from "../pages/Public/CreateAccount/SignUp/SignUp"
import RequestPassword from "../pages/Public/RequestPassword/RequestPassword"
import ResetPassword from "../pages/Public/ResetPassword/ResetPassword"

//user components
import ContactUs  from '../pages/ContactUs/ContactUs'
import Dashboard from "../pages/Dashboard/Dashboard"
import Help from "../pages/Help/Help"
import Activity from "../pages/Activity/Activity"
import StudentProfile from "../pages/StudentProfile/StudentProfile"
import ProfileSettings from "../pages/ProfileSettings/ProfileSettings"
import MyProfile from "../pages/MyProfile/MyProfile"

import Growth from "../pages/Growth/Growth"
import GrowthScore from "../pages/Growth/GrowthScore/GrowthScore"
import GrowthReward from "../pages/Growth/GrowthReward/GrowthReward"
import GrowthBadges from "../pages/Growth/GrowthBadges/GrowthBadges"

import AddStudent from "../pages/Public/CreateAccount/AddStudent/AddStudent"
import BillingInfo from "../pages/Public/CreateAccount/BillingInfo/BillingInfo"
import Payment from "../pages/Public/CreateAccount/Payment/Payment"
import PaymentSuccess from "../pages/Public/CreateAccount/Payment/PaymentSuccess"

import WelcomeUser from "../pages/Public/CreateAccount/WelcomeUser/WelcomeUser"
import Notifications from "../pages/Notifications/Notifications"

import PageNotFound from "../pages/PageNotFound/PageNotFound"

const publicRoutes = [
    { path: 'sign-in', component: SignIn  },
    { path: 'request-password', component: RequestPassword  },
    { path: 'resetpassword/:token', component: ResetPassword  },
    { path: 'page-not-found', component: PageNotFound },
    { path: "/", exact: true, component: () => <Navigate to="/sign-in" /> },
    { path: "*", exact: true, component: () => <Navigate to="/page-not-found" /> }
]

const setupRoutes = [
    { path: 'select-plan', component: SelectPlan },
    { path: 'parent/select-plan', component: SelectPlan },
    { path: 'signup', component: SignUp },
    { path: 'email-verify', component: SignUp },
    { path: 'parent/add-student', component: AddStudent },
    { path: 'parent/billing-info', component: BillingInfo },
    { path: 'parent/payment', component: Payment },
    { path: 'parent/payment-success', component: PaymentSuccess },
    { path: 'parent/welcome', component: WelcomeUser }
]

const userRoutes = [
    { path: 'parent/dashboard', component: Dashboard },
    { path: 'parent/student-profile', component: StudentProfile },
    { path: 'parent/growth', component: Growth },
    { path: 'parent/activity', component: Activity },
    { path: 'parent/contact', component: ContactUs },
    { path: 'parent/help', component: Help },
    { path: 'parent/my-profile', component: MyProfile },
    { path: 'parent/settings', component: ProfileSettings },
    { path: 'parent/notifications', component: Notifications }
]

const growthRoutes = [
    { path: 'parent/growth/score', component: GrowthScore },
    { path: 'parent/growth/reward', component: GrowthReward },
    { path: 'parent/growth/badges', component: GrowthBadges },
]

const restrictedRoutesAfterSignedIn = [
    { path: '/signup', component: SignUp },
    { path: '/sign-in', component: SignIn  },
    { path: '/request-password', component: RequestPassword  },
    { path: '/resetpassword/:token', component: ResetPassword  },
]


export { publicRoutes, userRoutes, setupRoutes, growthRoutes, restrictedRoutesAfterSignedIn }
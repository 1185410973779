import {
    VIEW_BADGES_SUCCESS,
    VIEW_BADGES_FAILED,
    VIEW_BADGES_TYPE_SUCCESS,
    VIEW_BADGES_TYPE_FAILED,
    CREATE_BADGE_SUCCESS,
    CREATE_BADGE_FAILED,
    UPDATE_BADGE_SUCCESS,
    UPDATE_BADGE_FAILED,
    DELETE_BADGE_SUCCESS,
    DELETE_BADGE_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    badges: null,
    badges_type: null
}

const BadgesReducer = (state = INIT_STATE, action) => {

    switch (action.type) {

      case VIEW_BADGES_SUCCESS: {
        return {
          ...state,
          loading: false,
          badges: action.payload,
          error: "",
        }
      }
      case VIEW_BADGES_FAILED: {
        return {
          ...state,
          loading: false,
          badges: null,
          error: action.payload
        }
      }
      case VIEW_BADGES_TYPE_SUCCESS: {
        return {
          ...state,
          loading: false,
          badges_type: action.payload,
          error: "",
        }
      }
      case VIEW_BADGES_TYPE_FAILED: {
        return {
          ...state,
          loading: false,
          badges_type: null,
          error: action.payload
        }
      }
      case CREATE_BADGE_SUCCESS: {
        return {
          ...state,
          error: "",
        }
      }
      case CREATE_BADGE_FAILED: {
        return {
          ...state,
          loading: false,
          badges: null,
          error: action.payload
        }
      }
      case UPDATE_BADGE_SUCCESS: {
        const data = action.payload.data
        const updatedItem = state.badges.find(obj => obj.id === data.id)
        Object.keys(data).forEach(function(key) {
            updatedItem[key] = data[key];
        })
        return {
          ...state,
          loading: false,
          badges: state.badges.map(u => u.id !== updatedItem.id ? u : updatedItem),
          error: "",
        }
      }
      case UPDATE_BADGE_FAILED: {
        return {
          ...state,
          loading: false,
          badges: null,
          error: action.payload
        }
      }
      case DELETE_BADGE_SUCCESS: {
        const id = action.payload.data.id
        return {
          ...state,
          loading: false,
          badges: state.badges.filter(
            item => item.id.toString() !== id.toString()
          ),
          error: "",
        }
      }
      case DELETE_BADGE_FAILED: {
        return {
          ...state,
          error: action.payload
        }
      }
      default:
        return state
    }

}

export default BadgesReducer;
import { call, put, takeEvery } from "redux-saga/effects"

import { ADD_CARD, VIEW_CARD, UPDATE_CARD ,DELETE_CARD } from "./actionTypes"
import { viewCardSuccess, viewCardFailed, updateCardSuccess, updateCardFailed ,deleteCardSuccess, deleteCardFailed } from "./actions"

import { addCardApi ,viewCardApi, updateCardApi ,deleteCardApi } from '../../helpers/api/api'

function* addCard({ payload: card, meta }) {
    try {
        const response = yield call(addCardApi, card)
        yield put(viewCardSuccess(response, meta))
    }
    catch(error) {
        if(error.response) {
            const {message}=error.response?.data
            yield put(viewCardFailed(message, meta))
        }
    }
    
}

function* viewCard({ payload: card, meta }) {
    try {
        const response = yield call(viewCardApi, card)
        yield put(viewCardSuccess(response.data, meta))
    }
    catch(error) {
        if(error.response) {
            const {message}=error.response?.data
            yield put(viewCardFailed(message, meta))
        }
    }
    
}

function* updateCard({ payload: data, meta }) {
    try {
        const response = yield call(updateCardApi,data)
        yield put(updateCardSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(updateCardFailed(message, meta))
    }
    
}

function* deleteCard({ payload: data, meta }) {
    try {
        const response = yield call(deleteCardApi,data)
        yield put(deleteCardSuccess({response, data}, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(deleteCardFailed(message, meta))
    }
    
}

function* CardSaga() {
    yield takeEvery(ADD_CARD, addCard)
    yield takeEvery(VIEW_CARD, viewCard)
    yield takeEvery(UPDATE_CARD, updateCard)
    yield takeEvery(DELETE_CARD, deleteCard)
}
  
  export default CardSaga
export const ADD_CARD = "ADD_CARD"
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS"
export const ADD_CARD_FAILED = "ADD_CARD_FAILED"

export const VIEW_CARD = "VIEW_CARD"
export const VIEW_CARD_SUCCESS = "VIEW_CARD_SUCCESS"
export const VIEW_CARD_FAILED = "VIEW_CARD_FAILED"

export const UPDATE_CARD = "UPDATE_CARD"
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS"
export const UPDATE_CARD_FAILED = "UPDATE_CARD_FAILED"

export const DELETE_CARD = "DELETE_CARD"
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS"
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED"
import { takeEvery, put, call } from "redux-saga/effects"

import { VIEW_LESSON_ACTIVITY_RESULT } from './actionTypes'
import { viewLessonActivityResultSuccess, viewLessonActivityResultFailed } from './actions'

import { viewLessonActivityResultApi } from "../../helpers/api/api"

function* viewLessonActivityResult({ meta }) {
    try {
        const response = yield call(viewLessonActivityResultApi)
        yield put(viewLessonActivityResultSuccess(response, meta))
    }
    catch(error) {
        const {message}=error.response.data
        yield put(viewLessonActivityResultFailed(message, meta))
    }
}


function* LessonActivitySaga() {
    yield takeEvery(VIEW_LESSON_ACTIVITY_RESULT, viewLessonActivityResult)
}

export default LessonActivitySaga
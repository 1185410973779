export const VIEW_BADGES = "VIEW_BADGES"
export const VIEW_BADGES_SUCCESS = "VIEW_BADGES_SUCCESS"
export const VIEW_BADGES_FAILED = "VIEW_BADGES_FAILED"

export const VIEW_BADGES_TYPE = "VIEW_BADGES_TYPE"
export const VIEW_BADGES_TYPE_SUCCESS = "VIEW_BADGES_TYPE_SUCCESS"
export const VIEW_BADGES_TYPE_FAILED = "VIEW_BADGES_TYPE_FAILED"

export const CREATE_BADGE = "CREATE_BADGE"
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCCESS"
export const CREATE_BADGE_FAILED = "CREATE_BADGE_FAILED"

export const UPDATE_BADGE = "UPDATE_BADGE"
export const UPDATE_BADGE_SUCCESS = "UPDATE_BADGE_SUCCESS"
export const UPDATE_BADGE_FAILED = "UPDATE_BADGE_FAILED"

export const DELETE_BADGE = "DELETE_BADGE"
export const DELETE_BADGE_SUCCESS = "DELETE_BADGE_SUCCESS"
export const DELETE_BADGE_FAILED = "DELETE_BADGE_FAILED"
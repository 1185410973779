import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { fulfilled } from "redux-saga-thunk"

import { viewNotifications as onViewNotifications } from '../../../store/notifications/actions'
import notificationIcon from "../../../assets/images/notification-icon.svg"

import _ from 'underscore'

const HeaderNotification = props => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ availableNotifications, setavailableNotifications ] = useState([])

  const { notifications, get_success  } = useSelector(state => ({
    notifications: state.NotificationsReducer.notifications,
    get_success: fulfilled(state, "VIEW_NOTIFICATIONS")
  }))

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)

  /*const getUnreadNotificationsCount = () => {
    let count = 0
    notifications.forEach((item, index) => {
      if(item.read !== 'true'){
        count++
      }
    })
    return count
  }*/

  const goTo = () => {
    setDropDownExpanded(false)
    navigate("/parent/notifications")
  }

  const handleNotificationDropdown = () => {
    if(_.isEmpty(availableNotifications)) {
      navigate("/parent/notifications")
    }
    else {
      setDropDownExpanded(true)
    }
  }

  useEffect(() => {
    
    const elem = document.querySelector(".notify-dropdown");
    document.addEventListener("mousedown", (event) => {
      if (elem.contains(event.target)) {
        setDropDownExpanded(true)
      } else {
        setDropDownExpanded(false)
      }
    });
    let data = {
      target: 'parent'
    }
    dispatch(onViewNotifications(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_success) {
      let newData = notifications.filter((item, index) => {
        return item.read === 'false'
      })
      setavailableNotifications(newData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {

  }, [availableNotifications])

  return (
    <div className='header-notification-wrapper'>
      <div className='notify-box' onClick={handleNotificationDropdown}>
      { get_success && !_.isEmpty(availableNotifications) && <div className='badge text-xs text-primary-600'>{availableNotifications.length}</div> }
        <img src={notificationIcon} alt="" />
      </div>
      <div className={`notify-dropdown ${ isDropDownExpanded ? 'd-block' : 'd-none'}`}>
        <div className='arrow'></div>
          <ul className='list-group'>

          { get_success && availableNotifications.slice(0, 2).map((item, index) => (
              <li key={index} className='list-group-item d-flex'>
                <div className={`name flex-shrink-0`}>
                  <img className='img-full' src={item.image} alt="" />
                </div>
                <div className='content ms-3'>
                  <p className={`text-sm mb-1 ${item.read === 'true' ? "" : "fw-semibold"}`}>{item.title}</p>
                  <p className={`text-xs ${item.read === 'true' ? "" : "fw-medium"}`}>{item.description}</p>
                </div>
              </li>
            ))
          }
          </ul>
          { !_.isEmpty(availableNotifications) && <div className="d-flex">
            <div className="ms-auto text-primary-800 text-sm fw-semibold text-end m-3 pointer" onClick={goTo}>View All</div>
          </div> }
      </div>
    </div>
  )
}

export default HeaderNotification
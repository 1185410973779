import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import AccountStepper from '../AccountStepper/AccountStepper'
import { useSelector } from 'react-redux';

import checkImg from "../../../../assets/images/welcome-check-img.png"


const WelcomeUser = props => {

  const navigate = useNavigate()
  const location = useLocation();

  const [ isUpgrade, setUpgrade ] = useState(false)

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  useEffect(() => {
    if(location.pathname.includes("welcome")) {
      setTimeout(()=> {
        navigate({ pathname: '/parent/dashboard' })
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  useEffect(() => {
          
    let currentUrlParams = new URLSearchParams(location.search);
    if(currentUrlParams.get("upgrade")) {
        setUpgrade(true)
    }
    else {
        setUpgrade(false)
    }
  }, [location])

  return (
    <div className='setup-confirmation-wrapper'>
        <AccountStepper />
        <div className='welcome-box bg-primary-100 w-100 flex-column d-flex justify-content-center align-items-center'>
            <img src={checkImg} alt="" />
            <div className='message text-center'>
                <h4 className='text-gray-950 title'>Success</h4>
                <p className='text-base text-gray-400'>Woo hoo! {user.full_name}. You have Successfully {isUpgrade ? "upgraded" : "added"}  student.</p>
            </div>
        </div>
    </div>
  )
}

export default WelcomeUser
export const VIEW_ASSIGNMENT_LESSON = "VIEW_ASSIGNMENT_LESSON"
export const VIEW_ASSIGNMENT_LESSON_SUCCESS = "VIEW_ASSIGNMENT_LESSON_SUCCESS"
export const VIEW_ASSIGNMENT_LESSON_FAILED = "VIEW_ASSIGNMENT_LESSON_FAILED"

export const VIEW_ASSIGNED_ASSIGNMENT = "VIEW_ASSIGNED_ASSIGNMENT"
export const VIEW_ASSIGNED_ASSIGNMENT_SUCCESS = "VIEW_ASSIGNED_ASSIGNMENT_SUCCESS"
export const VIEW_ASSIGNED_ASSIGNMENT_FAILED = "VIEW_ASSIGNED_ASSIGNMENT_FAILED"

export const VIEW_ASSIGNMENT_CHAPTER = "VIEW_ASSIGNMENT_CHAPTER"
export const VIEW_ASSIGNMENT_CHAPTER_SUCCESS = "VIEW_ASSIGNMENT_CHAPTER_SUCCESS"
export const VIEW_ASSIGNMENT_CHAPTER_FAILED = "VIEW_ASSIGNMENT_CHAPTER_FAILED"

export const UPDATE_ASSIGNMENT_STATUS = "UPDATE_ASSIGNMENT_STATUS"
export const UPDATE_ASSIGNMENT_STATUS_SUCCESS = "UPDATE_ASSIGNMENT_STATUS_SUCCESS"
export const UPDATE_ASSIGNMENT_STATUS_FAILED = "UPDATE_ASSIGNMENT_STATUS_FAILED"

export const CREATE_PRACTICE_TEST = "CREATE_PRACTICE_TEST"
export const CREATE_PRACTICE_TEST_SUCCESS = "CREATE_PRACTICE_TEST_SUCCESS"
export const CREATE_PRACTICE_TEST_FAILED = "CREATE_PRACTICE_TEST_FAILED"

export const DELETE_COMPLETED_ASSIGNMENT = "DELETE_COMPLETED_ASSIGNMENT"
export const DELETE_COMPLETED_ASSIGNMENT_SUCCESS = "DELETE_COMPLETED_ASSIGNMENT_SUCCESS"
export const DELETE_COMPLETED_ASSIGNMENT_FAILED = "DELETE_COMPLETED_ASSIGNMENT_FAILED"

export const VIEW_COMPLETED_ASSIGNMENT = "VIEW_COMPLETED_ASSIGNMENT"
export const VIEW_COMPLETED_ASSIGNMENT_SUCCESS = "VIEW_COMPLETED_ASSIGNMENT_SUCCESS"
export const VIEW_COMPLETED_ASSIGNMENT_FAILED = "VIEW_COMPLETED_ASSIGNMENT_FAILED"
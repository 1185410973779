import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pending, fulfilled } from "redux-saga-thunk"

import NotificationSetting from './NotificationSetting'
import Loader from '../../components/Common/Loader';
import { viewNotifications as onViewNotifications, updateNotification as onUpdateNotification } from '../../store/notifications/actions'


const Notifications = props => {

  const dispatch = useDispatch()

  const [ availableNotifications, setavailableNotifications ] = useState([])

  const { notifications, get_notify, get_notify_success  } = useSelector(state => ({
    notifications: state.NotificationsReducer.notifications,
    get_notify: pending(state, "VIEW_NOTIFICATIONS"),
    get_notify_success: fulfilled(state, "VIEW_NOTIFICATIONS")
  }))

  const handleUnreadNotifications = (item) => {
    if(item.read !== 'true') {
      let data = {
        notification_id: item.id,
        read: "true"
      }
      dispatch(onUpdateNotification(data))
    }
  }

  useEffect(() => {
    let data = {
      target: 'parent'
    }
    dispatch(onViewNotifications(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(get_notify_success) {
      setavailableNotifications(notifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_notify_success])

  return (
    <div className='notification-wrapper'>
        { get_notify && <Loader /> }
        { get_notify_success && 
          <>
            <h5 className='title'>Notification</h5>
            <div className='content-box'>
                <div className='row'>
                  <div className='col-md-12 col-lg-8'>
                    <div className='card h-100 shadow'>
                      <div className='card-body p-0'>
                      <ul className='list-group'>

                        { get_notify_success && availableNotifications.map((item, index) => (
                          <li key={index} className={`list-group-item ${item.read === 'true' ? "read" : "unread"}`} onClick={() => (handleUnreadNotifications(item))}>
                            <div className='row'>
                              <div className='col-md-8 col-lg-9 d-flex'>
                              <div className={`name flex-shrink-0`}>
                                <img className='img-full' src={item.image} alt="" />
                              </div>
                                <div className='content ms-3'>
                                  <p className={`text-gray-920 text-base fw-medium ${item.read === 'true' ? "" : "fw-semibold"}`}>{item.title}</p>
                                  <p className={`mt-2 text-normal text-gray-920 ${item.read === 'true' ? "" : "fw-medium"}`}>{item.description}</p>
                                </div>
                              </div>
                              <div className='col-md-4 col-lg-3'>
                                <p className={`ms-auto time mt-2 text-sm text-gray-700 text-end ${item.read === 'true' ? "" : "fw-semibold"}`}>{item.created_at}</p>
                              </div>
                            </div>
                          </li>
                        ))}

                      </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 col-lg-4'>
                    <NotificationSetting />
                  </div>
                </div>
            </div>
          </>
        }
    </div>
  )
}

export default Notifications
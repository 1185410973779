import {
    GET_EXAM_BOARDS,
    GET_EXAM_BOARDS_SUCCESS,
    GET_EXAM_BOARDS_FAILED
} from './actionTypes';

export const getExamBoards = () => {
    return {
        type: GET_EXAM_BOARDS,
        meta:{
            thunk:true
        }
    }
}
  
export const getExamBoardsSuccess = (keyStages, { thunk }) => ({
    type: GET_EXAM_BOARDS_SUCCESS,
    payload: keyStages,
    meta: {
        thunk
    }
})
  
export const getExamBoardsFailed = (error, { thunk }) => ({
    type: GET_EXAM_BOARDS_FAILED,
    payload: error,
    meta: {
        thunk
    }
})
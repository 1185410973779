import React, { useState, useEffect } from 'react'
import { useLocation  } from "react-router-dom"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, clean } from 'redux-saga-thunk';
import { Tooltip } from "reactstrap"

import { Modal, ModalBody } from "reactstrap"
import { useFormik } from 'formik'

import closeIcon from "../../../assets/images/modal-close-icon.svg"
import SelectInputOverlap from '../../../components/Common/SelectInputOverlap';
import { rewardPointsData as reward_points } from '../../../constants/variousdata';

import { viewSubject as onViewSubject } from '../../../store/subject/actions';
import { viewRewardType as onViewRewardType } from '../../../store/reward-type/actions';
import { viewRewardCategory as onViewRewardCategory } from '../../../store/reward/actions';
import { createReward as onCreateReward } from '../../../store/reward/actions';
import { updateReward as onUpdateReward } from '../../../store/reward/actions';
import { viewReward as onViewReward } from '../../../store/reward/actions';

import { showToastMessage } from '../../../helpers/toaster'

import _ from 'underscore'


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const CreateRewardModal = ({ visible, isEdit, reward, onClose, onDelete }) => {

    const dispatch = useDispatch()
    const location = useLocation();

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [selectedReward, setSelectedReward] = useState({});

    const { subject, reward_type, reward_category, get_subject_success, get_reward_category_success, get_reward_type_success, get_update_success, get_create_success } = useSelector(state => ({
        subject: state.SubjectReducer.subject,
        reward_type: state.RewardTypeReducer.reward_type,
        reward_category: state.RewardReducer.reward_category,
        get_subject_success: fulfilled(state, "VIEW_SUBJECT"),
        get_reward_category_success: fulfilled(state, "VIEW_REWARD_CATEGORY"),
        get_reward_type_success: fulfilled(state, "VIEW_REWARD_TYPE"),
        get_create_success: fulfilled(state, "CREATE_REWARD"),
        get_update_success: fulfilled(state, "UPDATE_REWARD")
    }))

    const initialValues = {
        subject_id: "",
        reward_cat_id: "",
        reward_typ_id: "",
        description: "",
        points: ""
    }

    const onSubmit = values => {
        if(isEdit) {
            values['id'] = selectedReward.id
            values['user_id'] = selectedReward.user_id
            dispatch(onUpdateReward(values))
        }
        else {
            let currentUrlParams = new URLSearchParams(location.search);
            values['user_id'] = currentUrlParams.get('sid')
            dispatch(onCreateReward(values))
        }
    }

    const validate = values => {

        let errors = {}
    
        if(!values.subject_id) {
          errors.subject_id = "Subject required!"
        }

        if(!values.reward_cat_id) {
            errors.reward_cat_id = "Category required!"
        }

        if(!values.reward_typ_id) {
            errors.reward_typ_id = "Type required!"
        }

        if(!values.description) {
            errors.description = "Description required!"
        }

        if(!values.points) {
            errors.points = "Points required!"
        }
        
        return errors
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    }) 

    const handleOnSelectSubject = (id) => {
        formik.setFieldValue("subject_id", id)
    }

    const handleOnSelectCategory = (id) => {
        formik.setFieldValue("reward_cat_id", id)
    }

    const handleOnSelectType = (id) => {
        formik.setFieldValue("reward_typ_id", id)
    }

    const handleOnSelectPoints = (id) => {
        formik.setFieldValue("points", id)
    }

    const handleDeleteReward = () => {
        onDelete()
    }

    useEffect(() => {
        dispatch(onViewSubject())
        dispatch(onViewRewardType())
        dispatch(onViewRewardCategory())
        formik.resetForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(visible) {
            formik.resetForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])


    useEffect(() => {
        if(isEdit) {
            setSelectedReward(reward)
        }
        else {
            setSelectedReward({})            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit])

    useEffect(() => {
        if(!_.isEmpty(selectedReward)) {
            for (let key in formik.values) {
                if(Object.keys(selectedReward).includes(key)) {
                    formik.setFieldValue(key, selectedReward[key])
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReward])

    useEffect(() => {
        if(get_create_success) {
            dispatch(clean("CREATE_REWARD"))
            showToastMessage('success',"Reward created")

            let currentUrlParams = new URLSearchParams(location.search);
            let student_id = currentUrlParams.get('sid')

            if(student_id) {
                let data = {
                    user_id: student_id
                }
                dispatch(onViewReward(data))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_create_success])

    useEffect(() => {
        if(get_update_success) {
            dispatch(clean("UPDATE_REWARD"))
            showToastMessage('success',"Reward updated")
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_update_success])
    
  return (
    <Modal isOpen={visible} toggle={onClose} size='lg'>
        <ModalBody>
            <div className='create-reward-modal-wrapper'>
                <div className='title'>
                    <img className='close-icon' src={closeIcon} alt="" onClick={onClose} />
                </div>
                <div className='desp text-start mt-5'>
                    <div className='px-4 pt-4'>
                        <div className='d-flex align-items-center mb-4'>
                            <h4>{isEdit ? "Edit" : "Create" } Reward</h4>
                            { isEdit && <h4 className='ms-auto text-primary-500 pointer' onClick={handleDeleteReward}>
                                <FontAwesomeIcon icon="fas fa-trash" />
                            </h4> }
                        </div>
                        { visible && get_subject_success && get_reward_type_success && get_reward_category_success &&
                           <form className="create-reward-form" name="createRewardForm" onSubmit={formik.handleSubmit}>
                                <div className='select-reward-box'>
                                    <SelectInputOverlap
                                        fieldType="name"
                                        fieldLabel="selectRewardSubject"
                                        fieldId={selectedReward.subject_id}
                                        data={subject}  
                                        placeholder="Select Subject"
                                        design="primary"
                                        onChange={handleOnSelectSubject}
                                    />
                                    { (formik.touched.subject_id && formik.errors.subject_id) && <p className="mt-1 text-sm text-red-500">{formik.errors.subject_id}</p> }
                                </div>
                                <div className='select-reward-box'>
                                    <SelectInputOverlap
                                        fieldType="title"
                                        fieldLabel="selectRewardCategory"
                                        fieldId={selectedReward.reward_cat_id}
                                        data={reward_category}  
                                        placeholder="Select categories"
                                        design="primary"
                                        onChange={handleOnSelectCategory}
                                    />
                                    { (formik.touched.reward_cat_id && formik.errors.reward_cat_id) && <p className="mt-1 text-sm text-red-500">{formik.errors.reward_cat_id}</p> }
                                </div>
                                <div className='select-reward-box'>
                                    <SelectInputOverlap
                                        fieldType="title"
                                        fieldLabel="selectRewardType"
                                        fieldId={selectedReward.reward_typ_id}
                                        data={reward_type}  
                                        placeholder="Reward type"
                                        design="primary"
                                        onChange={handleOnSelectType}
                                    />
                                    { (formik.touched.reward_typ_id && formik.errors.reward_typ_id) && <p className="mt-1 text-sm text-red-500">{formik.errors.reward_typ_id}</p> }
                                </div>
                                <div className='select-reward-box'>
                                    <textarea className='form-control' name="description" placeholder='Reward details' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.description}></textarea>
                                    { (formik.touched.description && formik.errors.description) && <p className="mt-1 text-sm text-red-500">{formik.errors.description}</p> }
                                </div>
                                <div className='select-reward-box'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-grow-1'>
                                            <SelectInputOverlap
                                                fieldType="name"
                                                fieldLabel="selectRewardPoints"
                                                data={reward_points}
                                                fieldId={selectedReward.points}  
                                                placeholder="Points"
                                                design="primary"
                                                onChange={handleOnSelectPoints}
                                            />
                                            { (formik.touched.points && formik.errors.points) && <p className="mt-1 text-sm text-red-500">{formik.errors.points}</p> }
                                        </div>
                                        <h4 className='info ms-4 text-primary-500' id="infoRewardTooltip">
                                            <FontAwesomeIcon icon="fa-solid fa-question-circle" />
                                        </h4>
                                        <Tooltip
                                            isOpen={tooltipOpen}
                                            placement="right"
                                            target="infoRewardTooltip"
                                            toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                            Select upto 100 points
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <button className='btn btn-primary submit-btn'>
                                        { isEdit ? "Update" : "Save"}
                                    </button>
                                    <div className='btn link-btn link-dark-btn ms-3' onClick={onClose}>Cancel</div>
                                </div>
                            </form> 
                        }
                    </div>
                </div>
            </div>
        </ModalBody>
        
    </Modal>
  )
}

CreateRewardModal.propTypes = {}

export default CreateRewardModal
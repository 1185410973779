import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fulfilled, clean, rejected } from 'redux-saga-thunk';
import _ from 'underscore'

import { useFormik } from 'formik'
import DatePicker from "react-datepicker";
import moment from 'moment';

import ProfilePicUpload from '../../components/Common/ProfilePicUpload'
import SelectInputGroup from '../../components/Common/SelectInputGroup';

import UpdatePasswordModal from '../MyProfile/UpdatePasswordModal';
import DeleteStudentConfirmModal from './DeleteStudentConfirmModal';
import { showToastMessage } from '../../helpers/toaster';

import { getKeyStages as onGetKeyStages } from '../../store/settings/keystage/actions';
import { getGrades as onGetGrades } from '../../store/settings/grades/actions';
import { getExamBoards as onGetExamBoards } from '../../store/settings/exam-boards/actions';
import { updateStudent as onUpdateStudent } from '../../store/student/actions';
import { deleteStudent as onDeleteSudent } from '../../store/student/actions';

import { viewPlans as onViewPlans } from '../../store/plans-and-prices/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const StudentDetailsCard = ({student, onDeleteSuccess}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [ isEdit, setEdit ] = useState(false)
    const [ isUpdatePasswordVisible, setUpdatePasswordVisible ] = useState(false)
    const [ isDeleteStudentConfrimModalVisible, setDeleteStudentConfrimModalVisible ] = useState(false)
    const [ planDetails, setplanDetails ] = useState({})
    const [ dob, setDob ] = useState("")

    const [ isDeleteIdSubmitted, setDeleteIdSubmitted ] = useState(false)

    const { user, myplan, keystages, grades, error, examboards, get_update_student_success, get_delete_success, update_student_failed, get_plan_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        myplan: state.PlansAndPricesReducer.myplan,
        keystages: state.KeyStageReducer.keystages,
        grades: state.GradesReducer.grades,
        examboards: state.ExamBoardsReducer.examboards,
        error: state.StudentReducer.error,
        get_update_student_success: fulfilled(state, "UPDATE_STUDENT"),
        update_student_failed: rejected(state, "UPDATE_STUDENT"),
        get_delete_success: fulfilled(state, "DELETE_STUDENT"),
        get_plan_success: fulfilled(state, "VIEW_PLANS")
    }))

    const initialValues = {
        image: "",
        user_name: "",
        school_name: "",
        dob: "",
        exam_board_id: null,
        key_stage_id: null,
        grade_id: null,
        email: "",
        password: ""
    }

    const onSubmit = values => {
        
        let data = {
            id: student.id,
            exam_board_id: values.exam_board_id,
            key_stage_id: values.key_stage_id,
            grade_id: values.grade_id,
            parent_id: user.id,
            full_name: student.full_name,
            user_name: values.user_name,
            first_name: student.first_name,
            last_name: student.last_name,
            email: values.email,
            dob: moment(dob).format('DD/MM/YYYY'),
            school_name: values.school_name
        }

        if(values.old_password !== "") {
            data.old_password = values.old_password
        }
    
        if(values.new_password !== "") {
            data.new_password = values.new_password
        }
    
        if(values.confirm_new_password !== "") {
            data.confirm_new_password = values.confirm_new_password
        }
           
        dispatch(onUpdateStudent(data))

    }

    const validate = values => {

        let errors = {}

        if(!values.image) {
            errors.image = "Profile image required"
        }

        if(!values.user_name) {
            errors.user_name = "Username required!"
        }
        
        if(!values.school_name) {
            errors.school_name = "School Name required!"
        }
        
        if(!values.dob) {
            errors.dob = "DOB required!"
        }

        if(!values.grade_id) {
            errors.grade_id = "Grade required!"
        }
    
        if(!values.key_stage_id) {
          errors.key_stage_id = "Key Stage required!"
        }
    
        if(!values.exam_board_id) {
          errors.exam_board_id = "Exam Board required!"
        }

        if(!values.email) {
            errors.email = "Email required"
        }
        else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
            errors.email = "Enter a valid email address"
        }

        return errors

    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handleImage = async (image) => {
        formik.setFieldValue("image", image)
    }

    const handlePasswordModal = () => {
        setUpdatePasswordVisible(!isUpdatePasswordVisible)
        setEdit(true)
    }

    const onPasswordSubmit = (data) => {
        formik.setFieldValue("old_password", data.old_password)
        formik.setFieldValue("new_password", data.new_password)
        formik.setFieldValue("confirm_new_password", data.confirm_new_password)
    }

    const handleOnSelectGrades = (id) => {
        formik.setFieldValue("grade_id", id)
    }
    
    const handleOnSelectKeystage = (id) => {
        formik.setFieldValue("key_stage_id", id)
    }
    
    const handleOnSelectExamBoards = (id) => {
        formik.setFieldValue("exam_board_id", id)
    }
    
    const handleDate = (date) => {
        setDob(date)
        setTimeout(() => {
            formik.setFieldValue("dob", moment(date).format('DD/MM/YYYY'))
        }, 100)
    }
      
    const handleDeleteStudent = () => {
        setDeleteStudentConfrimModalVisible(!isDeleteStudentConfrimModalVisible)
    }

    const handleEdit = () => {
        setEdit(!isEdit)
    }

    const handleClose = () => {
        setEdit(!isEdit)
    }

    const onConfirmDelete = () => {
        setDeleteIdSubmitted(true)
        let data = {
            student_id: student.id
        }
        dispatch(onDeleteSudent(data))
    }

    const handleUpgrade = () => {
        navigate( { pathname: "/select-plan", search: "?upgrade=true" })
    }

    const onImageRemove = () => {
        formik.setFieldValue("image", "")
    }

    useEffect(() => {
        if(student) {
            for (let key in formik.values) {
               if(Object.keys(student).includes(key)) {
                formik.setFieldValue(key, student[key])
               }
            }
            setDob(moment(student.student_detail.dob, "DD. M. YYYY").toDate())
            formik.setFieldValue("school_name", student.student_detail.school_name)
            formik.setFieldValue("exam_board_id", student.student_detail.exam_board_id)
            formik.setFieldValue("key_stage_id", student.student_detail.key_stage_id)
            formik.setFieldValue("grade_id", student.student_detail.grade_id)
            formik.setFieldValue("dob", moment(student.student_detail.dob, "DD. M. YYYY").toDate())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student])

    useEffect(() => {
        if(!_.isEmpty(user)) {
            dispatch(onViewPlans())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if(get_plan_success) {
            if(myplan.length !== 0)
            setplanDetails(myplan[0])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_plan_success])

    useEffect(() => {
        dispatch(onGetKeyStages())
        dispatch(onGetGrades())
        dispatch(onGetExamBoards())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(get_update_student_success) {
            dispatch(clean("UPDATE_STUDENT"))
            handleClose()
            showToastMessage('success',"Student Updated")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_update_student_success])

    useEffect(() => {
        if(update_student_failed) {
            dispatch(clean("UPDATE_STUDENT"))
            showToastMessage('error',error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update_student_failed])

    useEffect(() => {
        if(get_delete_success && isDeleteIdSubmitted) {
            setDeleteStudentConfrimModalVisible(false)
            showToastMessage('success',"Student Deleted")
            onDeleteSuccess(student.id)
            setDeleteIdSubmitted(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_delete_success])

  return (
    <div className='card gray-card student-card-box mb-4'>
        { isEdit && <div className='card-header d-flex justify-content-between align-items-center'>
            <h4 className='ft-default fw-regular'>{ isEdit ? "Edit Account Details" : "My Details" }</h4>
            <FontAwesomeIcon className="text-primary-500 text-h6 me-4 pointer" icon="fas fa-trash" onClick = {() => {handleDeleteStudent()}} />
        </div> }
        <div className='card-body p-0'>
            <form name="studentForm" onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className='col-lg-5 liner bg-white'>
                        <div className={`column first-column ${isEdit ? "space-top" : ""}`}>
                            { !isEdit && <div className='text-end mb-4 d-block d-lg-none'>
                                <p className='d-inline-block text-primary-920 text-base ft-default pointer' onClick={handleEdit}>Edit</p>
                            </div>}
                            <ProfilePicUpload image={formik.values.image} onImageSelect={handleImage} isEdit={isEdit} onImageRemove={onImageRemove} />
                            { formik.touched.image && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.image}</p> }
                            <div className='mt-5'>
                                <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                    <label htmlFor="user_name">User Name</label>
                                    <div className='input-group'>
                                        <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="user_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.user_name} readOnly={!isEdit} />
                                    </div>
                                    { formik.touched.user_name && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.user_name}</p> }
                                </div>
                                <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                    <label htmlFor="firstName">Password</label>
                                    <div className='input-group'>
                                        <input className={`form-control ${isEdit ? "simple" : ""}`} type="password" name="password" placeholder='************' readOnly />
                                        <span className="input-group-text">
                                            <FontAwesomeIcon className="text-primary-300 text-h6 pointer" icon="fas fa-edit" onClick = {handlePasswordModal} />
                                        </span>
                                    </div>
                            </div>
                            </div>
                            { isEdit && <div className='mt-5 d-none d-lg-block'>
                                <button className='btn btn-primary'>Update</button>
                                <div className='ms-4 btn btn-secondary' onClick={handleClose}>Cancel</div>
                            </div> }
                        </div>
                    </div>
                    <div className='col-lg-7 bg-white'>
                        <div className={`column ${isEdit ? "space-top" : ""}`}>
                            { !isEdit && <div className='text-end mb-4 d-none d-lg-block'>
                                <p className='d-inline-block text-primary-920 text-base ft-default pointer' onClick={handleEdit}>Edit</p>
                            </div>}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                        <label className="form-label">School Name</label>
                                        <div className="input-group">
                                            <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="school_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.school_name} readOnly={!isEdit} />
                                        </div>
                                        { formik.touched.school_name && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.school_name}</p> }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='input-box readonly-box'>
                                        <label className="form-label">Date of Birth</label>
                                        <div className="input-group">
                                            <DatePicker 
                                            className={`form-control ${isEdit ? "simple pointer" : "no-event"}`} 
                                            placeholderText={'DD/MM/YYYY'} 
                                            id="example-datepicker" 
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"  
                                            selected={dob}
                                            onChange={(date) => handleDate(date)} 
                                            autoComplete="off" 
                                            />
                                        </div>
                                        { formik.touched.dob && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.dob}</p> }
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='input-box'>
                                    <SelectInputGroup 
                                        label="Exam Board" 
                                        id="examBoardSelect" 
                                        placeholder="Exam board (eg. AQA)"
                                        data={examboards}
                                        readonly = {!isEdit}
                                        value={formik.values.exam_board_id}
                                        design={`${isEdit ? "simple" : ""}`} 
                                        onChange={handleOnSelectExamBoards} 
                                    />
                                    { formik.touched.exam_board_id && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.exam_board_id}</p> }
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='input-box'>
                                    <SelectInputGroup 
                                        label="Key stage" 
                                        id="keyStageSelect" 
                                        placeholder="Key stage (eg. KS-3)"
                                        data={keystages}
                                        readonly = {!isEdit}
                                        value={formik.values.key_stage_id}
                                        design={`${isEdit ? "simple" : ""}`}  
                                        onChange={handleOnSelectKeystage}
                                    />
                                    { formik.touched.key_stage_id && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.key_stage_id}</p> }
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='input-box'>
                                    <SelectInputGroup 
                                        label="Class" 
                                        id="ClassSelect" 
                                        placeholder="Grade (eg. G-12)" 
                                        data={grades}
                                        readonly = {!isEdit}
                                        value={formik.values.grade_id}
                                        design={`${isEdit ? "simple" : ""}`} 
                                        onChange={handleOnSelectGrades}
                                    />
                                    { formik.touched.grade_id && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.grade_id}</p> }
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className={`input-box ${isEdit ? "" : "readonly-box"}`}>
                                        <label htmlFor="email">Email</label>
                                        <div className='input-group'>
                                            <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} readOnly={!isEdit} />
                                        </div>
                                        { formik.touched.email && isEdit && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className="input-box readonly-box package-box">
                                        <label htmlFor="firstName">Package</label>
                                        <div className='input-group'>
                                            <input className={`form-control ${isEdit ? "simple" : ""}`} type="text" name="plan_name" value={planDetails?.plan_id === "2" ? "Free" : "Pro"} readOnly />
                                            {   planDetails?.plan_id === "2" &&
                                                <span className="input-group-text">
                                                    <div className='btn upgrade-btn ms-auto' onClick={handleUpgrade}>Upgrade</div>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            { isEdit && <div className='d-block d-lg-none mt-3'>
                                <button className='btn btn-primary'>Update</button>
                                <div className='ms-4 btn btn-secondary' onClick={handleClose}>Cancel</div>
                            </div> }
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <UpdatePasswordModal 
            visible={isUpdatePasswordVisible} 
            onClose = { () => {setUpdatePasswordVisible(!isUpdatePasswordVisible)} }
            onSubmitData={onPasswordSubmit} />
        <DeleteStudentConfirmModal 
            visible={isDeleteStudentConfrimModalVisible} 
            onClose = { () => {setDeleteStudentConfrimModalVisible(!isDeleteStudentConfrimModalVisible)} }
            onDelete={onConfirmDelete} />
    </div>
  )
}


export default StudentDetailsCard
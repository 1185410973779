import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk';

import { useFormik } from 'formik'

import { billingDetailsSubmit as onBillingDetailsSubmit } from '../../../../store/plans-and-prices/actions';

import { countryData } from '../../../../constants/variousdata'
import SelectInputGroup from '../../../../components/Common/SelectInputGroup';

const AddBillingDetails = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, get_billing_details_success } = useSelector(state => ({
        user: state.LoginReducer.user,
        get_billing_details_success: fulfilled(state, "BILLING_DETAILS_SUBMIT")
    }))

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        country: "",
        postal_code: ""
    }

    const onSubmit = values => {

        let countryFilteredData = countryData.filter(item => {
            return item.id === values.country
        })

        let data ={
            ...values,
            email: user.email,
            country: countryFilteredData[0].name
        }
        dispatch(onBillingDetailsSubmit(data))
    }

    const validate = values => {

        let errors = {}

        if(!values.first_name) {
            errors.first_name = "First Name is required!"
        }
    
        if(!values.last_name) {
            errors.last_name = "Last Name is required!"
        }
    
        if(!values.address_line_1) {
            errors.address_line_1 = "Address Line 1 is required!"
        }
    
        if(!values.city) {
            errors.city = "City is required!"
        }
    
        if(!values.country) {
            errors.country = "Country is required!"
        }
    
        if(!values.postal_code) {
            errors.postal_code = "Postal Code is required!"
        }

        return errors

    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handleOnCountry = (name) => {
        formik.setFieldValue("country", name)
    }

    useEffect(() => {
        dispatch(clean("BILLING_DETAILS_SUBMIT"))
        if(get_billing_details_success) {
            let currentUrlParams = new URLSearchParams(location.search);
            currentUrlParams.set('sid', 4)
            navigate( { pathname: "/parent/payment", search: currentUrlParams.toString() }, { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_billing_details_success])

  return (
    <div className='add-billing-card-wrapper'>
         <form name="addBillingInfo" onSubmit={formik.handleSubmit}>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="first_name">First Name<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="first_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.first_name} />
                    </div>
                </div>
                    { formik.touched.first_name && <p className="mt-1 text-sm text-red-500">{formik.errors.first_name}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="last_name">Last Name<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="last_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.last_name} />
                    </div>
                </div>
                { formik.touched.last_name && <p className="mt-1 text-sm text-red-500">{formik.errors.last_name}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="address_line_1">Address line 1<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="address_line_1" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address_line_1} />
                    </div>
                </div>
                { formik.touched.address_line_1 && <p className="mt-1 text-sm text-red-500">{formik.errors.address_line_1}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="address_line_2">Address line 2</label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="address_line_2" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address_line_2} />
                    </div>
                </div>
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="city">City<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="city" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.city} />
                    </div>
                </div>
                { formik.touched.city && <p className="mt-1 text-sm text-red-500">{formik.errors.city}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="country">Country<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <SelectInputGroup 
                            label="" 
                            id="countrySelect" 
                            placeholder="Country"
                            data={countryData}
                            value={formik.values.country}
                            onChange={handleOnCountry} 
                        />
                    </div>
                </div>
                { formik.touched.country && <p className="mt-1 text-sm text-red-500">{formik.errors.country}</p> }
            </div>
            <div className='input-box mb-4'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <label htmlFor="postal_code">Postal Code<span className='text-custom-red'>*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <input type="text" className='form-control' name="postal_code" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.postal_code} />
                    </div>
                </div>
                { formik.touched.postal_code && <p className="mt-1 text-sm text-red-500">{formik.errors.postal_code}</p> }
            </div>
            <div className='mt-4'>
                <button type='submit' className='btn btn-primary submit-btn'>Next</button>
            </div>
         </form>
    </div>
  )
}

export default AddBillingDetails
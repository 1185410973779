import {
    VIEW_REWARD,
    VIEW_REWARD_SUCCESS,
    VIEW_REWARD_FAILED,
    CREATE_REWARD,
    CREATE_REWARD_SUCCESS,
    CREATE_REWARD_FAILED,
    VIEW_REWARD_CATEGORY,
    VIEW_REWARD_CATEGORY_SUCCESS,
    VIEW_REWARD_CATEGORY_FAILED,
    DELETE_REWARD,
    DELETE_REWARD_SUCCESS,
    DELETE_REWARD_FAILED,
    UPDATE_REWARD,
    UPDATE_REWARD_SUCCESS,
    UPDATE_REWARD_FAILED
} from './actionTypes'

export const viewReward = (reward) => {
    return {
        type: VIEW_REWARD,
        payload: reward,
        meta:{
            thunk:true
        }
    }
}

export const viewRewardSuccess = (reward, {thunk}) => {
    return {
        type: VIEW_REWARD_SUCCESS,
        payload: reward,
        meta: {
            thunk
        }
    }
}

export const viewRewardFailed = (reward, {thunk}) => {
    return {
        type: VIEW_REWARD_FAILED,
        payload: reward,
        error: true,
        meta: {
            thunk
        }
    }
}

export const viewRewardCategory = () => {
    return {
        type: VIEW_REWARD_CATEGORY,
        meta:{
            thunk:true
        }
    }
}

export const viewRewardCategorySuccess = (reward_category, {thunk}) => {
    return {
        type: VIEW_REWARD_CATEGORY_SUCCESS,
        payload: reward_category,
        meta: {
            thunk
        }
    }
}

export const viewRewardCategoryFailed = (reward_category, {thunk}) => {
    return {
        type: VIEW_REWARD_CATEGORY_FAILED,
        payload: reward_category,
        error: true,
        meta: {
            thunk
        }
    }
}

export const createReward = (reward) => {
    return {
        type: CREATE_REWARD,
        payload: reward,
        meta:{
            thunk:true
        }
    }
}
  
export const createRewardSuccess = ({response, data}, { thunk }) => ({
    type: CREATE_REWARD_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const createRewardFailed = (error, { thunk }) => ({
    type: CREATE_REWARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const updateReward = (reward) => {
    return {
        type: UPDATE_REWARD,
        payload: reward,
        meta:{
            thunk:true
        }
    }
}
  
export const updateRewardSuccess = ({response, data}, { thunk }) => ({
    type: UPDATE_REWARD_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const updateRewardFailed = (error, { thunk }) => ({
    type: UPDATE_REWARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})

export const deleteReward = (reward) => {
    return {
        type: DELETE_REWARD,
        payload: reward,
        meta:{
            thunk:true
        }
    }
}
  
export const deleteRewardSuccess = ({response, data}, { thunk }) => ({
    type: DELETE_REWARD_SUCCESS,
    payload: {response, data},
    meta: {
        thunk
    }
})
  
export const deleteRewardFailed = (error, { thunk }) => ({
    type: DELETE_REWARD_FAILED,
    payload: error,
    error: true,
    meta: {
        thunk
    }
})
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import blockBg from "../../../assets/images/signin-block-bg.png"
import logo from "../../../assets/images/logo-big.png"

import { useFormik } from 'formik'
import { Alert } from "reactstrap"

import { useDispatch, useSelector } from 'react-redux';
import { fulfilled, rejected } from 'redux-saga-thunk';

import { requestPasswordEmail as onRequestPasswordEmail } from '../../../store/auth/request-password/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const RequestPassword = props => {

  const dispatch = useDispatch()

  const [ isRequestPassword, setRequestPassword ] = useState(false)

  const { error, get_success, get_error  } = useSelector(state => ({
    error: state.RequestPasswordReducer.error,
    get_success: fulfilled(state, "REQUEST_PASSWORD_EMAIL"),
    get_error: rejected(state, "REQUEST_PASSWORD_EMAIL")
  }))

  const initialValues = {
    email: ""
  }

  const onSubmit = values => {
    dispatch(onRequestPasswordEmail(values.email))
  }

  const validate = values => {
    let errors = {}
    
    if(!values.email) {
      errors.email = "Email is required"
    }
    else if(!/^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+.)+[a-z]{2,5}$/i.test(values.email)) {
      errors.email = "Enter a valid email address"
    }

    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  }) 

  useEffect(() => {
    if(get_success) {
      setRequestPassword(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_success])

  useEffect(() => {
    setRequestPassword(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
   <div className="reset-password-wrapper d-inline-block w-100">
      <div className="container-fluid">
        <div className="row">
            <div className="col-sm-12">
              <div className="logo-container d-none d-lg-flex">
                <img src={logo} alt="" />
              </div>
              <div className="content-container d-flex w-100">
                <div className="left d-none d-lg-block">
                  <img className="img-fluid block-img" src={blockBg} alt="" />
                </div>
                <div className="right">
                  <div className="row">
                    <div className="col-12 col-sm-10 offset-sm-1 d-lg-none">
                      <div className="logo-container position-static">
                        <img src={logo} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-10 col-lg-8 offset-lg-2 offset-sm-1">
                      <div className="form-container">
                        <h2 className="text-gray-980 fw-semibold ls--2 mb-4">Reset Password</h2>
                        
                        { !isRequestPassword ? 
                          <>
                            <h5 className="text-gray-980 fw-regular mb-2">Forgot Password</h5>
                            <p className="text-gray-980 text-sm op-50 pb-1">Enter your email to get the link.</p>

                            <div className="form-box mt-4">
                              {(error && get_error) && ( <Alert color="danger">{error}</Alert>)}
                              <form name="requestPasswordForm" onSubmit={formik.handleSubmit}>
                                <div className="input-box auth-box">
                                  <label htmlFor="requestPasswordEmail">Email</label>
                                  <div className='input-group'>
                                    <input className="form-control" type="text" id="requestPasswordEmail" name="email" placeholder="Eg: JohnPaterson@gmail.com" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} />
                                    {(formik.touched.email && !formik.errors.email) && <span className='input-group-text'>
                                      <FontAwesomeIcon className='text-primary-950 fs-6' icon="fa-solid fa-check" />
                                    </span>}
                                  </div>
                                  { (formik.touched.email && formik.errors.email) && <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p> }
                                </div>
                                <div className="mt-4">
                                  <button className="btn btn-special w-100 text-uppercase ls--15">Send link</button>
                                </div>
                                <div className="mt-3 text-center">
                                  <span className="ft-head text-sm ls--2">Go back to</span> <Link to="/sign-in" className="text-blue-500 fw-medium ft-head">Sign In</Link>
                                </div>

                              </form>
                            </div> 

                          </>
                        : 
                          <>
                            <h6 className="text-gray-980 instruct lh-medium">An email with reset instructions have been sent to <span className='fw-medium'>{formik.values.email}</span></h6>
                            <div className="mt-5 text-center">
                              <span className="ft-head text-sm ls--2">Go back to</span> <Link to="/sign-in" className="text-blue-500 fw-medium ft-head">Sign In</Link>
                            </div>
                          </>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
   </div>
  )
}


export default RequestPassword
import {
    VIEW_REWARD_TYPE_SUCCESS,
    VIEW_REWARD_TYPE_FAILED
} from './actionTypes'

const INIT_STATE = {
    error: "",
    loading: false,
    reward_type: null
}


const RewardTypeReducer = (state = INIT_STATE, action) => {

    switch (action.type) {

      case VIEW_REWARD_TYPE_SUCCESS: {
        return {
          ...state,
          loading: false,
          reward_type: action.payload,
          error: "",
        }
      }
      case VIEW_REWARD_TYPE_FAILED: {
        return {
          ...state,
          loading: false,
          reward_type: null,
          error: action.payload,
        }
      }
      default:
        return state
    }

}

export default RewardTypeReducer;
import React, { useState, useEffect } from 'react'

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const SelectInputGroup = ( { label, id, icon="", placeholder, data, readonly = false, design="", value, onChange }) => {

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)
  const [ selectedItem, setSelectedItem ] = useState("")


  const handleSelectedItem = (item) => {
    setSelectedItem(item.name)
    onChange(item.id)
  }

  useEffect(() => {
    document.addEventListener("mouseup", (event) => {
      const parentElem = document.getElementById(`${id}List`);
      const elem = parentElem?.querySelectorAll(":scope > .select-item")
      if(elem && elem.length !== 0) {
        for (let i = 0; i < elem.length; i++) {
          if (!elem[i].contains(event.target)) {
            setDropDownExpanded(false)
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(data.length !== 0) {
      if(value != null && value !== undefined && value !== "") {
        let list = data.filter(item => {
          return item.id === parseInt(value)
        })
        setSelectedItem(list[0].name)
      }
      else {
        setSelectedItem("")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value])

  return (
    <div className='select-group-box'>
        <div className="input-box mb-0">
            { label && <label htmlFor={id} className="form-label">{label}</label> }
            <div className="input-group">
                { icon && <span className="input-group-text">
                    <FontAwesomeIcon className='text-primary-950 text-sm' icon={`fas ${icon}`} />
                </span>}
                <input type="text" className={`form-control ${readonly ? "no-event" : design} `} value={selectedItem} id={id} placeholder={placeholder} onClick={() => {setDropDownExpanded(!isDropDownExpanded)}} readOnly />
                <span className="input-group-text">
                  <FontAwesomeIcon className='text-primary-950 text-sm' icon="fas fa-angle-down" />
                </span>
            </div>
            <ul id={`${id}List`} className={`select-input-dropdown list-group ${isDropDownExpanded ? "d-block" : "d-none"}`}>
              { data?.map((item, index) => {
                return <li key={index} className='list-group-item select-item' onClick={() => {handleSelectedItem(item)}}>{item.name}</li>
              })
              }
            </ul>
        </div>
    </div>
  )
}


export default SelectInputGroup
import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom"

import { topMenu } from "../../../constants/sidebarMenus";
import { bottomMenu } from "../../../constants/sidebarMenus";

import SwapAccountModal from "./SwapAccountModal";

const Sidebar = props => {

  const navigate = useNavigate();
  const location = useLocation()

  const [ isSidebarHover, setSidebarHover ] = useState(false)
  const [ isSwapAccountAvailable, setSwapAccountAvailable ] = useState(false)

  const [ currentPath, setCurrentPath ] = useState("")

  const { isSideBarExpanded  } = useSelector(state => ({
    isSideBarExpanded: state.LayoutReducer.isSideBarExpanded
  }))

  useEffect(() => {
  }, [isSideBarExpanded])

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  const navigateTo = path => {
    if(path) {
      navigate(path)
    }
    else {
      setSwapAccountAvailable(true)
    }
  }

  return (
    <div className={`sidebar-wrapper ${isSideBarExpanded ? 'show' : ''} ${isSidebarHover ? 'big' : ''}`}>
      <div 
        className="sidebar-container d-flex flex-column justify-content-between"
        onMouseEnter={() => {setSidebarHover(true)}}
        onMouseLeave={() => {setSidebarHover(false)}}
        >
        <div className="sidebar-top mt-3">
          {
            topMenu.map((item, index) => {
              return (
                <div key={index} className="sidebar-item" onClick={() => {navigateTo(item.path)}}>
                  <img src={`${ currentPath === item.path ? item.altIcon : item.icon }`} alt="" />
                  <p className="text text-base ft-default fw-medium">{item.title}</p>
                </div>
              )
            })
          }
        </div>
        <div className="sidebar-bottom">
           {
              bottomMenu.map((item, index) => {
                return (
                  <div key={index} className="sidebar-item" onClick={() => {navigateTo(item.path)}}>
                    <img src={`${ currentPath === item.path ? item.altIcon : item.icon }`} alt="" />
                    <p className="text text-base ft-default fw-medium">{item.title}</p>
                  </div>
                )
              })
            }
        </div>
      </div>
      <SwapAccountModal visible={isSwapAccountAvailable} 
            onClose = { () => {setSwapAccountAvailable(!isSwapAccountAvailable)} }/>
    </div>
  )
}

export default Sidebar
import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignoutConfirmModal from "./SignoutConfirmModal";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from 'underscore'


library.add(fas, fab);

const ProfileMenu = () => {

  const { user } = useSelector(state => ({
    user: state.LoginReducer.user
  }))

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)
  const [ isConfirmAvailable, setConfirmAvailable ] = useState(false)
  const [ profileImage, setProfileImage ] = useState(null)
  const [ role, setRole ] = useState(" ")

  useEffect(() => {
    const topElem = document.querySelector(".profile-menu-top");
    const bottomElem = document.querySelector(".profile-menu-bottom");
    document.addEventListener("mousedown", (event) => {
      if (topElem.contains(event.target) || bottomElem.contains(event.target)) {
        setDropDownExpanded(true)
      } else {
        setDropDownExpanded(false)
      }
    });
  }, [])

  useEffect(() => {
    if(!_.isEmpty(user)) {
      if(user.image !== "https://api.smartht.co.uk/storage/images/users") {
        setProfileImage(user.image)
      }
      if(user?.role) {
        setRole(user?.role[0].name)
      }
      else {
        setRole(user?.roles[0].name)
      }
    }
  }, [user])

  return (
    <div className={ `profile-menu-wrapper ${ isDropDownExpanded ? 'show' : '' }` }>
      <div className={`profile-menu-top d-flex pointer`} onClick={() => {setDropDownExpanded(true)} }>
        <div className="img-box flex-shrink-0">
          { profileImage ? (
            <img src={profileImage} alt="" />
          ) : (
            <div className={`user-icon d-flex justify-content-center align-items-center ${isDropDownExpanded ? "bg-primary-300 text-white" : ""} `}>
              <FontAwesomeIcon className="text-h5 pointer text-white" icon="fas fa-user" />
            </div>
          )}
          
        </div>
        <div className='name-box'>
          <h6 className='text-normal fw-medium text-capitalize name'>Hello, {user.full_name}</h6>
          <p className='text-sm role lh-14 text-capitalize'>{role}</p>
        </div>
        <div className='arrow'>
          <FontAwesomeIcon icon={['fas', 'fa-chevron-down']} />
        </div>
      </div>
      <div className={`profile-menu-bottom ${ isDropDownExpanded ? 'd-block' : 'd-none' }` }>
        <ul className='list'>
          <li>
           <Link to="/parent/my-profile">
              <FontAwesomeIcon icon={['fas', 'fa-user-alt']} />
              <span className='ft-head text-sm ms-2'>My Profile</span>
           </Link>
          </li>
          <li>
            <Link to="/parent/settings">
              <FontAwesomeIcon icon="fas fa-user-cog" />
              <span className='ft-head text-sm ms-2'>Profile Settings</span>
            </Link>
          </li>
          <li>
            <a href="return:false" onClick={() => {setConfirmAvailable(!isConfirmAvailable)}}>
              <FontAwesomeIcon icon="fas fa-sign-out" />
              <span className='ft-head text-sm ms-2'>Sign Out</span>
            </a>
          </li>
        </ul>
      </div>
      <SignoutConfirmModal visible={isConfirmAvailable} 
            onClose = { () => {setConfirmAvailable(!isConfirmAvailable)} }/>
    </div>
  )
}

export default ProfileMenu
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean } from 'redux-saga-thunk';

import PaymentVisaCardImg from '../../assets/images/payment-card-visa-img.png'
import PaymentStripeCardImg from '../../assets/images/payment-card-stripe-img.png'
import PaymentMasterCardImg from '../../assets/images/payment-card-mastercard-img.png'

import { makePayment as onMakePayment } from '../../store/plans-and-prices/actions'
import { updateSubscription as onUpdateSubscription } from '../../store/plans-and-prices/actions';
import { changeSubscription as onChangeSubscription } from '../../store/plans-and-prices/actions';
import { buyPlan as onBuyPlan } from '../../store/plans-and-prices/actions'
import { updatePaymentMethod as onUpdatePaymentMethod } from '../../store/plans-and-prices/actions';

import { showToastMessage } from '../../helpers/toaster';


import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const PaymentCard = ({ card, setup, length, onDelete, onUpdatePayment } ) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const [ isDropDownExpanded, setDropDownExpanded ] = useState(false)
  const [ isPayDisabled, setPayDisabled ] = useState(true)
  const [ cvc, setCVC ] = useState("")
  const [ myPlan, setMyPlan ] = useState(null)

  const {user, myplan, get_plan_success, get_payment_success, get_change_subscription_success, get_update_subscription_success, get_buyplan_success, get_update_method_success } = useSelector(state => ({
    user: state.LoginReducer.user,
    myplan: state.PlansAndPricesReducer.myplan,
    get_plan_success: fulfilled(state, "VIEW_PLANS"),
    get_payment_success: fulfilled(state, "MAKE_PAYMENT"),
    get_change_subscription_success: fulfilled(state, "CHANGE_SUBSCRIPTION"),
    get_update_subscription_success: fulfilled(state, "UPDATE_SUBSCRIPTION"),
    get_buyplan_success: fulfilled(state, "BUY_PLAN"),
    get_update_method_success: fulfilled(state, "UPDATE_PAYMENT_METHOD")
  }))

  const handlePayment = () => {

    let currentUrlParams = new URLSearchParams(location.search);

    if(currentUrlParams.get("upgrade")) {

      let subscription = currentUrlParams.get("subscription")

      let data = {
            id: myPlan.id,
            plan_id: 3,
            plan_type: subscription,
            number_of_student: 1
      }

      dispatch(onChangeSubscription(data))


    }
    else {
      let data = {
        plan_id: 3,
        number_of_student: 1
      }
      dispatch(onUpdateSubscription(data))
    }

  }

  const handleCVC = (event) => {
    let num = event.target.value
    if(num.length > 2) {
      setPayDisabled(false)
    }
    else {
      setPayDisabled(true)
    }
    setCVC(event.target.value)
  }

  const handleUpdatePayment = (id) => {

    let data = {
      payment_method_id: id
    }
    dispatch(onUpdatePaymentMethod(data))
  }

  useEffect(() => {
    if(get_plan_success) {
      if(myplan.length !== 0)
      setMyPlan(myplan[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_plan_success])

  useEffect(() => {
    if(get_change_subscription_success) {
        
      dispatch(clean("CHANGE_SUBSCRIPTION"))

        let currentUrlParams = new URLSearchParams(location.search);
        let subscription = currentUrlParams.get("subscription")

        let data = {
          plan_id: 3,
          plan_type: subscription,
          parent_id: user.id,
          number_of_student: 1 //because upgrade will always have only one children
        }
        dispatch(onBuyPlan(data))
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_change_subscription_success])

  useEffect(() => {
    if(get_buyplan_success) {
      
      dispatch(clean("BUY_PLAN"))
      
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('sid', 4)
      navigate( { pathname: "/parent/payment", search: currentUrlParams.toString() }, { replace: true })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_buyplan_success])

  useEffect(() => {
    if(get_update_subscription_success) {
      
      dispatch(clean("UPDATE_SUBSCRIPTION"))

      let name = card.billing_details.name.split(" ")

      let paymentData = {
        cardNumber: card.last4,
        expiryMonth: card.exp_month,
        expiryYear: card.exp_year,
        cvc: cvc,
        first_name: name[0],
        last_name: name[1],
        email: card.billing_details.email,
        address_line_1: card.billing_details.address.line1,
        address_line_2: card.billing_details.address.line2,
        city: card.billing_details.address.city,
        postal_code: card.billing_details.address.postal_code,
        country: card.billing_details.address.country,
      }

      dispatch(onMakePayment(paymentData))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_update_subscription_success])

  useEffect(() => {
    dispatch(clean("MAKE_PAYMENT"))
    if(get_payment_success) {
      let currentUrlParams = new URLSearchParams(location.search);
      currentUrlParams.set('sid', 4)
      navigate( { pathname: "/parent/payment-success", search: currentUrlParams.toString() }, { replace: true })
  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_payment_success])

  useEffect(() => {
    dispatch(clean("UPDATE_PAYMENT_METHOD"))
    if(get_update_method_success) {
      showToastMessage('success',"Card updated")
      onUpdatePayment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_update_method_success])
  

  return (
    <div className='payment-card'>
      <div className='title-box bg-primary-900 d-flex align-items-center'>
          <div className='text-base ft-default text-white'>{card.is_default ? "Default" : ""} payment method</div>
          { !setup && !card.is_default && <FontAwesomeIcon className="ms-auto text-white fs-6 pointer" icon="fa-solid text-h4 fa-trash" onClick={() => {onDelete(card.id)}} /> }
        </div>
        <div className={`content ${isDropDownExpanded ? "bg-white shadow" : "bg-gray-100"} d-flex`}>
          <div className='mx-2 mt-3'>
            <div className={`check-item ${card?.is_default ? "default" : ""} ${length === 1 ? "no-event" : ""} `} onClick={() => handleUpdatePayment(card.id)}>
              <div className='inner'></div>
            </div>
          </div>
          <div className='mx-2'>
            { card.brand === "mastercard" && <img className='card-img' src={PaymentMasterCardImg} alt="" /> }
            { card.brand === "visa" && <img className='card-img' src={PaymentVisaCardImg} alt="" /> }
            { card.brand === "stripe" && <img className='card-img' src={PaymentStripeCardImg} alt="" /> }
          </div>
          <div className='mx-2'>
            <div className='text-h6'>Ending in {card.last4}</div>
            <div className='text-h6 text-gray-700'>Expires in {card.exp_month}/{card.exp_year}</div>
            <div className='text-base text-gray-700'>{card.billing_details.name}</div>
            { isDropDownExpanded && 
              <div className='d-flex align-items-center'>
                <input type="text" className='form-control user-select-none' placeholder='Enter cvc' onChange={(event) => handleCVC(event)} />
                { setup &&  <p className={`fw-medium ms-3 pointer ${isPayDisabled ? 'no-event text-gray-250' : "text-primary-900"}`} onClick={handlePayment}>Pay</p> }
              </div> 
            }
          </div>
          <div className='ms-auto me-2'>
            <FontAwesomeIcon className='ms-auto text-gray-700 fs-6 pointer' icon={`fa-solid text-h4 ${isDropDownExpanded ? "fa-angle-up" : "fa-angle-down"}`} onClick={() => {setDropDownExpanded(!isDropDownExpanded)}} />
          </div>
      </div>
    </div>
  )
}


export default PaymentCard
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { fulfilled, clean, rejected } from 'redux-saga-thunk';

import { useFormik } from 'formik'
import { Alert } from "reactstrap"

import DatePicker from "react-datepicker";
import moment from 'moment';
import _ from 'underscore'

import { paymentCards } from '../../../../constants/variousdata'

import { makePayment as onMakePayment } from '../../../../store/plans-and-prices/actions';

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab);

const AddCardDetails = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ expiryDate, setExpiryDate] = useState(Date.parse(null))

    const { billing, error_payment, get_payment_success, get_payment_error } = useSelector(state => ({
        billing: state.PlansAndPricesReducer.billing,
        error_payment: state.PlansAndPricesReducer.error_payment,
        get_payment_success: fulfilled(state, "MAKE_PAYMENT"),
        get_payment_error: rejected(state, "MAKE_PAYMENT")
    }))

    const initialValues = {
        cardNumber: "",
        expiryMonth: "",
        expiryYear: "",
        cvc: "",
        holder_name: "",
        expiry_date: "",
        card_type: ""
    }

    const onSubmit = values => {

        let dateData = values.expiry_date.split('/')

        let cardData = {
            ...values,
            cardNumber: "5555555555554444",
            expiryMonth: dateData[0],
            expiryYear: dateData[1]
        }

        if(!billing) {
            showBillingDetails()
        }
        else {
            let data = {
                ...cardData,
                ...billing,
            }
            dispatch(onMakePayment(data))
        }

    }

    const validate = values => {

        let errors = {}


        if(!values.card_type) {
            errors.card_type = "Card type is required!"
        }
    
        if(!values.holder_name) {
            errors.holder_name = "Card holder name is required!"
        }
    
        if(!values.cardNumber) {
            errors.cardNumber = "Card number is required!"
        }
        else if(values.cardNumber.length < 16) {
            errors.cardNumber = "Card number should contain 16 digits!"
        }
    
        if(!values.expiry_date) {
            errors.expiry_date = "Expiry date is required!"
        }
        else if(isNaN(Date.parse(expiryDate))) {
            errors.expiry_date = "Expiry date is required!"
        }
    
        if(!values.cvc) {
            errors.cvc = "CVC is required!"
        }
        else if(values.cvc.length < 3) {
            errors.cvc = "Password should contain 3 or 4 digits!"
        }

        return errors

    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const handleExpiryDate = (date) => {
        setExpiryDate(date)
        setTimeout(() => {
            formik.setFieldValue("expiry_date", moment(date).format('MM/YYYY'))
        }, 100)
    }

    const handleCardType = (name) => {
        formik.setFieldValue("card_type", name)
    }

    const showBillingDetails = () => {
        let currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set('sid', 3)
        navigate( { pathname: "/parent/billing-info", search: currentUrlParams.toString() }, { replace: true })
    }

    useEffect(() => {
        dispatch(clean("MAKE_PAYMENT"))
        if(get_payment_success) {
          let currentUrlParams = new URLSearchParams(location.search);
          currentUrlParams.set('sid', 4)
          navigate( { pathname: "/parent/payment-success", search: currentUrlParams.toString() }, { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_payment_success])

    useEffect(() => {
        dispatch(clean("MAKE_PAYMENT"))
        if(get_payment_error) {
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get_payment_error])

  return (
    <div className='add-payment-card-wrapper'>
        <form name="addBillingInfo" onSubmit={formik.handleSubmit}>
            <div className='mt-3'>
                {(_.isArray(error_payment)) && error_payment && ( 
                    <>
                    { Object.keys(error_payment).map(key => {
                        return (
                            <Alert color="danger">{error_payment[key]}</Alert>
                        )
                    })}
                    </>
                )}
                { error_payment?.message && <Alert color="danger">{error_payment?.message}</Alert>} 
            </div>
            <div className='card-container'>
                <label htmlFor="holder_name">Add debit/credit card</label>
                <div className='payment-cards-container mt-2 mb-3'>

                    <div className='d-flex align-items-center'>
                        { paymentCards.map((item, index) => {
                            return (
                                <div className='payment-box mx-1' key={index} onClick={() => {handleCardType(item.name)}}>
                                    <img className='card' src={item.image} alt="" />
                                    <div className={`checked ${formik.values.card_type === item.name ? "d-inline-block" : "d-none"}`}>
                                        <img src={item.checked} alt="" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    { formik.errors.card_type &&  <div className='mt-1'>
                    { formik.touched.card_type && <p className="mt-1 text-sm text-red-500">{formik.errors.card_type}</p> }
                    </div> }
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='input-box mb-4'>
                        <label htmlFor="holder_name">Card holder name<span className='text-custom-red'>*</span></label>
                        <input type="text" className='form-control' name="holder_name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.holder_name} />
                        { formik.touched.holder_name && <p className="mt-1 text-sm text-red-500">{formik.errors.holder_name}</p> }
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='input-box mb-4'>
                        <label htmlFor="cardNumber">Card number<span className='text-custom-red'>*</span></label>
                        <div className="input-group">
                            <input type="text" className='form-control' name="cardNumber" pattern='[0-9]*\.?[0-9]*' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.cardNumber} maxLength="16" />
                            <span className='input-group-text'>
                                <FontAwesomeIcon className='text-primary-950 tick-icon' icon="fa-solid fa-lock" />
                            </span>
                        </div>
                        { formik.touched.cardNumber && <p className="mt-1 text-sm text-red-500">{formik.errors.cardNumber}</p> }
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='input-box readonly-box expiry-date-box mb-4'>
                        <label htmlFor="expiry_date">Expiry date<span className='text-custom-red'>*</span></label>
                        <div className="input-group">
                            <span className="input-group-text pointer">
                                <FontAwesomeIcon className='text-primary-950 text-sm' icon="fa-solid fa-calendar-days" />
                            </span>
                            <DatePicker 
                                className='form-control pointer' 
                                placeholderText={'MM/YY'} 
                                id="example-datepicker" 
                                showMonthYearPicker
                                selected={expiryDate}
                                dateFormat="MM/yy"
                                minDate={new Date()}  
                                onChange={(date) => handleExpiryDate(date)}
                                onBlur={formik.handleChange}
                                autoComplete="off" 
                            />
                        </div>
                        { formik.touched.expiry_date && <p className="mt-1 text-sm text-red-500">{formik.errors.expiry_date}</p> }
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='input-box mb-4'>
                        <label htmlFor="cvc">CVC number<span className='text-custom-red'>*</span></label>
                        <input type="text" className='form-control' name="cvc" pattern='[0-9]*\.?[0-9]*' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.cvc} maxLength="4" />
                        { formik.touched.cvc && <p className="mt-1 text-sm text-red-500">{formik.errors.cvc}</p> }
                    </div>
                </div>
            </div>
            <div className='mt-4 d-flex'>
                <button type="submit" className='btn btn-primary submit-btn me-3'>Continue</button>
                <div className='btn btn-secondary back-btn submit-btn' onClick={showBillingDetails}>Back</div>
            </div>
        </form>
    </div>
  )
}

export default AddCardDetails